.heavenly-traces-section {
  margin: 6.5rem auto 0 auto;
  font-size: 16px;
  position: relative;
  /* min-height: calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100); */
  /* overflow-y: scroll; */
}

/*  Things that need to be taken in consideration to scale 
0. .heavenly-flex-container -> gap (em)
1. .ht-card -> dimensions (em)
2. .ht-card-front-text -> top (!em), .ht-card-front-text h5, h6 -> font-size (em)
3. .ht-arrow -> font-size (em)
4. .ht-back-title p -> font-size (em)
5. .ht-card-back-content p -> font-size (em)
*/

.heavenly-traces-center {
  margin: 0.3rem auto 0.5rem auto;
  /* height: calc(var(--vh, 1vh) * 100); */
  position: relative;
  padding: 0em 1rem 0em 1rem;
}

/* Overlay */
.ht-overlay {
  content: "";
  top: -1.3rem;
  bottom: -0.7rem;
  left: 0;
  right: 0;
  /* height: 100vh; */
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;

  z-index: 1000;
  display: grid;
  justify-items: center;
  align-items: flex-end;
  grid-template-rows: 20em 8em 3em 1fr;

  /* grid-template-rows: 50vh 45vh; */
  /* animation: xbckArrFadeIn 1s linear 1s forwards; */
  opacity: 1;
}

.ht-overlay p {
  align-self: flex-end;
  text-align: center;
  padding: 0.8em;
  color: var(--glory-blue-200);
  font-size: 1.2em;
  font-weight: 500;
  font-family: var(--bodyfont);
  max-width: 50em;
}

.ht-overlay-list {
  color: var(--glory-blue-200);
  font-size: 0.9em;
  padding: 0em 2em;
  align-self: flex-start;
  /* margin-top: -5em; */
}

.ht-overlay-list li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: var(--glory-blue-200); /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
  margin-right: 0.25em;
}

.ht-overlay i,
.ht-overlay svg {
  align-self: flex-start;
  font-size: 2.4em;
  animation: spotlightX 1s linear 1s forwards;
  color: var(--glory-blue-300);
}

@keyframes spotlightX {
  from {
    color: var(--glory-blue-300);
  }
  to {
    color: var(--cross-red-500);
  }
}

/* Cards */

.heavenly-flex-container {
  margin-top: -1rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2em;
  scroll-behavior: smooth;
  /* overflow-x: scroll; */
  overflow-y: scroll;
  overscroll-behavior-inline: contain;
  width: 100%;
  height: calc(var(--vh, 1vh) * 78);
  /* padding: 1em 20em 1em 40em; */
  scrollbar-width: thin;
  scrollbar-color: var(--grey-500) transparent;
  scroll-behavior: smooth;
  scroll-padding-left: 1em;
}

.ht-card {
  border-radius: var(--borderRadius);
  position: relative;
  perspective: 1500px;
  transform-style: preserve-3d;
  /* -webkit-transform-style: preserve-3d; */
  max-width: 95vw;
  width: 95vw;
  height: calc(var(--vh, 1vh) * 70);
  /* height: 27em; */
  transition: var(--transition);
  flex-basis: 20em;
  flex-grow: 1;
  flex-shrink: 0;
}

.ht-card-side {
  transition: all 2s linear;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  /*transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: var(--shadow-4);
  cursor: pointer;
}

.front-img {
  width: 100%;
  height: 100%;
  border-radius: var(--borderRadius);
  object-fit: cover;
  object-position: top;
  transition: var(--transition);
  z-index: 1000;
}

.vist-img {
  width: 100%;
  height: 100%;
  border-radius: var(--borderRadius);
  object-fit: cover;
  object-position: left;
  transition: var(--transition);
  z-index: 1000;
}

.ht-card-front {
  border-radius: var(--borderRadius);
}

.ht-card-front-text h5,
h6 {
  font-family: var(--headingFont);
  font-size: 1.2em;
  font-weight: bold;
  color: var(--white);
  margin: 0.3rem 0;
}
.ht-card-front-text {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  width: 100%;
  place-items: center;
  text-align: center;
  text-shadow: 0 0 12px black;
  letter-spacing: 0.1em;
}

.ht-card-front-text h5 {
  font-family: var(--bodyFont);
}
.ht-card-back {
  transform: rotateY(180deg);
  border-radius: var(--borderRadius);
  display: grid;
  grid-template-rows: 2em auto 1.5em;
  padding: 0.5em 0.5em 0.75em 0.5em;
  background: var(--grey-100);
  /* -webkit-transform: rotateY(180deg); */
  /* backface-visibility: hidden; */
  /* -webkit-backface-visibility: hidden; */
}

.rt-origin-card-back {
  transform: rotateY(180deg);
  border-radius: var(--borderRadius);
  display: grid;
  grid-template-rows: 2em auto;
  padding: 0.5em 0.5em 0.75em 0.5em;
  background: var(--grey-100);
}

.ht-arrow {
  font-size: 1.2em;
  transition: var(--transition);
}

.ht-card:hover .ht-card-front {
  transform: rotateY(-180deg);
  /* -webkit-transform: rotateY(-180deg); */
}

.ht-card:hover .ht-card-back {
  transform: rotateY(0);
  /* -webkit-transform: rotateY(0); */
}

.ht-card:hover .rt-origin-card-back {
  transform: rotateY(0);
}

/* Formatting the back side of the cards */
.ht-card-back-header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  place-items: center;
  transition: var(--transition);
  border-bottom: 1px solid black;
}
.ht-card-back-content {
  /* overflow-y: hidden; */
  overflow-y: hidden;
  /* -webkit-overflow-scrolling: touch; */
  overflow-x: hidden;
  transition: var(--transition);
}

/* .ht-back-title p,
.ht-content-paragraph p,
.ht-content-paragraph p span,
.ht-forward-arrow,
.ht-back-arrow {
  z-index: 1;
} */

.ht-back-links {
  margin-top: 0.5em;
}
.ht-card:hover .ht-card-back-content {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.ht-back-title p {
  font-size: 1.1em;
  font-family: var(--headingFont);
  margin: 0 auto;
  transition: var(--transition);
  text-align: center;
}

.minimized-header-font {
  font-size: 0.77em;
}

.ht-content-paragraph p,
.ht-content-paragraph p span {
  margin: 0;
  text-align: left;
  overflow-wrap: break-word;
  hyphens: auto;
  font-family: var(--bodyFont);
  font-size: 1em;
  transition: var(--transition);
  /* z-index: 10; */
}

.ht-content-paragraph p {
  text-indent: 1.5rem;
}

/* back links */
.ht-card-back-content p {
  margin: 0;
  font-size: 0.95em;
  /* z-index: -1; */
  /* font-family: var(--bodyFont);
  font-size: 0.9em;
  transition: var(--transition); */
}

.center-txt {
  text-align: center !important;
  text-transform: capitalize;
  font-weight: bold;
  position: relative;
  right: 0.7em;
  overflow-wrap: normal;
  /* z-index: -1; */
}

/* Buttons on the Heavenly Traces Page */
.heavenly-traces-btns {
  margin: 0.5em auto;
  display: flex;
  height: 1.2em;
  width: 22em;
  justify-content: space-between;
  align-items: center;
}

.ht-story-btn {
  background: rgba(255, 223, 0, 1);
  height: 1.2em;
  width: 1.2em;
  border-radius: 50%;
  border: none;
  transition: var(--transition);
  cursor: pointer;
}

.ht-story-btn:hover {
  animation: pulsehtBtn 1.5s forwards;
}

.ht-story-btn-s {
  height: 1.4em;
  width: 1.4em;
  box-shadow: 0 0 0 0 rgba(3, 152, 158, 1);
  background: var(--glory-blue-300);
}

.ht-story-btn-ns {
  box-shadow: 0 0 0 0 rgba(3, 152, 158, 1);
  background: rgba(255, 223, 0, 1);
}

.ht-story-btn-s-before {
  box-shadow: 0 0 0 0 rgba(3, 152, 158, 1);
  background: var(--grey-400);
}

@keyframes pulsehtBtn {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 1em rgba(3, 152, 158, 0);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(3, 152, 158, 0);
  }
}

/* Button | Scroll Dec */

.ht-card-back-content::-webkit-scrollbar {
  width: 0.5em;
  /* for horizontal scroll bars */
  height: 1rem;
}

.ht-card-back-content::-webkit-scrollbar-track {
  background: #555;
  border-radius: 100vw;
  margin-block: 0.3rem;
  margin-right: 0;
}

.ht-card-back-content::-webkit-scrollbar-thumb {
  border-radius: 100vw;
  cursor: pointer;
}

/* Flex box for Heavenly traces */
.heavenly-flex-container::-webkit-scrollbar {
  display: none;
}

.jade {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 149, 138, 0.75) transparent;
  scroll-behavior: smooth;
}

.jade::-webkit-scrollbar-thumb:hover {
  background: var(--cross-gold-300);
}

.jade::-webkit-scrollbar-thumb {
  background: rgba(0, 149, 138, 0.75);
}

.cross-blue {
  scrollbar-width: thin;
  scrollbar-color: var(--cross-blue-300) transparent;
  scroll-behavior: smooth;
}
.cross-blue::-webkit-scrollbar-thumb:hover {
  background: var(--cross-gold-300);
}

.cross-blue::-webkit-scrollbar-thumb {
  background: var(--cross-blue-300);
}

.glory-blue {
  scrollbar-width: thin;
  scrollbar-color: var(--glory-blue-300) transparent;
  scroll-behavior: smooth;
}

.glory-blue::-webkit-scrollbar-thumb:hover {
  background: var(--cross-gold-300);
}
.glory-blue::-webkit-scrollbar-thumb {
  background: var(--glory-blue-300);
}

.red {
  scrollbar-width: thin;
  scrollbar-color: rgb(212, 28, 28) transparent;
  scroll-behavior: smooth;
}

.red::-webkit-scrollbar-thumb:hover {
  background: var(--cross-gold-300);
}

.red::-webkit-scrollbar-thumb {
  background: rgb(212, 28, 28);
}

.gold {
  scrollbar-width: thin;
  scrollbar-color: var(--cross-gold-300) transparent;
  scroll-behavior: smooth;
}

.gold::-webkit-scrollbar-thumb:hover {
  background: var(--cross-gold-300);
}

.gold::-webkit-scrollbar-thumb {
  background: rgb(219, 190, 73);
}

.purple-scroll {
  scrollbar-width: thin;
  scrollbar-color: var(--purple) transparent;
  scroll-behavior: smooth;
}

.purple-scroll::-webkit-scrollbar-thumb:hover {
  background: var(--cross-gold-300);
}

.purple-scroll::-webkit-scrollbar-thumb {
  background: var(--purple);
}

.jade-arrow {
  color: rgba(0, 149, 138, 0.75);
}
.jade-card:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 149, 138, 0.75), 0 8px 10px -6px rgba(0, 149, 138, 0.75);
}

.cross-blue-arrow {
  color: rgb(51, 123, 159);
}
.cross-blue-card:hover {
  box-shadow: 0 20px 25px -5px rgb(51, 123, 159), 0 8px 10px -6px rgb(51, 123, 159);
}

.glory-blue-arrow {
  color: var(--glory-blue-300);
}
.glory-blue-card:hover {
  box-shadow: 0 20px 25px -5px var(--glory-blue-300), 0 8px 10px -6px var(--glory-blue-300);
}

.red-arrow {
  color: rgb(212, 28, 28);
}
.red-card:hover {
  box-shadow: 0 20px 25px -5px rgb(212, 28, 28), 0 8px 10px -6px rgb(212, 28, 28);
}

.gold-arrow {
  color: rgb(219, 190, 73);
}
.gold-card:hover {
  box-shadow: 0 20px 25px -5px rgb(219, 190, 73), 0 8px 10px -6px rgb(219, 190, 73);
}

.bounce-arrow {
  animation: bounce 2s linear infinite;
  -webkit-animation: bounce 2s linear infinite;
}

@keyframes bounce {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.purple-scroll-arrow {
  color: var(--purple);
}
.purple-arrow {
  color: var(--purple);
}
.purple-scroll-card:hover {
  box-shadow: 0 20px 25px -5px var(--purple), 0 8px 10px -6px var(--purple);
}
.grey-arrow {
  color: var(--grey-500);
}

.ht-btm-nav-container {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 1.5em;
  background-color: transparent;
  border-top: 1px solid black;
}

.ht-btm-nav-container p {
  margin: 0;
  padding: 0.2em 0.1em;
  font-size: 0.9em;
  letter-spacing: 0.1em;
  color: var(--cross-blue-300);
}

.dw-btm-nav-container {
  width: 100%;
  height: 1.5em;
  background-color: transparent;
  border-top: 1px solid black;
}

.dw-btm-nav-container p {
  margin: 0;
  padding: 0.2em 0.1em;
  font-size: 0.9em;
  letter-spacing: 0.1em;
  color: var(--cross-blue-300);
}

@media screen and (min-width: 400px) {
  .heavenly-traces-btns {
    /* height: 1.2em; */
    width: 24em;
  }
}
/* end */
@media screen and (max-width: 450px) and (min-height: 700px) {
  .ht-card {
    height: 33em;
  }
}

@media screen and (min-width: 450px) {
  .heavenly-traces-btns {
    height: 1.3em;
    width: 25em;
  }
}

@media screen and (min-width: 450px) {
  .heavenly-traces-btns {
    /* height: 1.3em; */
    width: 27em;
  }
}

@media screen and (min-width: 500px) {
  .heavenly-traces-section {
    font-size: 17px;
  }
  /* .ht-card-front-text {
    top: 90%;
  } */
}

@media screen and (min-width: 700px) {
  .ht-card {
    max-width: 21em;
    height: 28em;
  }
  .ht-overlay p {
    font-size: 1.4em;
  }
  .ht-overlay-list {
    font-size: 1em;
  }
  .ht-overlay i,
  .ht-overlay svg {
    font-size: 2.4em;
  }
}

@media screen and (min-height: 740px) and (max-aspect-ratio: 64/100) {
  .ht-card {
    height: 34em;
  }
}

@media screen and (min-height: 800px) and (max-aspect-ratio: 64/100) {
  .ht-card {
    height: 36em;
  }
}

@media screen and (min-height: 900px) and (max-aspect-ratio: 64/100) {
  .ht-card {
    height: 40em;
  }
}

@media screen and (min-width: 800px) {
  .heavenly-traces-center {
    height: auto;
  }

  .ht-card {
    height: 28em;
  }

  .heavenly-flex-container {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2em;
    scroll-behavior: smooth;
    overflow-x: hidden;
    overflow-y: scroll;
    overscroll-behavior-inline: contain;
    width: 100%;
    height: auto;
    /* padding: 1em 20em 1em 40em; */
    scrollbar-width: none;
    /* scrollbar-color: var(--purple) transparent;
  scroll-behavior: smooth; */
  }
}

@media screen and (min-width: 900px) {
  .heavenly-traces-section {
    font-size: 18px;
  }
}

@media screen and (min-width: 1000px) {
  /* .heavenly-flex-container {
    gap: 1.8em;
  } */

  .ht-overlay p,
  .ht-overlay i,
  .ht-overlay svg {
    font-size: 1.6em;
  }
  .heavenly-flex-container {
    gap: 2.1em;
    margin-top: 3em;
  }
}

@media screen and (min-width: 1000px) and (max-aspect-ratio: 12/14) {
  .heavenly-traces-section {
    margin-top: 8rem;
  }
}

@media screen and (min-width: 1200px) {
  .minimized-header-font {
    font-size: 0.9em;
  }
  .ht-overlay p,
  .ht-overlay i,
  .ht-overlay svg {
    font-size: 1.8em;
  }
  .heavenly-traces-section {
    font-size: 19px;
  }
}

@media screen and (min-width: 1400px) {
  .heavenly-traces-section {
    font-size: 19px;
  }
  .heavenly-flex-container {
    gap: 2.3em;
  }
}

@media screen and (min-width: 1500px) {
  .heavenly-traces-section {
    font-size: 20px;
  }
  .ht-card {
    width: 21em;
    max-width: 21em;
  }
  .heavenly-flex-container {
    gap: 2.4em;
  }
}

@media screen and (min-width: 1900px) {
  .heavenly-traces-section {
    font-size: 23px;
  }
  .heavenly-flex-container {
    gap: 2.8em;
  }
}

@media screen and (min-width: 2100px) {
  .heavenly-traces-section {
    font-size: 27px;
  }
  .heavenly-flex-container {
    gap: 3em;
  }
}

@media screen and (min-width: 2200px) {
  .heavenly-traces-section {
    font-size: 30px;
  }
}

@media screen and (min-width: 2500px) {
  .heavenly-traces-section {
    font-size: 32px;
    margin-top: 8rem;
  }
}

@media screen and (min-width: 2700px) {
  .heavenly-traces-section {
    font-size: 37px;
  }
  .ht-btm-nav-container {
    width: 100%;
    height: 1.3em;
  }

  .ht-btm-nav-container p {
    padding: 0.15em 0.1em;
    font-size: 0.9em;
  }
}

@media screen and (min-width: 3200px) {
  .heavenly-traces-section {
    font-size: 39px;
    margin-top: 9rem;
  }
  .ht-btm-nav-container {
    width: 100%;
    height: 1.2em;
  }

  .ht-btm-nav-container p {
    padding: 0.15em 0.1em;
    font-size: 0.85em;
  }
}

@media screen and (min-width: 3800px) {
  .heavenly-traces-section {
    font-size: 49px;
    margin-top: 12rem;
  }
  .ht-btm-nav-container {
    width: 100%;
    height: 1em;
  }

  .ht-btm-nav-container p {
    padding: 0.125em 0.1em;
    font-size: 0.8em;
  }
}
