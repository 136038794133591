.article-page {
  margin-top: 6em;
  font-size: 16px;
  min-height: calc(100vh - 6em);
  padding-bottom: 1.5em;
}

.article-not-found {
  min-height: 70vh;
  display: grid;
  place-content: center;
}

.article-not-found h4 {
  max-width: 80vw;
  letter-spacing: 0.1em;
  text-align: center;
}

.article-section {
  display: grid;
  place-items: center;
  margin-bottom: 2em;
  position: relative;
}

.article-content article {
  display: grid;
  place-items: center;
  width: 95vw;
  position: relative;
}

.collapse-btn {
  /* position: absolute; */
  /* top: 0.1em; */
  /* left: 0%; */
  display: block;
  border: none;
  background: var(--cross-gold-200);
  color: white;
  border-radius: 30%;
  font-size: 2em;
  cursor: pointer;
  width: fit-content;
  padding: 0 0.3em;
  transition: var(--transition);
}

article h3 {
  text-align: center;
  letter-spacing: 0.1em;
}

.article-links {
  margin: 0 0 1em 0;
  text-align: left;
}

.paragraph-subtitle {
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1em;
  font-size: 1em;
  margin: 0;
}

/* 2 references below are the same thing */
.paragraph-text {
  font-size: 1em;
  letter-spacing: 0.075em;
}
.article-content article div p {
  margin-top: 0;
}

/* Modal */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  animation: fadeIn 1s linear 1s forwards, fadeOut 1s linear 4s forwards;
}

.modal-container img {
  width: 100%;
  height: 100%;
}
.modal-msg {
  position: absolute;
  top: 10%;
  bottom: 30%;
  left: 0;
  right: 0;
  display: grid;
  place-items: center;
  z-index: 100;
}

.modal-msg p {
  font-size: 0.75em;
  color: white;
  letter-spacing: 0.075em;
  margin: 0;
}

.modal-msg i,
.modal-msg svg {
  font-size: 0.75em;
  color: white;
  background: var(--cross-gold-200);
  padding: 0.1em 0.3em 0.1em 0.3em;
  border-radius: 30%;
  margin: 0;
}

.modal-x {
  position: absolute;
  top: 5%;
  left: 8%;
  transform: translateX(-50%);
  border: none;
  background: none;
  color: white;
}

@media screen and (min-width: 700px) {
  .paragraph-text,
  .article-links {
    font-size: 1.2em;
  }
  .paragraph-subtitle {
    font-size: 1.2em;
  }
}

@media screen and (min-width: 1000px) {
  .paragraph-text,
  .article-links {
    font-size: 1.3em;
  }
  .paragraph-subtitle {
    font-size: 1.3em;
  }
}

@media screen and (min-width: 1200px) {
  .article-content article div {
    max-width: 85vw;
  }
}

@media screen and (min-width: 1600px) {
  .collapse-btn {
    left: 27%;
  }
  article h3 {
    font-size: 2.3em;
  }
  .article-page {
    margin-top: 6.5em;
  }
}

@media screen and (min-width: 2200px) {
  .paragraph-text,
  .article-links {
    font-size: 1.6em;
  }
  .paragraph-subtitle {
    font-size: 1.6em;
  }
}

@media screen and (min-width: 2500px) {
  article h3 {
    font-size: 3.3em;
  }
  .article-page {
    margin-top: 8em;
  }
}

@media screen and (min-width: 2700px) {
  .paragraph-text,
  .article-links {
    font-size: 1.8em;
  }
  .paragraph-subtitle {
    font-size: 1.8em;
  }
}

@media screen and (min-width: 3800px) {
  article h3 {
    font-size: 4em;
  }
  .article-page {
    margin-top: 11em;
  }
  .paragraph-text,
  .article-links {
    font-size: 2.2em;
  }
  .paragraph-subtitle {
    font-size: 2.2em;
  }
}
