.footer-section {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  padding: 0.5em;
  background: var(--white);
  font-size: 16px;
}
.footer-subsection {
  display: grid;
  grid-template-columns: 1fr;
}
.footer-subsection > div {
  height: 3em;
}
.connect {
  margin: 0 0 0.5em 0;
  display: grid;
  place-items: center;
}

.contact {
  place-self: center;
  border: 1px solid var(--btnJade);

  padding: 0.25em 0.4em;
  border-radius: 0.5em;
  width: fit-content;
  background: var(--btnJade);
  transition: var(--transition);
}

.contact a {
  align-self: center;
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
}

.contact a p {
  margin-top: 0.1em;
  color: white;
}

.contact:hover {
  transform: scale(1.05);
}

.contact a:hover p {
  color: var(--cross-gold-300);
}

.connect p,
.references p,
.contact p {
  margin: 0;
  letter-spacing: 0.1em;
}

.social-links {
  display: flex;
  flex-direction: row;
  margin: 0;
}

.social-links a,
.footer-logo {
  margin: 0 0.5em;
  font-size: 1.5em;
  transition: var(--transition);
  color: var(--cross-gold-300);
}

.social-links a:hover,
.footer-logo:hover {
  font-size: 1.7em;
  margin-left: 0.7em;
  margin-right: 0.7em;
  color: var(--cross-gold-400);
}

.footer-logo {
  margin: 0.2em;
}

.support-logo {
  margin: 0.2em;
  margin: 0 0.5rem;
  font-size: 1.5rem;
  transition: var(--transition);
  color: var(--cross-gold-400);
}

.copyright p {
  font-style: italic;
  font-size: 0.8em;
  margin-bottom: 0.2em;
  max-width: 1200px;
  letter-spacing: 0.1em;
}

.copyright {
  margin-bottom: 0.5em;
}

@media screen and (min-width: 800px) {
  .footer-subsection {
    width: 100%;
    /* grid-template-columns: repeat(2, 1fr); */
  }
}

@media screen and (min-width: 2200px) {
  .footer-section {
    font-size: 21px;
  }
  .copyright p {
    max-width: 1400px;
  }
}

@media screen and (min-width: 2500px) {
  .footer-section {
    font-size: 22px;
  }
}

@media screen and (min-width: 2700px) {
  .footer-section {
    font-size: 24px;
  }
}

@media screen and (min-width: 3200px) {
  .footer-section {
    font-size: 27px;
  }
}

@media screen and (min-width: 3800px) {
  .footer-section {
    font-size: 30px;
  }
}
