#staircasecomplete-svg-rect1 {
  animation-name: staircasecomplete-svg-rect1_c_o, staircasecomplete-svg-rect1_f_o;
  animation-duration: 3500ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes staircasecomplete-svg-rect1_c_o {
  0% {
    opacity: 0.25;
  }
  5.714286% {
    opacity: 0.5;
  }
  11.428571% {
    opacity: 0.75;
  }
  17.142857% {
    opacity: 1;
  }
  22.857143% {
    opacity: 0.75;
  }
  28.571429% {
    opacity: 0.5;
  }
  34.285714% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.25;
  }
}
@keyframes staircasecomplete-svg-rect1_f_o {
  0% {
    fill-opacity: 0;
  }
  5.714286% {
    fill-opacity: 0.33;
  }
  11.428571% {
    fill-opacity: 0.66;
  }
  17.142857% {
    fill-opacity: 1;
  }
  22.857143% {
    fill-opacity: 0.66;
  }
  28.571429% {
    fill-opacity: 0.33;
  }
  34.285714% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 0;
  }
}
#staircasecomplete-svg-rect2 {
  animation-name: staircasecomplete-svg-rect2_c_o, staircasecomplete-svg-rect2_f_o;
  animation-duration: 3500ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes staircasecomplete-svg-rect2_c_o {
  0% {
    opacity: 0.25;
  }
  8.571429% {
    opacity: 0.25;
  }
  14.285714% {
    opacity: 0.5;
  }
  20% {
    opacity: 0.75;
  }
  25.714286% {
    opacity: 1;
  }
  31.428571% {
    opacity: 0.75;
  }
  37.142857% {
    opacity: 0.5;
  }
  42.857143% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.25;
  }
}
@keyframes staircasecomplete-svg-rect2_f_o {
  0% {
    fill-opacity: 0;
  }
  8.571429% {
    fill-opacity: 0;
  }
  14.285714% {
    fill-opacity: 0.33;
  }
  20% {
    fill-opacity: 0.66;
  }
  25.714286% {
    fill-opacity: 1;
  }
  31.428571% {
    fill-opacity: 0.66;
  }
  37.142857% {
    fill-opacity: 0.33;
  }
  42.857143% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 0;
  }
}
#staircasecomplete-svg-rect22 {
  animation-name: staircasecomplete-svg-rect22_c_o, staircasecomplete-svg-rect22_f_o;
  animation-duration: 3500ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes staircasecomplete-svg-rect22_c_o {
  0% {
    opacity: 0.25;
  }
  14.285714% {
    opacity: 0.25;
  }
  20% {
    opacity: 0.5;
  }
  25.714286% {
    opacity: 0.75;
  }
  31.428571% {
    opacity: 1;
  }
  37.142857% {
    opacity: 0.75;
  }
  42.857143% {
    opacity: 0.5;
  }
  48.571429% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.25;
  }
}
@keyframes staircasecomplete-svg-rect22_f_o {
  0% {
    fill-opacity: 0;
  }
  14.285714% {
    fill-opacity: 0;
  }
  20% {
    fill-opacity: 0.33;
  }
  25.714286% {
    fill-opacity: 0.66;
  }
  31.428571% {
    fill-opacity: 1;
  }
  37.142857% {
    fill-opacity: 0.66;
  }
  42.857143% {
    fill-opacity: 0.33;
  }
  48.571429% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 0;
  }
}
#staircasecomplete-svg-rect4 {
  animation-name: staircasecomplete-svg-rect4_c_o, staircasecomplete-svg-rect4_f_o;
  animation-duration: 3500ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes staircasecomplete-svg-rect4_c_o {
  0% {
    opacity: 0.25;
  }
  22.857143% {
    opacity: 0.25;
  }
  28.571429% {
    opacity: 0.5;
  }
  34.285714% {
    opacity: 0.75;
  }
  40% {
    opacity: 1;
  }
  45.714286% {
    opacity: 0.75;
  }
  51.428571% {
    opacity: 0.5;
  }
  57.142857% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.25;
  }
}
@keyframes staircasecomplete-svg-rect4_f_o {
  0% {
    fill-opacity: 0;
  }
  22.857143% {
    fill-opacity: 0;
  }
  28.571429% {
    fill-opacity: 0.33;
  }
  34.285714% {
    fill-opacity: 0.66;
  }
  40% {
    fill-opacity: 1;
  }
  45.714286% {
    fill-opacity: 0.66;
  }
  51.428571% {
    fill-opacity: 0.33;
  }
  57.142857% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 0;
  }
}
#staircasecomplete-svg-rect5 {
  animation-name: staircasecomplete-svg-rect5_c_o, staircasecomplete-svg-rect5_f_o;
  animation-duration: 3500ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes staircasecomplete-svg-rect5_c_o {
  0% {
    opacity: 0.25;
  }
  28.571429% {
    opacity: 0.25;
  }
  34.285714% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.75;
  }
  45.714286% {
    opacity: 1;
  }
  51.428571% {
    opacity: 0.75;
  }
  57.142857% {
    opacity: 0.5;
  }
  62.857143% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.25;
  }
}
@keyframes staircasecomplete-svg-rect5_f_o {
  0% {
    fill-opacity: 0;
  }
  28.571429% {
    fill-opacity: 0;
  }
  34.285714% {
    fill-opacity: 0.33;
  }
  40% {
    fill-opacity: 0.66;
  }
  45.714286% {
    fill-opacity: 1;
  }
  51.428571% {
    fill-opacity: 0.66;
  }
  57.142857% {
    fill-opacity: 0.33;
  }
  62.857143% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 0;
  }
}
#staircasecomplete-svg-rect6 {
  animation-name: staircasecomplete-svg-rect6_c_o, staircasecomplete-svg-rect6_f_o;
  animation-duration: 3500ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes staircasecomplete-svg-rect6_c_o {
  0% {
    opacity: 0.25;
  }
  34.285714% {
    opacity: 0.25;
  }
  40% {
    opacity: 0.5;
  }
  45.714286% {
    opacity: 0.75;
  }
  51.428571% {
    opacity: 1;
  }
  57.142857% {
    opacity: 0.75;
  }
  62.857143% {
    opacity: 0.5;
  }
  68.571429% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.25;
  }
}
@keyframes staircasecomplete-svg-rect6_f_o {
  0% {
    fill-opacity: 0;
  }
  34.285714% {
    fill-opacity: 0;
  }
  40% {
    fill-opacity: 0.33;
  }
  45.714286% {
    fill-opacity: 0.66;
  }
  51.428571% {
    fill-opacity: 1;
  }
  57.142857% {
    fill-opacity: 0.66;
  }
  62.857143% {
    fill-opacity: 0.33;
  }
  68.571429% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 0;
  }
}
#staircasecomplete-svg-rect7 {
  animation-name: staircasecomplete-svg-rect7_c_o, staircasecomplete-svg-rect7_f_o;
  animation-duration: 3500ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes staircasecomplete-svg-rect7_c_o {
  0% {
    opacity: 0.25;
  }
  40% {
    opacity: 0.25;
  }
  45.714286% {
    opacity: 0.5;
  }
  51.428571% {
    opacity: 0.75;
  }
  57.142857% {
    opacity: 1;
  }
  62.857143% {
    opacity: 0.75;
  }
  68.571429% {
    opacity: 0.5;
  }
  74.285714% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.25;
  }
}
@keyframes staircasecomplete-svg-rect7_f_o {
  0% {
    fill-opacity: 0;
  }
  40% {
    fill-opacity: 0;
  }
  45.714286% {
    fill-opacity: 0.33;
  }
  51.428571% {
    fill-opacity: 0.66;
  }
  57.142857% {
    fill-opacity: 1;
  }
  62.857143% {
    fill-opacity: 0.66;
  }
  68.571429% {
    fill-opacity: 0.33;
  }
  74.285714% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 0;
  }
}
#staircasecomplete-svg-line2 {
  animation-name: staircasecomplete-svg-line2_c_o, staircasecomplete-svg-line2_s_do;
  animation-duration: 3500ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: 1;
}
@keyframes staircasecomplete-svg-line2_c_o {
  0% {
    opacity: 1;
  }
  5.714286% {
    opacity: 1;
  }
  11.428571% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes staircasecomplete-svg-line2_s_do {
  0% {
    stroke-dashoffset: 89.1;
  }
  5.714286% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
#staircasecomplete-svg-rail {
  animation-name: staircasecomplete-svg-rail_c_o, staircasecomplete-svg-rail_s_do;
  animation-duration: 3500ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: 1;
}
@keyframes staircasecomplete-svg-rail_c_o {
  0% {
    opacity: 1;
  }
  37.142857% {
    opacity: 1;
  }
  51.428571% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes staircasecomplete-svg-rail_s_do {
  0% {
    stroke-dashoffset: 85.972296;
  }
  20% {
    stroke-dashoffset: 85.972296;
  }
  37.142857% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
#staircasecomplete-svg-handle {
  animation-name: staircasecomplete-svg-handle_c_o, staircasecomplete-svg-handle_s_do;
  animation-duration: 3500ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: 1;
}
@keyframes staircasecomplete-svg-handle_c_o {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  34.285714% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes staircasecomplete-svg-handle_s_do {
  0% {
    stroke-dashoffset: 180;
  }
  5.714286% {
    stroke-dashoffset: 180;
  }
  20% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
#staircasecomplete-svg-line3 {
  animation-name: staircasecomplete-svg-line3_c_o, staircasecomplete-svg-line3_s_do;
  animation-duration: 3500ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: 1;
}
@keyframes staircasecomplete-svg-line3_c_o {
  0% {
    opacity: 1;
  }
  5.714286% {
    opacity: 1;
  }
  11.428571% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes staircasecomplete-svg-line3_s_do {
  0% {
    stroke-dashoffset: 89.1;
  }
  5.714286% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
#staircasecomplete-svg-rail2 {
  animation-name: staircasecomplete-svg-rail2_c_o, staircasecomplete-svg-rail2_s_do;
  animation-duration: 3500ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: 1;
}
@keyframes staircasecomplete-svg-rail2_c_o {
  0% {
    opacity: 1;
  }
  37.142857% {
    opacity: 1;
  }
  51.428571% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes staircasecomplete-svg-rail2_s_do {
  0% {
    stroke-dashoffset: 85.972296;
  }
  20% {
    stroke-dashoffset: 85.972296;
  }
  37.142857% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
#staircasecomplete-svg-handle2 {
  animation-name: staircasecomplete-svg-handle2_c_o, staircasecomplete-svg-handle2_s_do;
  animation-duration: 3500ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: 1;
}
@keyframes staircasecomplete-svg-handle2_c_o {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  34.285714% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes staircasecomplete-svg-handle2_s_do {
  0% {
    stroke-dashoffset: 180;
  }
  5.714286% {
    stroke-dashoffset: 180;
  }
  20% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
