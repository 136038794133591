/* This CSS File has styling for  [Home.js, Attributions.js, Support.js] */

/* Support Page */

.support-contact-page {
  font-size: 16px;
  min-height: 100vh;
  display: grid;
}

.support-contact-page footer {
  margin-top: 2em;
}

.support-section {
  margin: 5.5rem auto 2em auto;
  height: auto;
  width: 95vw;
  display: grid;
  grid-template-rows: auto 1fr;
  place-items: center;
}

.support-section h1 {
  margin-bottom: 0;
}

.support-box {
  height: auto;
  width: 20em;
  place-self: center;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.support-greeting {
  display: grid;
  place-items: center;
}

.support-greeting p {
  font-size: 0.95em;
}

.support-impact {
  height: auto;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  place-items: center;
}

.support-amount-box {
  width: 100%;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  place-items: center;
}

.support-amount {
  width: 5em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
}

.support-amount-text {
  font-size: 0.9em;
  font-style: italic;
  margin: 0 auto;

  /* margin-right: 0.33em; */
}
#support-i-1 {
  font-size: 1.1em;
  color: var(--cross-red-300);
}

#support-i-2 {
  font-size: 1.2em;
  color: var(--glory-blue-300);
}

#support-i-3 {
  font-size: 1.4em;
  color: var(--glory-gold-400);
}

.support-payments {
  margin: 0 auto;
  display: grid;
  gap: 1em;
}

.support-payments button {
  margin: 1.5em 0 1em 0;
  width: 12em;
  height: 3em;
  background: radial-gradient(var(--glory-blue-100), var(--glory-blue-300));
  border: 1px solid var(--glory-blue-300);
  border-radius: 0.8em;
  display: grid;
  place-items: center;
  color: white;
  letter-spacing: 0.1em;
  transition: var(--transition);
}

.support-payments button {
  display: flex;
  justify-content: space-around;
}

.venmo-img {
  width: 2em;
  height: 2em;
}

.donationSection {
  display: grid;
  place-items: center;
  margin-bottom: -1em;
}

.donationForms {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  gap: 1em;
}

.donationCountry {
  /* place-self: end center; */
  display: grid;
  grid-template-rows: auto 1fr;
  place-items: center;
}

select {
  width: 10em;
  height: 2em;
  outline: 2px solid var(--glory-blue-300);
  border: none;
  border-radius: 0.2em;
}

.donationBox {
  display: grid;
  grid-template-rows: auto 1fr;
  place-items: center;
}

.donationBox p,
.donationCountry p {
  margin: 0 0.3em 0 0;
  font-size: 0.9em;
  font-style: italic;
}

.readyBtn {
  margin: 1.5em 0 1em 0;
  width: 10em;
  height: 3em;
  background: radial-gradient(var(--glory-blue-100), var(--glory-blue-300));
  border: 1px solid var(--glory-blue-300);
  border-radius: 0.8em;
  display: grid;
  place-items: center;
  color: white;
  letter-spacing: 0.1em;
  transition: var(--transition);
}

.readyBtn:hover {
  background: radial-gradient(var(--glory-blue-200), var(--glory-blue-400));
}

.donationComplete {
  margin: 1.5em 0 1em 0;
  width: 20em;
  height: 14em;
  border-radius: var(--borderRadius);
  letter-spacing: 0.03em;
  position: relative;
  background: white;
}

.donationComplete p {
  content: "";
  position: absolute;
  font-size: 1em;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  margin: 0;
  padding: 1em;
  background: white;
  border-radius: var(--borderRadius);
  transition: var(--transition);
}

.donationComplete::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--cross-gold-300);
  background-size: 400%;
  opacity: 1;
  filter: blur(5px);
  transition: var(--transition);
  border-radius: 0.25em;
  /* animation: colorBlur 20s linear infinite; */
}

input[type="number"] {
  width: 10em;
  height: 2em;
  outline: 2px solid var(--glory-blue-300);
  border: none;
  border-radius: 0.2em;
}

.formBox:focus {
  border: none;
  outline: 2px solid var(--glory-blue-200);
}

.donationWarning {
  font-size: 0.85em;
  color: var(--cross-red-300);
  font-style: italic;
}

.payPal-btns {
  margin: 2em 0 0 0;
}

.venmo {
  display: grid;
  grid-template-columns: auto 1fr;
}
.venmo img {
  width: 3.3em;
  height: 3.3em;
}
.venmo div p {
  margin: 0 0 0 1em;
}

.venmo div p:nth-child(1) {
  font-family: var(--headingFont);
  font-style: italic;
}

.contact-section {
  display: grid;
  place-items: center;
  padding-bottom: 1em;
}

.contact-section i,
.contact-section svg {
  font-size: 3em;
  color: var(--cross-gold-300);
}

/* Home Page */
.home-page {
  background: black;
  height: 100vh;
  width: 100vw;
  /* height: calc(var(--vh, 1vh) * 100); */
  position: relative;
  overflow-y: hidden;
  /* Temp Change */
  /* background: white; */
}

.home-page-vid,
.home-page-logo,
.home-page-logo-desktop {
  position: absolute;
  object-fit: cover;
  /* height: calc(calc(var(--vh, 1vh) * 100) - 5.1em); */
  height: 100vh;
  width: 100vw;
  /* top: 5.1em; */
  left: 0;
  right: 0;
  display: grid;
  place-items: center;
  background: transparent;
  /* animation: fadeIn 1.5s linear 0s forwards; */
  z-index: 200;
}

.home-page-vid {
  z-index: 2;
}

.home-page-anim {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1000;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.home-page-anim svg {
  position: relative;
  top: 0%;
  width: 100%;
  height: 100%;
}

.home-page-logo img,
.home-page-logo svg {
  position: absolute;
  width: 11em;
  height: 11em;
  top: 10%;
  opacity: 1;
  animation: fadeIn 2s linear 0s forwards;
  transition: var(--transition);
}

.home-page-logo-desktop svg {
  position: absolute;
  width: 70vw;
  height: 35vh;
  top: 21%;
  opacity: 1;
  animation: fadeIn 2s linear 0s forwards;
  transition: var(--transition);
}

.landingSection {
  position: absolute;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  overflow-y: hidden;
  /* animation: OpacityOff 0.5s linear 41.5s forwards, OpacityOn 0.7s linear 56s forwards; */
  /* transition: 1s ease-in all; */
  z-index: 1000;
  font-size: 16px;
}

/* Fades in Buttons for home screen, to view globe better */
.opacity_view_globe {
  opacity: 0;
  animation: OpacityOn 5s ease-in 2s forwards;
}

.Medjugorje-news {
  position: absolute;
  /* top: 40%; */
  left: 0;
  right: 0;
  display: grid;
  place-items: center;
  /* animation: landingButtons 2s linear 0s forwards;
  -webkit-animation: landingButtons 2s linear 0s forwards; */
  z-index: 1000;
  /* Night Change Console */
  top: 42%;
  opacity: 0;
  /* animation: fadeIn 2s linear 0s forwards; */
}

.Medbtn-norm {
  animation: fadeIn 2s linear 0s forwards;
}

.Medbtn-dm {
  animation: fadeIn 2s linear 0s forwards;
}

.Medjugorje-news div {
  height: 2em;
  /* width: 10.75em; */
  display: grid;
  grid-template-columns: 2em 1fr;
  place-items: center;
  z-index: 1000;
  cursor: pointer;
  gap: 0.4em;
  /* Night change */
  width: 11.25em;
  /* Night Change Consolidations */
  padding-left: 0.25em;
  padding-right: 0.25em;
  border: 2px solid #28039e;
  box-shadow: none;
  background: black;
  border-radius: 0.5em;
  transition: var(--transition);
}

.Medjugorje-news-a div {
  /* animation: maNight 0.7s linear 26.1s forwards, maNormal 0.7s linear 32975ms forwards,
    maNight 0.7s linear 38.6s forwards, maNormal 0.7s linear 49000ms forwards, maNight 0.7s linear 71725ms forwards,
    maNormal 0.7s linear 77975ms forwards, maNight 0.7s linear 84225ms forwards, maNormal 0.7s linear 88.6s forwards,
    maNight 3750ms linear 92.35s forwards; */
  animation: maNight 0.7s linear 24.1s forwards, maNormal 0.7s linear 30975ms forwards,
    maNight 0.7s linear 36.6s forwards, maNormal 0.7s linear 47000ms forwards, maNight 0.7s linear 69725ms forwards,
    maNormal 0.7s linear 75975ms forwards, maNight 0.7s linear 82225ms forwards, maNormal 0.7s linear 86.6s forwards,
    maNight 3750ms linear 90.35s forwards;
}

.Medjugorje-news-av div {
  border-radius: 0.5em;
  border: 1px solid #28039e;
  box-shadow: inset 0 0 15px 2px var(--glory-gold-200), 0 0 10px 0px #28039e, 0 0 14px 7px var(--glory-blue-400),
    7px 0 10px 0px #008080, -7px 0 10px 0px #008080;
}

.Medjugorje-news-dm-av div {
  border-radius: 0.5em;
  border: 1px solid var(--cross-red-300);
  box-shadow: inset 0 0 15px 2px var(--glory-gold-200), 0 0 10px 0px var(--cross-red-300), 0 0 14px 7px var(--red-dark),
    7px 0 10px 0px var(--red-dark), -7px 0 10px 0px var(--red-dark);
}

@keyframes maNight {
  0% {
    box-shadow: none;
    border: 2px solid #28039e;
  }
  100% {
    border-radius: 0.5em;
    border: 1px solid #28039e;
    box-shadow: inset 0 0 15px 2px var(--glory-gold-200), 0 0 10px 0px #28039e, 0 0 14px 7px var(--glory-blue-400),
      7px 0 10px 0px #008080, -7px 0 10px 0px #008080;
  }
}

.Medjugorje-news div:hover {
  transform: scaleX(1.1);
}

@keyframes maNormal {
  0% {
    border-radius: 0.5em;
    border: 1px solid #28039e;
    box-shadow: inset 0 0 15px 2px var(--glory-gold-200), 0 0 10px 0px #28039e, 0 0 14px 7px var(--glory-blue-400),
      7px 0 10px 0px #008080, -7px 0 10px 0px #008080;
  }
  100% {
    box-shadow: none;
    border: 2px solid #28039e;
  }
}

@keyframes OpacityOffStatic {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

@keyframes OpacityOff {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes OpacityOn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes maSpNormal {
  from {
    color: var(--cross-gold-200);
  }
  to {
    color: black;
  }
}
@keyframes maSpNight {
  from {
    color: black;
  }
  to {
    color: var(--cross-gold-200);
  }
}

.Medjugorje-news div span {
  font-size: 0.8em;
  font-style: italic;
  /* color: black; */
  transition: var(--transition);
  justify-self: start;
  /* Night Change : Spanish txt needs to be .75em*/
  color: var(--cross-gold-200);
  transition: var(--transition);
}

.Medjugorje-news-a div span {
  /* animation: maSpNight 0.7s linear 26.1s forwards, maSpNormal 0.7s linear 32975ms forwards,
    maSpNight 0.7s linear 38.6s forwards, maSpNormal 0.7s linear 49000ms forwards,
    maSpNight 0.7s linear 71725ms forwards, maSpNormal 0.7s linear 77975ms forwards,
    maSpNight 0.7s linear 84225ms forwards, maSpNormal 0.7s linear 88.6s forwards,
    maSpNight 3750ms linear 92.35s forwards; */
  animation: maSpNight 0.7s linear 24.1s forwards, maSpNormal 0.7s linear 30975ms forwards,
    maSpNight 0.7s linear 36.6s forwards, maSpNormal 0.7s linear 47000ms forwards,
    maSpNight 0.7s linear 69725ms forwards, maSpNormal 0.7s linear 75975ms forwards,
    maSpNight 0.7s linear 82225ms forwards, maSpNormal 0.7s linear 86.6s forwards,
    maSpNight 3750ms linear 90.35s forwards;
}

.Medjugorje-news-av div span {
  color: var(--cross-gold-200);
}

.Medjugorje-news div:hover span {
  color: var(--glory-gold-300);
}

.Medjugorje-news div svg {
  height: 100%;
}

@keyframes reduceSizeOfGrid {
  0% {
    grid-template-rows: 8fr 2fr;
  }
  100% {
    grid-template-rows: 25vh 4fr;
  }
}

.landingPageAnimations {
  /* aspect-ratio: 7 / 4; */
  position: absolute;
  object-fit: cover;
  align-self: start;
  width: 100vw;
  height: 100vh;
  z-index: 2;
}

.landingPageAnimations svg {
  height: 100%;
  width: 100%;
}

.landingPageAnimations-dm-bg svg {
  height: 90%;
  width: 100%;
  /* animation: growSVG 0.7s linear 9.5s forwards;
  -webkit-animation: growSVG 0.7s linear 9.5s forwards; */
}

.landingPageAnimations-dm svg {
  height: 85%;
  width: 100%;
  /* animation: growSVG 0.7s linear 9.5s forwards;
  -webkit-animation: growSVG 0.7s linear 9.5s forwards; */
}

.landingPageAnimations-dm-bg {
  aspect-ratio: 7 / 4;
  align-self: start;
  width: 70vw;
  height: 75vh;
  visibility: visible;
  opacity: 1;
  display: grid;
  place-items: center;
}

@keyframes fadeOutVis {
  from {
    visibility: visible;
    opacity: 1;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}

.hm-pg-logo {
  position: absolute;
  width: 10em;
  height: 10em;
  border-radius: 50%;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  animation: hm-pg-appear-after-svg 1s linear 8.5s forwards;
  opacity: 0;
  z-index: 200;
}

/* Delay 8.5 sec, then set duration == 1sec */
@keyframes hm-pg-appear-after-svg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.landingPageButtons {
  content: "";
  position: absolute;
  /* top: 46%; */
  left: 50%;
  transform: translateX(-50%);
  align-self: start;
  display: grid;
  /* grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr; */
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: 90vw;
  gap: 1.25em;
  place-items: center;
  opacity: 1;
  animation: landingButtons 2s linear 0s forwards;
  -webkit-animation: landingButtons 2s linear 0s forwards;
  z-index: 100;
  padding: 0.5em;
  /* Night Change Console */
  top: 48%;
}

/* Colors for the buttons */
.landingPageButtons div {
  width: 10.75em;
  height: 2em;
  position: relative;
  border-radius: 0.5em;
  display: grid;
  /* background: radial-gradient(#00958a, #004d47); */
  border: 2px solid #288175;
  box-shadow: none;
  /* Temp change */
  background: black;
  /* Night Change */
  transition: var(--transition);
}

.landingPageButtons-a div {
  /* animation: laNight 0.7s linear 26.1s forwards, laNormal 0.7s linear 32975ms forwards,
    laNight 0.7s linear 38.6s forwards, laNormal 0.7s linear 49000ms forwards, laNight 0.7s linear 71725ms forwards,
    laNormal 0.7s linear 77975ms forwards, laNight 0.7s linear 84225ms forwards, laNormal 0.7s linear 88.6s forwards,
    laNight 3750ms linear 92.35s forwards; */
  animation: laNight 0.7s linear 24.1s forwards, laNormal 0.7s linear 30975ms forwards,
    laNight 0.7s linear 36.6s forwards, laNormal 0.7s linear 47000ms forwards, laNight 0.7s linear 69725ms forwards,
    laNormal 0.7s linear 75975ms forwards, laNight 0.7s linear 82225ms forwards, laNormal 0.7s linear 86.6s forwards,
    laNight 3750ms linear 90.35s forwards;
}

.landingPageButtons-av div {
  border: 2px solid #288175;
  box-shadow: 0 0 5px 3px #00958a, 0 0 14px 2px #00cd66;
}

/* .landingPageButtons div  */
@keyframes laNormal {
  from {
    border: 2px solid #288175;
    box-shadow: 0 0 5px 3px #00958a, 0 0 14px 2px #00cd66;
  }
  to {
    box-shadow: none;
    border: 2px solid #288175;
  }
}
@keyframes laNight {
  from {
    border: 2px solid #288175;
    box-shadow: none;
  }
  to {
    border: 2px solid #288175;
    box-shadow: 0 0 5px 3px #00958a, 0 0 14px 2px #00cd66;
  }
}

/* Fade in Btns */
/* .landingPageButtons {
  opacity: 0;
  animation: FadeIn 1.5s linear 1s forwards;
} */

/* .lpb4 {
  opacity: 0;
  animation: fadeIn 1s linear 0.6s forwards;
}

.lpb3 {
  opacity: 0;
  animation: fadeIn 1s linear 0.45s forwards;
}

.lpb2 {
  opacity: 0;
  animation: fadeIn 1s linear 0.3s forwards;
}

.lpb1 {
  opacity: 0;
  animation: fadeIn 1s linear 0.15s forwards;
} */

/* end of Fade */

.landingPageButtons div:hover span,
.landingPageButtons-dm div:hover span {
  color: var(--glory-gold-300);
}

.landingPageButtons div:hover i,
.landingPageButtons-dm div:hover i {
  color: var(--glory-gold-300);
}

.landingPageButtons div:hover svg,
.landingPageButtons-dm div:hover svg {
  color: var(--glory-gold-300);
}

.landingPageButtons div span {
  content: "";
  position: absolute;
  font-size: 0.75em;
  /* color: white; */
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  z-index: 1;
  background: none;
  border-radius: 0.25em;
  padding-left: 2.3em;
  padding-top: 0.25em;
  transition: var(--transition);
  text-transform: capitalize;
  /* text-align: center; */
  /* Night Change*/
  color: #288175;
}

.landingPageButtons-a div span {
  /* animation: btnContNight 0.7s linear 26.1s forwards, btnContNormal 0.7s linear 32975ms forwards,
    btnContNight 0.7s linear 38.6s forwards, btnContNormal 0.7s linear 49000ms forwards,
    btnContNight 0.7s linear 71725ms forwards, btnContNormal 0.7s linear 77975ms forwards,
    btnContNight 0.7s linear 84225ms forwards, btnContNormal 0.7s linear 88.6s forwards,
    btnContNight 3750ms linear 92.35s forwards; */

  animation: btnContNight 0.7s linear 24.1s forwards, btnContNormal 0.7s linear 30975ms forwards,
    btnContNight 0.7s linear 36.6s forwards, btnContNormal 0.7s linear 47000ms forwards,
    btnContNight 0.7s linear 69725ms forwards, btnContNormal 0.7s linear 75975ms forwards,
    btnContNight 0.7s linear 82225ms forwards, btnContNormal 0.7s linear 86.6s forwards,
    btnContNight 3750ms linear 90.35s forwards;
}

.landingPageButtons-av div span {
  color: white;
}

@keyframes btnContNormal {
  0% {
    color: white;
  }
  100% {
    color: #288175;
  }
}

@keyframes btnContNight {
  0% {
    color: #288175;
  }
  100% {
    color: white;
  }
}

.landingPageButtons div i,
.landingPageButtons div svg {
  position: absolute;
  content: "";
  top: 50%;
  left: 0.5em;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  /* color: white; */
  z-index: 10;
  transition: var(--transition);
  /* Temp change */
  color: #288175;
}
.landingPageButtons-dm div i,
.landingPageButtons-dm div svg {
  position: absolute;
  content: "";
  top: 50%;
  left: 0.5em;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  /* color: white; */
  z-index: 10;
  transition: var(--transition);
  /* Temp change */
  color: var(--cross-gold-400);
}

/* Page Jumps */

@keyframes landingButtons {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.toggle_section {
  position: relative;
  /* These changes only apply to mobile screen, on Big Screen child elements needs positioning not grid */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 10em;
  gap: 1.2em;
  /* height: 3em; */
  /* place-items: center; */
}

.passion-cross-cont {
  position: relative;
  width: 2em;
  height: 2em;
}

.passion-cross-cont svg {
  position: absolute;
  width: 2.5em;
  height: 2.5em;
  top: -30%;
}

.toggleLandingPgBtns {
  /* position: absolute; */
  background: none;
  cursor: pointer;
  border: none;
  width: 3em;
  height: 1.5em;
  /* align-self: flex-start; */
  align-self: center;
  margin-bottom: 0.75em;
  /* left: -4.5em; */
  /* top: -0.8em; */
}

.toggleLandingPgBtns i,
.toggleLandingPgBtns svg {
  font-size: 1.5em;
  color: var(--cross-gold-400);
}

.language {
  position: absolute;
  content: "";
  bottom: 0.2em;
  cursor: pointer;
  font-size: 1.4em;
  margin: -0.7em;
}

.slider-hm-label {
  position: absolute;
  content: "";
  right: -3.7em;
  /* transform: translate(0, -10%); */
  font-size: 0.7em;
  color: black;
  /* Night Change */
  /* color: white; */
  transition: var(--transition);
}

.slider-hm-label-a {
  /* animation: slNight 0.7s linear 26.1s forwards, slNormal 0.7s linear 32975ms forwards,
    slNight 0.7s linear 38.6s forwards, slNormal 0.7s linear 49000ms forwards, slNight 0.7s linear 71725ms forwards,
    slNormal 0.7s linear 77975ms forwards, slNight 0.7s linear 84225ms forwards, slNormal 0.7s linear 88.6s forwards,
    slNight 3750ms linear 92.35s forwards; */
  animation: slNight 0.7s linear 24.1s forwards, slNormal 0.7s linear 30975ms forwards,
    slNight 0.7s linear 36.6s forwards, slNormal 0.7s linear 47000ms forwards, slNight 0.7s linear 69725ms forwards,
    slNormal 0.7s linear 75975ms forwards, slNight 0.7s linear 82225ms forwards, slNormal 0.7s linear 86.6s forwards,
    slNight 3750ms linear 90.35s forwards;
}

.slider-hm-label-av {
  color: white;
}

@keyframes slNormal {
  from {
    color: white;
  }
  to {
    color: black;
  }
}

@keyframes slNight {
  from {
    color: black;
  }
  to {
    color: white;
  }
}

.eng-hm-label {
  position: absolute;
  content: "";
  right: 0.3em;
  font-size: 0.7em;
  color: white;
}

.sp-hm-label {
  position: absolute;
  content: "";
  left: 0.3em;
  font-size: 0.7em;
  color: white;
}
/*
.language {
  position: absolute;
  content: "";
  top: 50;
  right: 50;
  cursor: pointer;
  font-size: 1.4em;
} */

/* Toggle */

.switch {
  position: relative;
  display: inline-block;
  width: 2.7em;
  height: 1.2em;
  transition: var(--transition);
  opacity: 0;
  animation: fadeIn 1.5s linear 0s forwards;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--glory-blue-400);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  transition: var(--transition);
}

.slider-dm {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--cross-red-500);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  transition: var(--transition);
}

.slider:before,
.slider-dm:before {
  position: absolute;
  content: "";
  height: 1em;
  width: 1em;
  left: 0.1em;
  bottom: 0.1em;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  transition: var(--transition);
}

input:checked + .slider,
input:checked + .slider-dm {
  background-color: var(--jade);
  transition: var(--transition);
}

input:focus + .slider,
input:focus + .slider-dm {
  box-shadow: 0 0 1px var(--jade);
}

input:checked + .slider:before,
input:checked + .slider-dm:before {
  -webkit-transform: translateX(1.5em);
  -ms-transform: translateX(1.5em);
  transform: translateX(1.5em);
  transition: var(--transition);
}

/* Rounded sliders */
.slider.round,
.slider-dm.round {
  border-radius: 34px;
}

.slider.round:before,
.slider-dm.round:before {
  border-radius: 50%;
}

/*  */

@keyframes colorBlur {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 300% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/* Copy for home DM Page */

.landingSection-dm {
  position: absolute;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  display: grid;
  place-items: center;
  font-size: 16px;
  z-index: 1000;
}

.landingPageAnimations-dm {
  aspect-ratio: 4 / 8;
  height: 100vh;
  width: 100vw;
  visibility: visible;
  opacity: 1;
}

.hm-pg-logo-dm {
  position: absolute;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  animation: hm-pg-appear-after-svg 1s linear 15.5s forwards;
  opacity: 0;
  z-index: 200;
}

.landingPageButtons-dm {
  content: "";
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: 12em;
  gap: 1.25em;
  place-items: center;
  opacity: 0;
  animation: landingButtons 2s linear 0s forwards;
  -webkit-animation: landingButtons 2s linear 0s forwards;
  z-index: 100;
  padding: 0.5em;
}

.small-dm-btns {
  animation: landingButtons 1s linear 15s forwards;
  -webkit-animation: landingButtons 1s linear 15s forwards;
}

.bg-dm-btns {
  animation: landingButtons 1s linear 16s forwards;
  -webkit-animation: landingButtons 1s linear 16s forwards;
}

.landingPageButtons-dm div {
  width: 11.2em;
  height: 2em;
  position: relative;
  border-radius: 0.5em;
  background: black;
  display: grid;
  cursor: pointer;
  /* New look */
  background: transparent;
  border: 2px solid var(--cross-gold-300);
  box-shadow: 0 0 5px 3px var(--cross-gold-300), 0 0 14px 2px var(--glory-gold-300);
  transition: var(--transition);
}

.landingPageButtons-dm div span {
  content: "";
  position: absolute;
  font-size: 0.75em;
  color: white;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  z-index: 1;
  background: black;
  border-radius: 0.25em;
  padding-left: 2.2em;
  padding-top: 0.25em;
  transition: var(--transition);
  /* text-transform: capitalize; */
  /* text-align: center; */
}

/* Attribute Page */
.attribution-page {
  margin-top: 6rem;
  font-size: 16px;
}

.attribution-section {
  margin-bottom: 2.5em;
}

.page-attr {
  margin: 0 auto;
  width: 90%;
  border-top: 1px solid var(--cross-gold-300);
}

.attr-title {
  text-align: center;
  letter-spacing: 0.1em;
  color: var(--cross-gold-300);
  text-shadow: 0 0 0.03em black;
  text-transform: capitalize;
  margin-bottom: 0.5em;
}

.attr-content {
  max-width: fit-content;
  text-align: left;
  letter-spacing: 0.1em;
  padding: 0 0.5em;
}

.attr-content li {
  margin-bottom: 1em;
  font-size: 0.75em;
  word-wrap: break-word;
}
/* added later */
.section-title {
  font-size: 1.5rem;
  text-align: center;
  letter-spacing: 0.05em;
}

/* First Landing Section Font Size is 16px */
@media screen and (min-width: 380px) {
  .home-page-logo img,
  .home-page-logo svg {
    width: 12em;
    height: 12em;
    top: 12%;
  }
  .landingSection {
    font-size: 18px;
  }
  .landingPageButtons,
  .landingPageButtons-dm {
    /* top: 50%; */
    top: 48%;
  }
  .Medjugorje-news {
    top: 41%;
  }

  .landingSection-dm {
    font-size: 18px;
  }
  .hm-pg-logo-dm {
    width: 12em;
    height: 12em;
    top: 8%;
  }
}

@media screen and (min-height: 700px) {
  .landingPageAnimations-dm svg {
    height: 90%;
    width: 100%;
    /* animation: growSVG 0.7s linear 9.5s forwards;
  -webkit-animation: growSVG 0.7s linear 9.5s forwards; */
  }
}

@media screen and (min-height: 770px) {
  .landingPageButtons,
  .landingPageButtons-dm {
    top: 48%;
  }
}

@media screen and (min-height: 800px) {
  .landingPageButtons,
  .landingPageButtons-dm {
    top: 47%;
  }
  .landingSection-dm {
    font-size: 19px;
  }
}

@media screen and (min-height: 820px) {
  .landingPageButtons,
  .landingPageButtons-dm {
    top: 47%;
  }
}
/* 
@media screen and (min-height: 880px) {
  .landingPageButtons-dm {
    gap: 1.5em;
  }
} */

@media screen and (min-height: 920px) {
  .landingSection-dm {
    font-size: 20px;
  }
}

@media screen and (min-width: 400px) {
  .skip-btn {
    padding: 0.75em 1em 0.75em 0.25em;
  }
  .landingPageButtons,
  .landingPageButtons-dm {
    top: 49%;
  }
  .Medjugorje-news {
    top: 43%;
  }
}

@media screen and (min-width: 600px) {
  .support-box {
    width: 25em;
  }
  .support-greeting {
    font-size: 1.1em;
    letter-spacing: 0.05em;
  }
}

@media screen and (min-width: 700px) {
  /* .home-page-logo img {
    width: 19em;
    height: 19em;
    top: 10%;
  } */
  .landingSection {
    font-size: 24px;
  }
  .landingPageButtons,
  .landingPageButtons-dm {
    top: 55%;
  }
  .Medjugorje-news {
    top: 48%;
  }
  .hm-pg-logo-dm {
    width: 12em;
    height: 12em;
    top: 10%;
  }
  .landingSection-dm {
    font-size: 24px;
  }
}

@media screen and (min-width: 800px) {
  .home-page-logo img,
  .home-page-logo svg {
    width: 19em;
    height: 19em;
    top: 13%;
  }
  .toggleLandingPgBtns {
    left: -6em;
    top: -1.2em;
  }
  .toggleLandingPgBtns i,
  .toggleLandingPgBtns svg {
    font-size: 2em;
  }

  .skip-btn span,
  .skip-btn i,
  .skip-btn svg {
    font-size: 1.2em;
  }

  .landingSection {
    font-size: 27px;
  }
  .landingPageButtons,
  .landingPageButtons-dm {
    top: 56%;
  }
  .Medjugorje-news {
    top: 49%;
  }

  .support-contact-page {
    font-size: 18px;
  }
  .support-section {
    width: 33em;
  }
}

@media screen and (min-width: 900px) {
  .home-page-logo img,
  .home-page-logo svg {
    width: 22em;
    height: 22em;
    top: 14%;
  }

  .landingSection {
    font-size: 29px;
  }
  .landingPageButtons,
  .landingPageButtons-dm {
    top: 56%;
  }
  .Medjugorje-news {
    top: 49%;
  }

  .hm-pg-logo-dm {
    width: 12em;
    height: 12em;
    top: 12%;
  }
  .landingSection-dm {
    font-size: 30px;
  }

  /* .landingPageButtons a:nth-child(2) {
    margin-right: 1em;
  }
  .landingPageButtons a:nth-child(3) {
    margin-left: 1em;
  } */
}
@media screen and (min-width: 950px) {
  .section-title {
    font-size: 2.5rem;
    text-align: center;
  }

  .skip-btn span,
  .skip-btn i,
  .skip-btn svg {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 1150px) {
  .toggleLandingPgBtns {
    display: none;
  }
}

@media screen and (min-width: 1100px) and (min-aspect-ratio: 20/11) {
  .home-page-logo img,
  .home-page-logo svg {
    width: 20em;
    height: 20em;
    top: 10%;
  }
  .landingSection,
  .landingSection-dm {
    font-size: 17px;
  }

  /* Fades in Buttons for home screen, to view globe better */
  .opacity_view_globe {
    opacity: 1;
    animation: none;
  }
  .attribution-page {
    font-size: 18px;
  }

  .Medjugorje-news {
    top: 70%;
  }

  .Medjugorje-news div span {
    font-size: 1em;
    /* margin-left: 0.4em; */
  }

  /* .Medjugorje-news a*/
  .Medjugorje-news div {
    border: 2px solid #28039e;
  }

  /* .landingPageButtons div  */
  .landingPageButtons div {
    border: 3px solid #288175;
  }

  /* landing page buttons gap */
  .landingPageButtons div span {
    padding-left: 2.7em;
  }

  .landingPageButtons-dm a:nth-child(2) {
    margin-right: 0.5em;
  }

  .landingPageButtons-dm a:nth-child(3) {
    margin-left: 0.5em;
  }
  .landingPageButtons,
  .landingPageButtons-dm {
    top: 80%;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    gap: 1.5em;
  }
  .toggle_section {
    position: absolute;
    content: "";
    top: 90%;
    /* right: 50%; */
    /* transform: translateX(50%); */
    transform: translateY(100%);
    cursor: pointer;
    font-size: 1.4em;
  }
  /* .language {
    position: absolute;
    content: "";
    top: 50;
    right: 50;
    transform: translateY(100%);
    cursor: pointer;
    font-size: 1.4em;
  } */
  @keyframes growSVGAspect {
    0% {
      aspect-ratio: 4 / 7;
    }
    100% {
      aspect-ratio: 4 / 6;
    }
  }
  @keyframes reduceSizeOfGrid {
    0% {
      grid-template-rows: 8fr 2fr;
    }
    100% {
      grid-template-rows: 6fr 4fr;
    }
  }
  .landingPageAnimations-dm-bg svg {
    margin-top: 20vh;
    height: 90%;
    width: 80%;
  }
}

@media screen and (min-width: 1100px) and (max-aspect-ratio: 20/11) {
  .home-page-logo img,
  .home-page-logo svg {
    width: 24em;
    height: 24em;
    top: 10%;
  }
  .skip-btn span,
  .skip-btn i,
  .skip-btn svg {
    font-size: 1em;
  }

  .landingPageAnimations-dm-bg {
    position: absolute;
    top: 10%;
    height: 85vh;
    width: 80vw;
  }
  .opacity_view_globe {
    opacity: 1;
    animation: none;
  }
  .hm-pg-logo-dm {
    width: 12em;
    height: 12em;
    top: 17%;
  }
  .landingSection,
  .landingSection-dm {
    font-size: 20px;
  }
  .attribution-page {
    font-size: 18px;
  }

  .Medjugorje-news {
    top: 65%;
  }

  .Medjugorje-news div span {
    font-size: 1em;
    /* margin-left: 0.4em; */
  }

  /* .Medjugorje-news a*/
  .Medjugorje-news div {
    border: 2px solid #28039e;
  }

  /* .landingPageButtons div  */
  .landingPageButtons div {
    border: 3px solid #288175;
    cursor: pointer;
  }

  /* landing page buttons gap */
  .landingPageButtons div span {
    padding-left: 2.7em;
  }

  .landingPageButtons-dm a:nth-child(2) {
    margin-right: 0.5em;
  }

  .landingPageButtons-dm a:nth-child(3) {
    margin-left: 0.5em;
  }
  .landingPageButtons,
  .landingPageButtons-dm {
    top: 75%;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    gap: 1.5em;
  }

  /* .language {
    position: absolute;
    content: "";
    top: 50;
    transform: translateY(100%);
    font-size: 1.4em;
  } */
  .toggle_section {
    margin-top: 2em;
    position: absolute;
    content: "";
    top: 90%;
    right: 50%;
    transform: translateY(100%);
    transform: translateX(50%);
    cursor: pointer;
    font-size: 1.4em;
  }
  @keyframes growSVGAspect {
    0% {
      aspect-ratio: 4 / 7;
    }
    100% {
      aspect-ratio: 4 / 6;
    }
  }
  @keyframes reduceSizeOfGrid {
    0% {
      grid-template-rows: 8fr 2fr;
    }
    100% {
      grid-template-rows: 6fr 4fr;
    }
  }
}

@media screen and (min-width: 1000px) and (max-aspect-ratio: 12/14) {
  .landingPageAnimations-dm-bg {
    align-self: start;
    /* new*/
    position: absolute;
    top: -5%;
    height: 80vh;
    width: 90vw;
  }
  .support-section {
    margin-top: 8rem;
  }
}
@media screen and (min-width: 1200px) {
  .home-page-anim svg {
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    width: 50em;
    height: 25em;
  }
  .landingPageAnimations-dm-bg {
    /* new*/
    position: absolute;
    top: 0%;
    height: 80vh;
    width: 85vw;
  }
  .landingPageAnimations-dm-bg svg {
    /* new*/
    height: 90vh;
    width: 85%;
  }
}

@media screen and (min-width: 1300px) {
  .landingPageAnimations-dm-bg {
    width: 70vw;
  }
}

@media screen and (min-width: 1400px) {
  .home-page-logo img,
  .home-page-logo svg {
    width: 25em;
    height: 25em;
    top: 12%;
  }

  .landingSection,
  .landingSection-dm-bg {
    font-size: 20px;
    /* grid-template-rows: 85vh auto; */
  }
  /* .landingPageAnimations, */
  .landingPageAnimations-dm-bg {
    top: 0%;
  }
  .support-section {
    width: 50em;
  }
  .support-box {
    width: 50em;
  }

  input[type="number"],
  select {
    width: 18em;
  }
  .support-greeting ul {
    columns: 2;
  }
  .donationCountry,
  .donationBox {
    width: 22em;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
  }
  .donationBox div {
    display: inline-block;
  }
  .donationForms {
    gap: 2em;
  }
  .support-impact {
    line-height: 2.7em;
  }
  .support-greeting p {
    font-size: 1.3em;
  }
  .readyBtn {
    margin: 3.3em 0 1em 0;
  }
}

@media screen and (min-width: 1600px) {
  .landingPageAnimations-dm-bg {
    /* top: 10%; */
    width: 54em;
  }
  .skip-btn span,
  .skip-btn i,
  .skip-btn svg {
    font-size: 1.2em;
  }
}

@media screen and (min-width: 1900px) {
  /* .home-page-logo img {
    width: 24em;
    height: 24em;
    top: 10%;
  }

  .landingPageAnimations-dm-bg {
    position: absolute;
    top: 10%;
    height: 85vh;
    width: 80vw;
  }
  .hm-pg-logo-dm {
    width: 12em;
    height: 12em;
    top: 17%;
  } */
  .landingSection,
  .landingSection-dm {
    font-size: 24px;
  }
  .home-page-anim svg {
    width: 60em;
    height: 30em;
  }
  .skip-btn span,
  .skip-btn i,
  .skip-btn svg {
    font-size: 1.2em;
  }
}

@media screen and (min-width: 2200px) and (min-aspect-ratio: 14/10) {
  .home-page-logo img,
  .home-page-logo svg {
    width: 30em;
    height: 30em;
    top: 18%;
  }
  .home-page-logo-desktop svg {
    width: 67vw;
    height: 35vh;
    top: 21%;
  }
  .skip-btn {
    border: 2px solid white;
    padding: 1.2em 1.6em 1.2em 0.7em;
  }
  .skip-btn span,
  .skip-btn i,
  .skip-btn svg {
    font-size: 2em;
  }
  .support-section {
    margin-top: 8rem;
  }
  /*
  .landingPageAnimations-dm-bg {
    position: absolute;
    top: 10%;
    height: 85vh;
    width: 80vw;
  }
  .hm-pg-logo-dm {
    width: 12em;
    height: 12em;
    top: 17%;
  } */
  .landingSection,
  .landingSection-dm {
    font-size: 30px;
  }
  .home-page-anim svg {
    width: 65em;
    height: 32.5em;
  }
  .support-payments button {
    width: 15em;
    height: 4em;
  }
  .support-payments button span {
    font-size: 1.4em;
  }
}

@media screen and (min-width: 2700px) {
  .home-page-logo img,
  .home-page-logo svg {
    width: 37em;
    height: 37em;
    top: 18%;
  }
  .skip-btn {
    border: 2px solid white;
    padding: 1.4em 1.8em 1.4em 0.8em;
  }
  .skip-btn span,
  .skip-btn i,
  .skip-btn svg {
    font-size: 2.4em;
  }
  .support-payments button {
    width: 18em;
    height: 5em;
  }
  .support-payments button span {
    font-size: 1.8em;
  }
  /*
  .landingPageAnimations-dm-bg {
    position: absolute;
    top: 10%;
    height: 85vh;
    width: 80vw;
  }
  .hm-pg-logo-dm {
    width: 12em;
    height: 12em;
    top: 17%;
  } */
  .landingSection,
  .landingSection-dm {
    font-size: 37px;
  }
  .home-page-anim svg {
    width: 80em;
    height: 40em;
  }
  .support-contact-page {
    font-size: 24px;
  }
  .support-contact-page h1 {
    font-size: 4rem;
  }
  .support-payments button img {
    width: 3em;
    height: 3em;
  }
}

@media screen and (min-width: 3800px) {
  .support-section {
    margin-top: 10rem;
  }
  .support-contact-page h1 {
    font-size: 4.7rem;
  }
  .support-contact-page {
    font-size: 28px;
  }
  .support-payments button {
    width: 24em;
    height: 7em;
  }
  .support-payments button img {
    width: 4em;
    height: 4em;
  }
  .support-payments button span {
    font-size: 2.4em;
  }
  .home-page-logo img,
  .home-page-logo svg {
    width: 44em;
    height: 44em;
    top: 18%;
  }
  .skip-btn {
    padding: 1.6em 2em 1.6em 1em;
  }
  .skip-btn span,
  .skip-btn i,
  .skip-btn svg {
    font-size: 2.6em;
  }
  /*
  .landingPageAnimations-dm-bg {
    position: absolute;
    top: 10%;
    height: 85vh;
    width: 80vw;
  }
  .hm-pg-logo-dm {
    width: 12em;
    height: 12em;
    top: 17%;
  } */
  .landingSection,
  .landingSection-dm {
    font-size: 44px;
  }
  .home-page-anim svg {
    width: 100em;
    height: 50em;
  }
}
