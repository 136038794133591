.christ-passion-page {
  /* margin-top: 0.5rem; */
  background: var(--white);
  font-size: 16px;
  min-height: 100vh;
  overflow-y: hidden;
}

.c-passion-main-container {
  padding-top: 1rem;
  margin-top: 0;
  padding-bottom: 1em;
  position: relative;
  overflow-y: hidden;
}

.c-passion-main-container-overview {
  background: linear-gradient(to top, #333, #323129 35%, #77b0e2 90%, #e1bd45);
  min-height: 100vh;
}

/* To switch colors between overview an scene pages */
.c-passion-main-container-scenes {
  background: white;
  min-height: 100vh;
}

.c-passion-main-container-scene-content {
  /* background: linear-gradient(to top, #333, #595748 15%, #eee 50%, #ffffff 75%, #85c5c7); */
  /* background: linear-gradient(to top, #4d8b95, #eee 50%, #ffffff 75%, #f9fbfb); */
  min-height: 100vh;
  overflow-y: scroll;
}

.c-passion-overview-container {
  text-align: center;
}

.c-passion-overview-txt {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 1em;
  color: var(--white);
  text-shadow: 0 0 0.03em black;
  text-align: left;
  /* Add text glow here, fade in-out animation */
}

.c-passion-overview-btn {
  /* justify-content: center; */
  /* position: absolute; */
  background: none;
  border: none;
  color: #e1bd45;
  font-size: 1.3em;
  letter-spacing: 0.05em;
  margin-top: 0.5em;
  width: 100%;
  display: grid;
  place-items: center;
}

.c-passion-overview-btn p {
  margin: 0.1em 0 0.1em 0;
}

.c-passion-overview-arrows {
  margin-top: 0.5em;
  font-size: 2em;
  transform: rotate(90deg);
}

.c-passion-home-btn {
  font-size: 0.9em;
  padding: 0.25em 0.6em;
  width: fit-content;
  height: fit-content;
  background: radial-gradient(rgb(155, 13, 155), rgb(99, 5, 99) 70%);
  /* background: rgb(99, 5, 99); */
  color: white;
  border-radius: 0.4em;
  display: grid;
  grid-template-columns: 3fr 7fr;
  align-items: center;
  justify-items: start;
  opacity: 1;
  margin: 1em auto;
  letter-spacing: 0.05em;
}

.c-passion-home-btn i,
.c-passion-home-btn svg {
  justify-self: center;
  cursor: pointer;
}

.c-passion-title {
  margin: 0 0.5rem;
  text-align: center;
  letter-spacing: 0.075em;
  color: #cf1111;
  font-weight: bold;
  text-shadow: 0 0 0.03em rgb(61, 3, 3);
}

.c-passion-story-titles {
  padding-top: 0.5em;
  padding-left: 3.5em;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 1em;
  text-shadow: 0 0 0.03em black;
  /* color: var(--white); */
}

.c-passion-story-select-art {
  position: absolute;
  /* top: 65%; */
  bottom: 3%;
  left: 0;
  right: 0;
  height: 12em;
  width: 100vw;
  display: grid;
  place-items: center;
}

.c-passion-story-select-art svg {
  height: 12em;
  cursor: pointer;
}

.c-passion-subtitle {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 1em;
}

.c-passion-scene-box-n-list {
  text-align: center;
  position: relative;
  margin-bottom: 0.5em;
  display: grid;
  place-items: center;
  z-index: 1;
  cursor: pointer;
}

.c-passion-scene-select-box {
  margin-left: auto;
  margin-right: auto;
  width: 85vw;
  display: grid;
  /* grid-template-columns: 1fr 8fr; */
  grid-template-columns: 1fr;
  /* align-items: center; */
  place-items: center;
}

.c-passion-scene-select-box svg {
  height: 1.75em;
}

.c-passion-scene-select-box i,
.c-passion-scene-select-box svg {
  justify-self: flex-end;
  margin-right: 1em;
  font-size: 1.1em;
}

.c-passion-scene-select-title-cont {
  display: grid;
  align-items: center;
  justify-items: start;
  /* background: rgba(204, 4, 4, 0.8); */
  background: none;
  border-radius: 0.25em;
  /* border: 1px solid white; */
  position: absolute;
  top: 0;
  left: -1.5em;
  opacity: 0;
  animation: opacityOn 1s linear 1.2s forwards;
}

.c-passion-scene-select-title-cont p {
  color: #f7c312;
  font-size: 1.1em;
  letter-spacing: 0.04em;
  margin: 0 0.8em;
  animation: FadeGold 1s linear 2.2s forwards;
}

@keyframes FadeGold {
  0% {
    color: #f7c312;
  }
  100% {
    color: white;
  }
}

.c-passion-scene-select-subtitle-cont {
  justify-self: start;
  padding-left: 0em;
  width: calc(100vw - 2.6em);
}

.c-passion-scene-select-subtitle-list {
  position: relative;
  /* margin: 0.25em 0 0.25em 0.5em; indention of bullet points */
  background: rgba(255, 255, 255, 0.7);
  text-align: left;
  width: 100%;
  margin-top: 0.5em;
}

.c-passion-scene-select-subtitle-list li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: gold; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
  margin-right: 0.25em;
}

.subtitle-list-elements {
  font-size: 0.82em;
  color: black;
  /* If `line-height` is changed, the useeffect in SceneSelection needs adjustment*/
  line-height: 1.6em;
}

@keyframes dropDownSubtitles {
  0% {
    height: 0;
    margin: 0;
  }
  100% {
    height: var(--passSubtitleLinkHeight);
  }
}

@keyframes opacityOn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.c-passion-scene-select-btn {
  /* margin: 0.2em auto; */
  text-align: center;
  bottom: 0%;
  left: calc(47%);
  transform: translateX(-50%);
  /* background: #cc0404cc; */
  background: radial-gradient(#cc0404cc, #880303);
  border-radius: 0.5em;
  border: 1px solid white;
  padding: 0.4em 1em;
  position: absolute;
  color: white;
  letter-spacing: 0.04em;
}

#c-passion-scene-title {
  font-size: 1.6em;
  text-align: center;
  margin: 0 auto 0.5em auto;
}

.c-passion-scene-substory-title {
  font-size: 1.1em;
  text-align: center;
  color: #cc0404;
  margin: 0.3em auto 0.3em auto;
  letter-spacing: 0.05em;
}

.c-passion-paragraph-box {
  width: 90vw;
  margin: auto;
  /* background-color: #e4f8f9; */
}

.c-passion-paragraph {
  font-size: 0.9em;
}

#c-passion-pageRef {
  font-style: italic;
}

@keyframes bounce {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
    color: rgba(225, 189, 69, 0.5);
  }
  50% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    color: rgba(225, 189, 69, 1);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    color: rgba(225, 189, 69, 0.5);
  }
}

.c-passion-overview-arrows svg {
  animation: bounce 1.5s linear infinite;
  /* color: rgba(225, 189, 69, 1); */
}

/* scroll bar */
.c-passion-main-container-scene-content::-webkit-scrollbar {
  width: 0.5em;
  /* for horizontal scroll bars */
  height: 1rem;
}

.c-passion-main-container-scene-content::-webkit-scrollbar-track {
  background: none;
  border-radius: 100vw;
  margin-block: 0.3rem;
  margin-right: 0;
}

.c-passion-main-container-scene-content::-webkit-scrollbar-thumb {
  border-radius: 100vw;
  cursor: pointer;
  background: #cc0404;
  color: #cc0404;
}

.c-passion-main-container-scene-content::-webkit-scrollbar-thumb:hover {
  background: #cc0404;
}

@media screen and (min-width: 370px) {
  .subtitle-list-elements {
    font-size: 0.9em;
  }

  .c-passion-story-titles {
    padding-left: 2.5em;
    margin-left: 1rem;
    /* color: var(--white); */
  }
  .c-passion-scene-select-title-cont {
    left: -0.5em;
  }
}

@media screen and (min-width: 400px) {
  .c-passion-story-titles {
    padding-left: 1.5em;
    margin-left: 1rem;
    /* color: var(--white); */
  }
  .c-passion-scene-select-title-cont {
    border-radius: 0.25em;
    top: 0;
    left: 1em;
  }
  .c-passion-scene-select-subtitle-cont {
    justify-self: start;
    padding-left: 8vw;
    width: calc(100vw - 3em);
  }
}
@media screen and (min-height: 890px) {
  .c-passion-scene-select-title-cont {
    border-radius: 0.25em;
    top: 0;
    left: 3.7em;
  }

  .c-passion-scene-select-subtitle-cont {
    justify-self: start;
    padding-left: 4em;
    width: calc(100vw - 3em);
  }

  .c-passion-scene-select-box svg {
    height: 2em;
  }

  /* Scene Content Page */
  .c-passion-main-container-scene-content section {
    font-size: 17px;
  }
}

@media screen and (min-width: 420px) {
  .c-passion-story-titles {
    padding-left: 3em;
    margin-left: 1rem;
    /* color: var(--white); */
  }
  .c-passion-scene-select-title-cont {
    border-radius: 0.25em;
    top: 0;
    left: -0.5em;
  }
  .c-passion-scene-select-subtitle-cont {
    justify-self: start;
    padding-left: 2vw;
    width: calc(100vw - 6em);
  }
}

@media screen and (min-width: 480px) {
  .c-passion-scene-select-box svg {
    height: 2em;
  }

  .c-passion-story-titles {
    padding-left: 0.5em;
    margin-left: 1rem;
    /* color: var(--white); */
  }
  .c-passion-scene-select-title-cont {
    border-radius: 0.25em;
    top: 0;
    left: 3em;
  }

  .c-passion-scene-select-subtitle-cont {
    justify-self: start;
    padding-left: 5em;
    width: calc(100vw - 5em);
  }
}

@media screen and (min-height: 800px) {
  .c-passion-story-select-art {
    bottom: 7%;
  }
}

@media screen and (min-width: 700px) {
  /* Passion Overiew Page */
  .c-passion-overview-container {
    padding: 0.5em;
  }

  .c-passion-overview-txt {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 1.3em;
    /* Add text glow here, fade in-out animation */
  }

  .c-passion-overview-btn {
    margin-top: 2em;
  }

  .c-passion-overview-btn p {
    font-size: 1.3em;
  }

  .c-passion-overview-arrows {
    margin-top: 0.5em;
    font-size: 2.2em;
    cursor: pointer;
  }

  /* Scene Selection Page */
  .c-passion-story-titles {
    padding-top: 1em;
    padding-left: 0em;
    margin-left: 0em;
  }
  .c-passion-scene-select-title-cont {
    border-radius: 0.25em;
    top: 0;
    left: 6.5em;
    font-size: 1.3em;
  }

  .c-passion-scene-select-subtitle-cont {
    padding-left: 9em;
    width: calc(100vw - 9em);
  }

  .c-passion-scene-select-subtitle-cont ul li {
    font-size: 1.2em;
  }

  .c-passion-scene-select-box svg {
    height: 3em;
  }

  .c-passion-scene-select-btn {
    font-size: 1.1em;
    left: calc(47%);
  }

  .c-passion-story-select-art {
    bottom: 7%;
    height: 14em;
  }
  .c-passion-story-select-art svg {
    height: 14em;
  }

  /* Scene Content Page */
  #c-passion-scene-title {
    font-size: 2em;
  }
  .c-passion-main-container-scene-content section {
    font-size: 20px;
  }
  .c-passion-home-btn {
    font-size: 1em;
    padding: 0.25em 0.6em;
    /* border-radius: 0.4em; */
    margin: 1em auto;
    letter-spacing: 0.05em;
  }
}

@media screen and (min-width: 800px) {
  /* Scene Selection Page */
  .c-passion-main-container h1 {
    font-size: 3.5em;
  }
  .c-passion-story-titles {
    padding-top: 1.25em;
    margin-left: -2em;
  }
  .c-passion-scene-select-title-cont {
    border-radius: 0.25em;
    top: 0;
    left: 23vw;
    font-size: 1.3em;
  }
  .c-passion-scene-select-subtitle-cont {
    padding-left: 12em;
    width: calc(100vw - 12em);
  }
  /* Scene Content Page */
  .c-passion-main-container-scene-content section {
    font-size: 21px;
  }
  .c-passion-home-btn {
    font-size: 1.1em;
  }
}

@media screen and (min-width: 820px) {
  .c-passion-story-titles {
    padding-top: 1.25em;
    margin-left: -3em;
  }
  .c-passion-scene-select-title-cont {
    left: 27vw;
  }
  .c-passion-scene-select-subtitle-cont {
    padding-left: 15em;
    width: calc(100vw - 12em);
  }
}

@media screen and (min-width: 900px) {
  /* Scene Selection Page */
  .c-passion-scene-select-title-cont {
    border-radius: 0.25em;
    top: 0;
    left: 24vw;
    font-size: 1.3em;
  }
  .c-passion-scene-select-subtitle-cont {
    padding-left: 14em;
    width: calc(100vw - 14em);
  }
}

@media screen and (min-width: 1000px) and (max-aspect-ratio: 10/12) {
  /* Scene Selection Page */
  .c-passion-main-container h1 {
    font-size: 4em;
  }
  .c-passion-scene-select-title-cont {
    left: 20vw;
    font-size: 1.7em;
  }
  .c-passion-scene-select-subtitle-cont {
    padding-left: 14em;
    width: calc(100vw - 14em);
  }
  .c-passion-scene-select-box svg {
    height: 4em;
  }

  .c-passion-scene-select-subtitle-cont ul li {
    font-size: 1.4em;
  }

  .c-passion-scene-select-btn {
    font-size: 1.4em;
    left: calc(47%);
  }
  .c-passion-story-select-art {
    bottom: 7%;
    height: 15em;
  }
  .c-passion-story-select-art svg {
    height: 15em;
  }
  /* Scene Content Page */
  #c-passion-scene-title {
    font-size: 2.4em;
  }
  .c-passion-main-container-scene-content section {
    font-size: 26px;
  }
  .c-passion-home-btn {
    font-size: 1.4em;
    cursor: pointer;
  }
}

@media screen and (min-width: 1200px) {
  .c-passion-overview-container {
    padding: 0.5em 1.25em;
  }
  /* Scene Selection Page */
  .c-passion-story-titles {
    width: 50vw;
    margin-left: 5rem;
  }

  .c-passion-scene-select-box {
    width: 100%;
  }
  .c-passion-scene-select-title-cont {
    left: 8vw;
  }
  .c-passion-scene-select-subtitle-cont {
    padding-left: 8em;
    width: calc(50vw - 8em);
  }
  .c-passion-story-select-art {
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 50%;
    right: 0%;
    height: 100vh;
    width: 50vw;
    display: grid;
    place-items: center;
  }
  .c-passion-story-select-art svg {
    height: 16em;
    margin-bottom: 5em;
  }
  /* Scene Content Page */
  #c-passion-scene-title {
    font-size: 2em;
  }
  .c-passion-main-container-scene-content section {
    font-size: 19px;
  }
}

@media screen and (min-width: 1600px) {
  .christ-passion-page {
    font-size: 18px;
  }
  .c-passion-story-titles {
    padding-top: 1em;
  }
  /* Scene Selection Page */
  .c-passion-scene-select-title-cont {
    left: 10vw;
  }
  .c-passion-scene-select-subtitle-cont {
    padding-left: 10em;
    width: calc(50vw - 8em);
  }
}

@media screen and (min-width: 1900px) {
  .c-passion-main-container h1 {
    font-size: 4em;
  }
  .c-passion-overview-txt {
    font-size: 1.5em;
  }
  /* Scene Selection Page */
  .c-passion-scene-select-box svg {
    height: 3.5em;
  }
  .c-passion-scene-select-subtitle-cont {
    padding-left: 12em;
    width: calc(50vw - 10em);
  }
  .c-passion-story-select-art svg {
    height: 18em;
    margin-bottom: 8em;
  }
  /* Scene Content Page */
  #c-passion-scene-title {
    font-size: 2.4em;
  }
  .c-passion-main-container-scene-content section {
    font-size: 22px;
  }
  .c-passion-home-btn {
    font-size: 1.2em;
  }
  .c-passion-scene-select-title-cont {
    left: 12vw;
  }
}

@media screen and (min-width: 2200px) {
  .christ-passion-page {
    font-size: 22px;
  }
  /* Scene Selection Page */
  .c-passion-scene-select-title-cont {
    font-size: 1.5em;
  }
  .c-passion-scene-select-box svg {
    height: 4.1em;
  }
  .c-passion-scene-select-title-cont {
    left: 7vw;
  }
  .c-passion-scene-select-subtitle-cont ul li {
    font-size: 1.3em;
  }
  .c-passion-scene-select-btn {
    font-size: 1.5em;
    left: calc(47%);
  }
  /* Scene Content Page */
  .c-passion-main-container-scene-content section {
    font-size: 28px;
  }
  .c-passion-scene-select-subtitle-cont {
    padding-left: 5em;
    width: calc(50vw - 8em);
  }
}

@media screen and (min-width: 2500px) {
  .c-passion-overview-txt {
    font-size: 1.7em;
  }

  .c-passion-overview-btn {
    margin-top: 2.5em;
  }

  .c-passion-overview-btn p {
    font-size: 1.6em;
  }

  .c-passion-overview-arrows {
    margin-top: 0.5em;
    font-size: 2.2em;
  }
  /* Scene Selection Page */
  .c-passion-scene-select-title-cont {
    left: 10vw;
  }
  .c-passion-scene-select-subtitle-cont {
    padding-left: 14em;
    width: calc(50vw - 14em);
  }
  .c-passion-story-select-art svg {
    height: 24em;
    margin-bottom: 8em;
  }
}

@media screen and (min-width: 2700px) {
  .christ-passion-page {
    font-size: 24px;
  }
  .c-passion-main-container h1 {
    font-size: 4.1em;
  }
  .c-passion-story-titles {
    padding-top: 1.5em;
  }
  /* Scene Selection Page */
  .c-passion-scene-select-box svg {
    height: 4.2em;
  }
  .c-passion-scene-select-title-cont {
    left: 9vw;
    font-size: 1.8em;
  }

  .c-passion-scene-select-subtitle-cont {
    padding-left: 11em;
    width: calc(50vw - 13em);
  }

  .c-passion-scene-select-subtitle-cont ul li {
    font-size: 1.4em;
  }

  .c-passion-scene-select-btn {
    font-size: 1.5em;
    left: calc(47%);
  }
  .c-passion-story-select-art svg {
    margin-bottom: 16em;
  }
}

@media screen and (min-width: 3200px) {
  .christ-passion-page {
    font-size: 26px;
  }
  /* Scene Content Page */
  .c-passion-scene-select-subtitle-cont {
    padding-left: 13em;
    width: calc(50vw - 13em);
  }
  .c-passion-main-container-scene-content section {
    font-size: 32px;
  }
  .c-passion-scene-select-title-cont {
    left: 10vw;
  }
}

@media screen and (min-width: 3500px) {
  .christ-passion-page {
    font-size: 28px;
  }
  .c-passion-main-container h1 {
    font-size: 4.8em;
  }
  /* Scene Selection Page */
  .c-passion-story-titles {
    margin-left: -8rem;
  }
  .c-passion-scene-select-title-cont {
    left: 14vw;
  }
  .c-passion-scene-select-subtitle-cont {
    padding-left: 20em;
    width: calc(50vw - 15em);
  }
  /* Scene Content Page */

  .c-passion-main-container-scene-content section {
    font-size: 40px;
  }
}
