.powerful-lessons-page {
  margin-top: 5.5rem;
  /* padding-top: 4em; */
}
.powerful-conversations-section {
  min-height: 95vh;
  height: auto;
  font-size: 16px;
}

.powerful-lessons-section {
  margin: 0;
  padding-top: 1em;
  font-size: 16px;
  background: black;
  min-height: fit-content;
  height: auto;
  /* overflow-y: auto; */
}

.powerful-conversations-center {
  width: 100%;
  margin: 0.5rem auto 0.5rem auto;
}

.section-title {
  font-size: 1.5em;
  text-align: center;
  letter-spacing: 0.05em;
}

/* Conversations */

.powerful-conversations-center p {
  color: white;
}

.conversation {
  padding: 0 1em;
  text-align: center;
}

.writing-container > * {
  display: inline-block;
}

.conversation p {
  margin: 0;
  color: black;
}

.journal-entry {
  color: black;
  font-size: 0.75em;
  letter-spacing: 0.1em;
}
.speaker {
  margin: 0.75em;
  text-align: center;
  font-size: 0.8em;
  font-style: italic;
}
.dynamic-text {
  margin-top: 0.5em;
  text-align: center;
  font-size: 1em;
}

.blink-line {
  color: black;
  opacity: 0;
  animation: blink 0.7s linear 1.2s infinite;
}

.pc-bold {
  font-weight: bold;
}

.GodIntro {
  color: var(--cross-gold-300);
}

/* Dynamic typing */
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Conversations Selector */

.convo-links {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  height: 4em;
  width: 14em;
  transition: var(--transition);
}

.convo-links-animation {
  visibility: hidden;
  opacity: 0;
  animation: buttonsAppear 2.5s linear 12.5s normal forwards;
}

.convo-links-animation-short {
  visibility: hidden;
  opacity: 0;
  animation: buttonsAppear 2.5s linear 5s normal forwards;
}

@keyframes buttonsAppear {
  from {
    visibility: visible;
    opacity: 0.01;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}

.selected-btn {
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  border: none;
  transition: var(--transition);
  cursor: pointer;
}

.non-selected-btn {
  display: block;
  height: 1.2em;
  width: 1.2em;
  border-radius: 50%;
  border: none;
  transition: var(--transition);
  cursor: pointer;
  /* box-shadow: 0 0 0 0 rgba(3, 152, 158, 0.5); */
  animation: pulse 1.5s infinite;
}

.pulse-btn {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 1em rgba(34, 34, 34, 0);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(34, 34, 34, 0);
  }
}

.selected-convo {
  display: grid;
  justify-items: center;
  text-align: center;
}

.convo-title {
  color: black;
  font-size: 1em;
}

.despair-lnk {
  background: black;
}
.suffering-lnk {
  background: var(--cross-red-500);
}
.striving-lnk {
  background: var(--cross-blue-300);
}
.perfect-lnk {
  background: var(--cross-gold-300);
}

/* themes */

.powerful-themes-center {
  /* For the selected card to reference */
  position: relative;
}

.powerful-lessons-header {
  padding-top: 1rem;
  margin: 0 auto;
}

.powerful-lessons-header {
  position: relative;
  display: grid;
  place-items: center;
  padding: 0.5rem 0;
}
.powerful-themes-icon-container {
  margin: 0 auto;
  padding: 2em 0 4.5em 0;
  width: 100vw;
  height: auto;
  display: grid;
  place-content: center;
  grid-template-columns: 4.5em 4em 4em 4em 4.5em;
  grid-template-rows: 4em 4.5em 5.6em 7.2em 5em;
  grid-template-areas:
    "n g g g p"
    "c f f f d"
    "c e e e d"
    "c b b b d"
    "a a a a a";
  gap: 0.3em;
  overflow-x: hidden;
  transition: var(--transition);
}
.area {
  position: relative;
  border-radius: var(--borderRadius);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: transparent;
  gap: 0.7em;
  transition: var(--transition);
}

.area i,
.area svg {
  font-size: 1.5em;
}

.area:hover .theme-letters {
  color: white;
  letter-spacing: 0.2em;
}
.theme-letters {
  text-transform: capitalize;
  font-size: 1em;
  letter-spacing: 0.1em;
  margin: 0;
  font-family: var(--headingFont);
  transition: var(--transition);
}

.vertical-l {
  /* position: absolute; */
  writing-mode: vertical-rl;
  text-orientation: mixed;
  margin-top: 2em;
  transform-origin: bottom 1em;
  transform: rotate(180deg);
}
.vertical i,
.vertical svg {
  position: absolute;
  content: "";
  top: 1.5em;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s ease-in-out transform;
}
.vertical:hover i,
.vertical:hover svg {
  transform: translate(-50%, -0.4em);
}
.areaA {
  grid-area: a;
}
.areaB {
  grid-area: b;
}
.areaC {
  grid-area: c;
}
.areaD {
  grid-area: d;
}
.areaE {
  grid-area: e;
}
.areaF {
  grid-area: f;
}
.areaG {
  grid-area: g;
}

/* Theme quote cards */
/*specific grid quote colors*/
.jade-theme:hover {
  background: var(--jade);
  color: white;
}
.jade-theme {
  color: var(--jade);
}
.green-theme:hover {
  background: var(--cross-green-300);
  color: white;
}
.green-theme {
  color: var(--cross-green-300);
}
.glory-blue-theme:hover {
  background: var(--glory-blue-300);
  color: white;
}
.glory-blue-theme {
  color: var(--glory-blue-300);
}
.red-theme:hover {
  background: var(--cross-red-300);
  color: white;
}
.red-theme {
  color: var(--cross-red-300);
}
.gold-theme:hover {
  background: var(--cross-gold-200);
  color: white;
}
.gold-theme {
  color: var(--cross-gold-200);
}
.brown-theme:hover {
  background: var(--eucharist);
  color: white;
}
.brown-theme {
  color: var(--eucharist);
}
.purple-theme:hover {
  background: var(--purple);
  color: white;
}
.purple-theme {
  color: var(--purple);
}
.cross-blue-theme:hover {
  background: var(--cross-blue-300);
  color: white;
}
.cross-blue-theme {
  color: var(--cross-blue-300);
}

.gold-theme:hover {
  background: var(--cross-gold-300);
  color: white;
}
.gold-theme {
  color: var(--cross-gold-300);
}

.dm-theme:hover i,
.dm-theme:hover svg {
  color: var(--cross-red-300);
}

/* theme stars */

#theme-title {
  font-size: 1.4em;
  text-align: center;
  margin: 1em auto 0 auto;
}

/* Question Mark Icon*/
.information-icon {
  background: none;
  border: none;
}
.information-icon svg {
  margin: 0 auto;
  cursor: pointer;
  width: 1.2em;
  height: 1.2em;
}
.icon-bounce svg {
  animation: bounce 2s linear infinite;
}
@keyframes bounce {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.information-msg-dm {
  position: absolute;
  top: 0.8em;
  height: auto;
  width: 16em;
  background: black;
  font-family: var(--bodyFont);
  border: 1px white solid;
  border-radius: var(--borderRadius);
  z-index: 100;
  display: grid;
  place-items: center;
}
.information-msg-dm p {
  font-size: 0.7em;
  font-style: italic;
  color: var(--white);
  padding: 1em 1em 0 1em;
  text-align: left;
  margin: 0;
}
.information-msg-dm i,
.information-msg-dm svg {
  font-size: 0.7em;
}

.theme-grid-container {
  position: relative;
  height: 80vh;
  width: 100%;
  background: black;
}
.theme-grid-container svg {
  width: 100%;
  height: 100%;
}

.theme-grid-container button {
  position: absolute;
  content: "";
  top: -2em;
  left: 1em;
  border: none;
  background: transparent;
  z-index: 1000;
  cursor: pointer;
  width: 2.9em;
  height: 2.1em;
}

.theme-grid-container button i,
.theme-grid-container button svg {
  font-size: 1em;
}

.theme-pg-back-arrow {
  font-size: 1.5em;
  color: var(--glory-blue-300);
  border-radius: 0.1em;
  border: 1px solid var(--glory-blue-300);
  padding: 0.25em 0.25em;
}

.lessons-stars {
  position: absolute;
  content: "";
  border-radius: 50%;
  opacity: 0;
  font-size: 1.2em;
  transition: var(--transition);
}

.lessons-orbs {
  position: absolute;
  content: "";
  width: 0.4em;
  height: 0.4em;
  border-radius: 50%;
  opacity: 1;
  /* font-size: 1em; */
  transition: var(--transition);
  background: var(--glory-blue-300);
  color: var(--cross-gold-100);
  box-shadow: 0 0 0 3px rgba(9, 216, 231, 0.2);
  /* outline: 1px solid var(--glory-blue-300);
  outline-offset: 0.1em; */
  cursor: pointer;
}

.lessons-orbs:hover {
  transform: scale(1.5);
}

.orb-trans-1 {
  /* animation: bounce-orb 1.5s linear infinite; */
  animation: bounce-orb 1.5s infinite;
}
.orb-trans-2 {
  animation: bounce-orb 1.5s linear 0.7s infinite;
}
.orb-trans-3 {
  animation: bounce-orb 1.5s linear 1.4s infinite;
}
.orb-trans-4 {
  animation: bounce-orb 1.5s linear 2.1s infinite;
}
.orb-trans-5 {
  animation: bounce-orb 1.5s linear 2.8s infinite;
}
.orb-trans-6 {
  animation: bounce-orb 1.5s linear 1s infinite;
}
.orb-trans-7 {
  animation: bounce-orb 1.5s linear 2.4s infinite;
}

@keyframes bounce-orb {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

/* theme element */

.selected-content::-webkit-scrollbar {
  width: 0.2em;
}

.selected-content::-webkit-scrollbar-track {
  background: none;
  border-radius: 100vw;
  margin-block: 0.5rem;
}

.selected-content::-webkit-scrollbar-thumb {
  border-radius: 100vw;
  cursor: pointer;
  /* background: rgba(255, 255, 255, 0.5); */
  background: var(--glory-blue-400);
}

.selected-content::-webkit-scrollbar-thumb:hover {
  background: var(--glory-blue-300);
}

/* card  */
.selected-quote {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  width: 20em;
  background: black;
  border-radius: var(--borderRadius);
  /* box-shadow: 0 0 3px 4px var(--glory-blue-300); */
  /* animation: borderRadiate 3s ease-out infinite; */
  box-shadow: 0 0 7px 5px var(--glory-blue-200);
  z-index: 1000;
}

/* @keyframes borderRadiate {
  0% {
    box-shadow: 0 0 1px 1px var(--glory-blue-300);
  }
  25% {
    box-shadow: 0 0 3px 3px var(--glory-blue-200);
  }
  50% {
    box-shadow: 0 0 7px 5px var(--glory-blue-100);
  }
  75% {
    box-shadow: 0 0 3px 3px var(--glory-blue-200);
  }
  100% {
    box-shadow: 0 0 1px 1px var(--glory-blue-300);
  }
} */
.selected-title {
  font-family: var(--headingFont);
  font-size: 1.2em;
  width: 100%;
  display: grid;
  place-items: center;
}

.selected-content {
  font-family: var(--bodyFont);
  width: 100%;
  display: grid;
  place-items: center;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: hidden;
  text-align: left;
  font-size: 1em;
  max-height: 30em;
  letter-spacing: 0.01em;
  overflow-y: auto;
}
.selected-content p {
  max-width: none;
}

.selected-content p {
  margin: 0.5em;
  font-size: 0.7em;
}

.selected-title p,
.selected-footer p {
  margin: 0;
  font-size: 0.8em;
}

.grid-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* color: var(--white); */
  font-size: 1.5em;
  transition: var(--transition);
}

.lock-icon {
  position: absolute;
  top: 0px;
  left: 10px;
  /* transform: translate(-50%, -50%); */
  /* color: var(--white); */
  font-size: 1.5em;
  height: 1.1em;
  width: 1.1em;
}
.selected-footer {
  display: grid;
  /* justify-content: space-evenly; */
  grid-template-columns: 1fr 4em 1fr;
  place-items: center;
  text-align: center;
  padding: 0.3em;
  font-family: var(--headingFont);
}

/* .quote-radiate-orb {
  position: absolute;
  content: "";
  height: 0.5em;
  width: 0.5em;
  border-radius: 50%;
  background: var(--glory-blue-100);
  transition: var(--transition);
  opacity: 0;
}

*/

@media screen and (min-width: 500px) {
  .powerful-lessons-section {
    font-size: 20px;
  }
}

@media screen and (min-width: 700px) {
  .powerful-conversations-section {
    font-size: 20px;
  }
  .powerful-lessons-section {
    font-size: 24px;
  }
  .theme-letters {
    font-size: 1.2em;
  }
  .theme-grid-container button i,
  .theme-grid-container button svg {
    font-size: 1.6em;
  }
}

@media screen and (min-width: 800px) {
  /* conversations */
  .powerful-conversations-section {
    font-size: 21px;
  }
  /* themes */
  .powerful-themes-icon-container {
    grid-template-columns: 5.2em 4.8em 4.8em 4.8em 5.2em;
  }
  .theme-letters {
    font-size: 1.5em;
    letter-spacing: 0.2em;
  }
  .area i,
  .area svg {
    font-size: 1.6em;
  }
  .vertical i,
  .vertical svg {
    top: 3em;
  }
  .area {
    gap: 1em;
  }
}
@media screen and (min-width: 950px) {
  .powerful-conversations-section {
    font-size: 24px;
  }
  .powerful-lessons-section {
    font-size: 30px;
  }
  .theme-grid-container button i,
  .theme-grid-container button svg {
    font-size: 2em;
  }
}

@media screen and (min-width: 1000px) and (max-aspect-ratio: 12/14) {
  .lock-icon {
    top: 0px;
    left: 0.4em;
    /* height: 1.1em;
  width: 1.1em; */
  }
}

@media screen and (min-width: 1000px) and (min-aspect-ratio: 12/14) {
  .powerful-lessons-section {
    font-size: 20px;
  }
  .theme-grid-container button {
    left: 5em;
  }
  .lock-icon {
    top: 0px;
    left: 0.4em;
    /* height: 1.1em;
  width: 1.1em; */
  }
}

@media screen and (min-width: 1600px) {
  .powerful-themes-icon-container {
    grid-template-columns: 6.1em 6.1em 6.1em 6.1em 6.1em;
    grid-template-rows: 4.3em 4.8em 5.9em 7.5em 5.3em;
  }
}

@media screen and (min-width: 1900px) {
  .powerful-themes-icon-container {
    grid-template-rows: 5em 5.5em 6.6em 8.2em 6em;
  }
}

@media screen and (min-width: 2200px) {
  .powerful-conversations-section {
    font-size: 29px;
  }
  .powerful-lessons-page {
    margin-top: 5.5rem;
  }
  .powerful-lessons-section {
    font-size: 37px;
  }
  .theme-grid-container button {
    left: 5em;
    width: 4em;
    height: 3.4em;
  }

  .theme-grid-container button i,
  .theme-grid-container button svg {
    font-size: 3em;
  }

  .theme-letters {
    font-size: 1.8em;
    letter-spacing: 0.4em;
  }
  .area i,
  .area svg {
    font-size: 2em;
  }
  .vertical i,
  .vertical svg {
    top: 3em;
  }
  .area {
    gap: 1em;
  }
}

@media screen and (min-width: 2500px) {
  .powerful-conversations-section {
    font-size: 30px;
  }
  .information-icon svg {
    width: 1.5em;
    height: 1.5em;
  }
  .powerful-themes-icon-container {
    grid-template-rows: 4.3em 4.8em 5.9em 7.5em 5.3em;
  }
}

@media screen and (min-width: 2700px) {
  .powerful-conversations-section {
    font-size: 35px;
  }
  .powerful-lessons-section {
    font-size: 43px;
  }
  .theme-grid-container button {
    width: 4.2em;
    height: 3.6em;
  }
  .theme-grid-container button i,
  .theme-grid-container button svg {
    font-size: 4em;
  }
  .information-icon svg {
    width: 1.8em;
    height: 1.8em;
  }
  .theme-letters {
    font-size: 2.4em;
    letter-spacing: 0.4em;
  }
  .area i,
  .area svg {
    font-size: 2.4em;
  }
  .vertical i,
  .vertical svg {
    top: 3em;
  }
  .area {
    gap: 1em;
  }
}

@media screen and (min-width: 3200px) {
  .powerful-lessons-page {
    margin-top: 6rem;
  }
  .powerful-conversations-section {
    font-size: 37px;
  }
  .theme-grid-container button i {
    font-size: 5em;
  }
}

@media screen and (min-width: 3800px) {
  .powerful-lessons-page {
    margin-top: 10rem;
  }
  .powerful-conversations-section {
    font-size: 42px;
  }
  .powerful-lessons-section {
    font-size: 49px;
  }
}
