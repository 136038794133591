/* Queen Quotes Edit
.rosary-graces-grid
.q-quote-content{}
.q-quote {height: 28em;
} */

.ro-arrowAnim,
.ri-arrowAnim,
.rm-arrowAnim {
  width: 3em;
  height: 3em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: transparent;
  /* font-size: 8px; */
}

/* Made into inline styles */

.message-container {
  justify-content: center;
  display: flex;
  justify-items: center;
  align-items: center;
  margin-top: 0.2em;
}

.chess-q,
.fire,
.clock {
  color: var(--cross-gold-300);
  border-color: var(--orange);
  animation: bounce 1.8s linear infinite;
}

.fire {
  font-size: 1.2em;
}

/* Arrow Code */
.arrow {
  margin-left: -0.33em;
  width: 1em;
  height: 1em;
  border: 0.2em solid;
  border-color: rgba(219, 190, 73, 0.4) transparent transparent rgba(219, 190, 73, 0.4);
  /* background: blue; */
  transform: rotate(135deg);
}
.delay3 {
  animation: glimmer 1.2s linear infinite;
  /* base-setup: animation-delay needs to be half of total runtime of animation */
  /* then reduce this number to make it glide, and adjust below animation delay accordingly in half */
  animation-delay: 0.35s;
}
.delay2 {
  animation: glimmer 1.2s linear infinite;
  /* base-setup: animation-delay of delay2 needs to be half of animation-delay of delay1 */
  animation-delay: 0.175s;
}
.delay1 {
  animation: glimmer 1.2s linear infinite;
  animation-delay: 0s;
}

@keyframes glimmer {
  0% {
    border-color: rgba(219, 190, 73, 0.4) transparent transparent rgba(219, 190, 73, 0.4);
  }
  12.5% {
    border-color: rgba(219, 190, 73, 0.7) transparent transparent rgba(219, 190, 73, 0.7);
  }
  25% {
    border-color: rgba(219, 190, 73, 1) transparent transparent rgba(219, 190, 73, 1);
  }
  37.5% {
    border-color: rgba(219, 190, 73, 0.7) transparent transparent rgba(219, 190, 73, 0.7);
  }
  50% {
    border-color: rgba(219, 190, 73, 0.4) transparent transparent rgba(219, 190, 73, 0.4);
  }
}

.message {
  display: grid;
  grid-template-rows: auto;
  align-items: center;
  margin: 0;
  white-space: nowrap;
  cursor: pointer;
}

.message p {
  margin: 0 0 0 0.4em;
  font-size: 1em;
  color: var(--cross-gold-300);
}

/* Same Arrow but blue*/
.arrow-blue {
  margin-left: -0.33em;
  width: 1em;
  height: 1em;
  border: 0.2em solid;
  border-color: rgba(3, 152, 158, 0.4) transparent transparent rgba(3, 152, 158, 0.4);
  /* background: blue; */
  transform: rotate(135deg);
}
.delay3-blue {
  animation: glimmer-blue 1.4s linear infinite;
  /* base-setup: animation-delay needs to be half of total runtime of animation */
  /* then reduce this number to make it glide, and adjust below animation delay accordingly in half */
  animation-delay: 0.4s;
}
.delay2-blue {
  animation: glimmer-blue 1.4s linear infinite;
  /* base-setup: animation-delay of delay2 needs to be half of animation-delay of delay1 */
  animation-delay: 0.2s;
}
.delay1-blue {
  animation: glimmer-blue 1.4s linear infinite;
  animation-delay: 0s;
}

@keyframes glimmer-blue {
  0% {
    border-color: rgba(3, 152, 158, 0.4) transparent transparent rgba(3, 152, 158, 0.4);
  }
  12.5% {
    border-color: rgba(3, 152, 158, 0.7) transparent transparent rgba(3, 152, 158, 0.7);
  }
  25% {
    border-color: rgba(3, 152, 158, 1) transparent transparent rgba(3, 152, 158, 1);
  }
  37.5% {
    border-color: rgba(3, 152, 158, 0.7) transparent transparent rgba(3, 152, 158, 0.7);
  }
  50% {
    border-color: rgba(3, 152, 158, 0.4) transparent transparent rgba(3, 152, 158, 0.4);
  }
}

.message-blue {
  display: grid;
  grid-template-rows: auto;
  align-items: center;
  margin: 0;
  white-space: nowrap;
  cursor: pointer;
}

.message-blue p {
  margin: 0 0 0 0.4em;
  font-size: 1em;
  color: var(--glory-blue-300);
}
/* End of Arrows */

.rosary-page {
  margin-top: 4rem;
  position: relative;
}

.section-title {
  /* font-size: 1.5rem; */
  font-size: 1.5em;
  text-align: center;
  letter-spacing: 0.05em;
}
/* Context Section */
.rosary-context {
  padding-top: 1.5rem;
  margin: 0 auto;
  min-height: fit-content;
  font-size: 16px;
  text-align: center;
  /* background: radial-gradient(#1d9eaf, #078f96); */
  background: radial-gradient(#1d9eaf 0%, #078f96 80%, #076b96 100%);
}
.rosary-context h1 {
  margin-bottom: 0;
  color: white;
  font-size: 1.8em;
}
.rosary-context-visionary {
  font-family: var(--headingFont);
  font-size: 1.2em;
  font-weight: bold;
  letter-spacing: 0.1em;
  margin-bottom: 1em;
  margin: 0.75em auto 0 auto;
  color: #3f1da5;
  /* color: #e1bd45; */
  text-shadow: 0 0 0.05em white;
}
/* When the Visionary Text is too long, and might throw off the screen */
.rosary-context-visionary-lt {
  font-family: var(--headingFont);
  font-size: 1.05em;
  font-weight: bold;
  letter-spacing: 0.1em;
  margin-bottom: 1em;
  margin: 0.75em auto 0 auto;
  color: #3f1da5;
  /* color: #e1bd45; */
  text-shadow: 0 0 0.05em white;
}
.rosary-context-visionary-loc {
  font-family: var(--headingFont);
  text-shadow: 0 0 0.05em black;
  font-style: italic;
  font-size: 0.8em;
  letter-spacing: 0.1em;
  margin-bottom: 1em;
  margin: 0 auto;
  color: var(--white);
}

.rosary-context-about-title {
  font-family: var(--headingFont);
  font-size: 1.2em;
  font-weight: bold;
  letter-spacing: 0.1em;
  margin-bottom: 1em;
  margin: 0.75em auto 0 auto;
  color: #e1bd45;
  /* color: #e1bd45; */
  text-shadow: 0 0 0.05em black;
}

/* When modifying height here, need to modify height for rosary-context-info */
.rosary-context-info-pane {
  position: relative;
  height: 28em;
  background: rgba(0, 0, 0, 0.05);
}

/* Need to distinguish between -> context-info-full & context-info because context-info has titles above */
.rosary-context-info-full {
  text-align: left;
  color: white;
  font-size: 0.9em;
  text-shadow: 0 0 0.05em black;
  padding: 1em 0.5em 0 0.5em;
  margin: 0;
  letter-spacing: 0.1em;
  /* height: 28em; */
  height: 30em;
  overflow-y: auto;
}

.rosary-context-info {
  text-align: left;
  color: white;
  font-size: 0.9em;
  text-shadow: 0 0 0.05em black;
  padding: 1em 0.5em;
  margin: 0;
  letter-spacing: 0.1em;
  height: 23em;
  overflow-y: auto;
}

.rosary-context-info::-webkit-scrollbar,
.rosary-context-info-full::-webkit-scrollbar {
  width: 0.2em;
}

.rosary-context-info::-webkit-scrollbar-track,
.rosary-context-info-full::-webkit-scrollbar-track {
  background: none;
  border-radius: 100vw;
  margin-block: 0.5rem;
}

.rosary-context-info::-webkit-scrollbar-thumb,
.rosary-context-info-full::-webkit-scrollbar-thumb {
  border-radius: 100vw;
  cursor: pointer;
  /* background: rgba(255, 255, 255, 0.5); */
  background: white;
}

.rosary-context-info::-webkit-scrollbar-thumb:hover,
.rosary-context-info-full::-webkit-scrollbar-thumb:hover {
  background: var(--white);
}

.rosary-context-btn-container {
  box-shadow: 0 0 0 0 #e1bd45;
  padding: 1em 0 1em 0;
  margin: 0 auto;
  width: 85vw;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  place-items: center;
  transition: var(--transition);
  cursor: pointer;
  z-index: 500;
}

.rosary-context-btn-about {
  border-radius: 0.2em;
  border: none;
  letter-spacing: 0.05em;
  padding: 0.1em 0.5em;
  font-style: italic;
  /* font-weight: bold; */
  display: flex;
  flex-direction: row;
  place-items: center;
  margin-right: 0.5em;
  font-size: 1em;
}

.rosary-context-btn-about:hover {
  animation: pulseq_about 0.75s forwards;
}

.context-btn-about-s {
  background: #e1bd45;
  letter-spacing: 0.05em;

  color: white;
}
.context-btn-about-s i {
  font-size: 1em;
}

.context-btn-about-ns {
  background: white;
  letter-spacing: 0.05em;
  transform: scale(0.9);
  color: #e1bd45;
  /* margin-right: 0.5em; */
}
.context-btn-about-ns i {
  font-size: 0.9em;
}

@keyframes pulseq_about {
  0% {
    transform: scale(0.9);
  }
  100% {
    letter-spacing: 0.1em;
    transform: scale(1);
  }
}

.rosary-context-btn-quote:hover {
  animation: pulseq_gold 1.5s forwards;
}

.rosary-context-btn-quote {
  border-radius: 50%;
  border: none;
}

.context-btn-quote-dec-ns {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  background: white;
  width: 1.2em;
  height: 1.2em;
}

.context-btn-quote-dec-s {
  box-shadow: 0 0 0 0 rgba(225, 189, 69, 1);
  background: #e1bd45;
  transform: scale(1.2);
  width: 1.4em;
  height: 1.4em;
}

.context-btn-quote-dec-provoke {
  box-shadow: 0 0 0 0 rgba(225, 189, 69, 1);
  background: #e1bd45;
  transform: scale(1.2);
  width: 1.4em;
  height: 1.4em;
  animation: pulseq_gold 1.5s infinite;
}

@keyframes pulseq_gold {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 1em rgba(225, 189, 69, 0);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgb(225, 189, 69, 0);
  }
}

/*  */

.rosary-card {
  margin: 0 auto;
  border-radius: var(--borderRadius);
  position: relative;
  perspective: 1500px;
  transform-style: preserve-3d;
  width: 90vw;
  height: 50vh;
  transition: var(--transition);
  /* max-width: fit-content; */
}

.rosary-card:hover .ht-card-side {
  box-shadow: 0 20px 25px -5px rgba(212, 28, 28, 0.75), 0 8px 10px -6px rgba(212, 28, 28, 0.75);
}

.ht-card-side {
  transition: all 2s linear;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  /*transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: var(--shadow-4);
  cursor: pointer;
}
.ht-card-front {
  border-radius: var(--borderRadius);
}

.rt-card-back-header {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  border-bottom: 1px solid black;
}

.rosary-card:hover .ht-card-back-content {
  overflow-y: scroll;
}

.ht-card-back {
  transform: rotateY(180deg);
  border-radius: var(--borderRadius);
  display: grid;
  grid-template-rows: 2em auto 1.5em;
  padding: 0.5em 0.5em 0.75em 0.5em;
  background: var(--grey-100);
}
.rt-origin-card-back {
  transform: rotateY(180deg);
  border-radius: var(--borderRadius);
  display: grid;
  grid-template-rows: 2em auto;
  padding: 0.5em 0.5em 0.75em 0.5em;
  background: var(--grey-100);
}

.ht-card-back-content {
  /* overflow-y: hidden; */
  overflow-y: hidden;
  /* -webkit-overflow-scrolling: touch; */
  overflow-x: hidden;
  transition: var(--transition);
  scrollbar-width: thin;
  scrollbar-color: var(--purple) transparent;
  scroll-behavior: smooth;
}

.ht-card-back-content p {
  margin: 0;
  font-size: 0.95em;
}

.ht-card-back-content::-webkit-scrollbar {
  width: 0.5em;
  /* for horizontal scroll bars */
  height: 1rem;
}

.ht-card-back-content::-webkit-scrollbar-track {
  background: #555;
  border-radius: 100vw;
  margin-block: 0.3rem;
  margin-right: 0;
}

.ht-card-back-content::-webkit-scrollbar-thumb {
  border-radius: 100vw;
  cursor: pointer;
}

.mb-screen-hidden {
  display: none;
}

.invisible-btn {
  background: none;
  border: none;
}

/* btn styling */

/* .seperator {
  border-top: 2px dashed var(--white);
} */
/* .learn-btn {
  width: 90vw;
  height: auto;
  max-height: 20em;
  max-width: 900px;
  border-radius: var(--borderRadius);

  color: var(--white);
  overflow-y: auto;
  transition: 0.15s ease-in-out all;
} 

.learn-btn:hover {
  transform: scale(1.05);
}*/

.learn-btn::-webkit-scrollbar-thumb {
  background: var(--grey-200);
}

.center {
  text-align: center;
}
.center-blue {
  color: var(--grey-600);
  font-weight: bold;
  text-align: center;
}

/* prayer specific */
.prayer {
  margin-bottom: 0.4em;
  padding: 0.4em;
}
.prayer p {
  padding: 0;
}

.prayer ul li {
  margin-left: 2em;
  font-size: 0.8em;
}

.rosary-img-container {
  width: 100%;
  display: grid;
  place-items: center;
}
.rosary-img {
  width: 100%;
  height: 100%;
  max-width: 500px;
  object-fit: contain;
}

/* mystery styling */
.mystery {
  margin-bottom: 0.4em;
  border-bottom: 2px dashed white;
  padding: 0.4em;
}
.mystery p {
  /* margin-bottom: 0.5em; */
  padding: 0;
}

.mystery-title {
  font-size: 1.6em;
  font-family: var(--headingFont);
  letter-spacing: 0.05em;
}

#intention {
  margin-top: 0.5em;
  font-style: italic;
  color: var(--cross-gold-300);
}

#intention-blue {
  margin-top: 0.5em;
  font-style: italic;
  color: var(--grey-600);
}

/* Rosary Graces Section */

.rosary-graces {
  /* margin: 0 auto; */
  min-height: fit-content;
  height: auto;
  font-size: 15px;
  background: black;
  position: relative;
  padding-bottom: 1em;
  transform-style: flat;
  /* z-index: 1000; */
  z-index: 10;
}
.rosary-graces-stars {
  position: absolute;
  content: "";
  border-radius: 50%;
  opacity: 0;
  font-size: 1.4em;
  transition: var(--transition);
}
.star1 {
  animation: starFade 4s linear 0.2s infinite;
}
.star2 {
  animation: starFadeOther 4s linear 0.57s infinite;
}
.star3 {
  animation: starFade 4s linear 1.14s infinite;
}
.star4 {
  animation: starFadeOther 4s linear 1.71s infinite;
}
.star5 {
  animation: starFade 4s linear 2.28s infinite;
}
.star6 {
  animation: starFadeOther 4s linear 2.85s infinite;
}
.star7 {
  animation: starFade 4s linear 3.1s infinite;
}

@keyframes starFade {
  0% {
    opacity: 0;
    color: var(--glory-blue-300);
  }
  7.1% {
    opacity: 0.5;
  }
  11% {
    opacity: 1;
    color: var(--glory-blue-100);
  }
  14% {
    opacity: 0.5;
  }
  20% {
    opacity: 0;
  }
}
@keyframes starFadeOther {
  0% {
    opacity: 0;
    color: var(--glory-blue-300);
  }
  7.1% {
    opacity: 0.5;
  }
  24% {
    opacity: 1;
    color: var(--glory-blue-100);
  }
  27% {
    opacity: 0.5;
  }
  30% {
    opacity: 0;
  }
}

.rosary-graces-header {
  position: relative;
  padding-top: 1rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rosary-graces-header h2 {
  margin: 0 auto;
}

/* Question Mark Icon*/
.information-icon {
  background: none;
  border: none;
  font-size: 0.8em;
}
.information-icon svg {
  margin: 0 auto;
  cursor: pointer;
  width: 1.2em;
  height: 1.2em;
}
.icon-bounce svg {
  animation: bounce 2s linear infinite;
}
@keyframes bounce {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/*  */
.information-msg {
  position: absolute;
  top: 1em;
  height: auto;
  width: 14em;
  background: black;
  font-family: var(--bodyFont);
  border: 1px white solid;
  border-radius: var(--borderRadius);
  z-index: 100;
  display: grid;
  place-items: center;
}
.information-msg p {
  font-size: 0.7em;
  font-style: italic;
  color: var(--white);
  padding: 1em 1em 0 1em;
  text-align: left;
  margin: 0;
}
.information-msg i {
  font-size: 0.7em;
}
.information-icons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.rosary-context-subheader {
  margin: 0.5em auto;
  font-size: 0.9em;
  transition: var(--transition);
  animation: glow 7s ease-in-out infinite;
  cursor: pointer;
}
@keyframes glow {
  0% {
    color: var(--white);
  }
  50% {
    color: #e1bd45;
  }
  100% {
    color: var(--white);
  }
}
.queen-center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2em;
  padding: 0 1em 0 1em;
}
.rosary-graces-grid {
  padding: 1.5em 0 1em 0;
  /* width: 90vw; */
  display: grid;
  grid-template-rows: 4em 19em 4em;
  /* grid-template-rows: 7vh 33vh 7vh; */
  place-items: center;
}

.row {
  display: grid;
  grid-template-columns: 3.7em 14.2em 3.7em;
}

.midCol {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.right,
.left {
  display: flex;
  flex-direction: column;
  gap: 1.2em;
  justify-content: space-between;
}
.midCol {
  position: relative;
}
.col {
  margin: 0 auto;
}

.r-grace {
  height: 3.8em;
  width: 3.8em;
  border-radius: var(--borderRadius);
  display: grid;
  place-items: center;
  transition: var(--transition);
  cursor: pointer;
}
/* normal colored grace */
.nc-grace {
  border: 2px solid var(--purple);
}
/* selected colored grace */
.sc-grace {
  border: 2px solid var(--glory-blue-100);
}

.nc-grace i,
.sc-grace i {
  font-size: 1.7em;
}

.r-last-piece {
  position: absolute;
  height: 2em;
  width: 2em;
  border-radius: var(--borderRadius);
  top: -2.5em;
  right: 0.3em;
}

.purple {
  color: var(--purple);
}
.blue {
  color: var(--glory-blue-100);
}

.nc-grace:hover {
  background: var(--purple);
}

.nc-grace:hover i {
  color: var(--white);
}

.sc-grace:hover {
  background: var(--glory-blue-100);
}
.sc-grace:hover i {
  color: var(--white);
}

.corner {
  position: relative;
}
.corner-grace {
  position: absolute;
  top: 0.75em;
  left: -1.5em;
  height: 4.4em;
  width: 4.4em;
  /* height: 4em;
  width: 4em; */
  border-radius: var(--borderRadius);
  display: grid;
  place-items: center;
  /* margin: 1em 0 0 1em; */
}
.grace-quote-cell {
  overflow-y: auto;
  padding: 0 0.5em;
  display: grid;
  grid-template-rows: 2em 1fr;
  place-items: center;
}
.grace-quote-header > * {
  display: inline-block;
  margin: 0 0 0 0.5em;
}
.grace-quote-header i,
.grace-quote-header svg,
.grace-quote-header p {
  color: var(--glory-blue-100);
  font-size: 1em;
}

.grace-quote {
  color: var(--glory-blue-100);
  font-size: 0.75em;
  font-style: italic;
  letter-spacing: 0.1em;
  text-shadow: 0 0 0.1em var(--glory-blue-100), 0 0 0.2em var(--glory-blue-100);
  /* animation: textGlow 4s ease-in-out 4s infinite alternate; */
}

@keyframes buttonGlow {
  5% {
    background: currentColor;
  }
  10% {
    background: none;
  }
}

@keyframes textGlow {
  to {
    text-shadow: 0 0 0.1em var(--glory-blue-100), 0 0 0.2em var(--glory-blue-100), 0 0 0.3em var(--glory-blue-100),
      0 0 0.4em var(--glory-blue-100), 0 0 0.5em var(--glory-blue-100);
  }
}
.glow-1 {
  animation: buttonGlow 4s linear calc(1s * 0.07) infinite;
}
.glow-2 {
  animation: buttonGlow 4s linear calc(2s * 0.07) infinite;
}
.glow-3 {
  animation: buttonGlow 4s linear calc(3s * 0.07) infinite;
}
.glow-4 {
  animation: buttonGlow 4s linear calc(4s * 0.07) infinite;
}
.glow-5 {
  animation: buttonGlow 4s linear calc(5s * 0.07) infinite;
}
.glow-6 {
  animation: buttonGlow 4s linear calc(6s * 0.07) infinite;
}
.glow-7 {
  animation: buttonGlow 4s linear calc(7s * 0.07) infinite;
}
.glow-8 {
  animation: buttonGlow 4s linear calc(8s * 0.07) infinite;
}
.glow-9 {
  animation: buttonGlow 4s linear calc(9s * 0.07) infinite;
}
.glow-10 {
  animation: buttonGlow 4s linear calc(10s * 0.07) infinite;
}
.glow-11 {
  animation: buttonGlow 4s linear calc(11s * 0.07) infinite;
}
.glow-12 {
  animation: buttonGlow 4s linear calc(12s * 0.07) infinite;
}
.glow-13 {
  animation: buttonGlow 4s linear calc(13s * 0.07) infinite;
}
.glow-14 {
  animation: buttonGlow 4s linear calc(14s * 0.07) infinite;
}
.glow-15 {
  animation: buttonGlow 4s linear calc(15s * 0.07) infinite;
}

/* Queen Quotes */

.q-quote {
  color: white;
  text-align: center;
  transition: var(--transition);
  height: 28em;
}
.q-quote-verse {
  font-family: var(--headingFont);
  font-size: 1.2em;
  font-weight: bold;
  letter-spacing: 0.1em;
  /* animation: fade-inout 18s linear infinite; */
  margin-bottom: 1em;
  /* max-width: 23em; */
  margin: 0 auto;
  /* color: var(--glory-blue-300); */
}

.q-quote-content {
  font-style: italic;
  font-size: 0.9em;
  letter-spacing: 0.1em;
  animation: fade-in 2s linear forwards;
  line-height: 2em;
  color: var(--glory-blue-100);
  text-shadow: 0 0 0.1em var(--glory-blue-100), 0 0 0.2em var(--glory-blue-100);
  opacity: 0;
  overflow-y: auto;
  max-height: 24em;
  margin-bottom: 0;
  width: 90vw;
  margin: 0 auto;
  text-align: left;
  scrollbar-width: thin;
  scrollbar-color: var(--glory-blue-400) transparent;
  scroll-behavior: smooth;
}

.q-quote-content::-webkit-scrollbar {
  width: 0.2em;
}

.q-quote-content::-webkit-scrollbar-track {
  background: none;
  border-radius: 100vw;
  margin-block: 0.5rem;
}

.q-quote-content::-webkit-scrollbar-thumb {
  border-radius: 100vw;
  cursor: pointer;
  /* background: rgba(255, 255, 255, 0.5); */
  background: var(--glory-blue-400);
}

.q-quote-content::-webkit-scrollbar-thumb:hover {
  background: var(--glory-blue-300);
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}
.queen-q-links {
  margin-right: auto;
  margin-left: auto;
  margin-top: 1.5em;
  margin-bottom: 0;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  place-items: center;
  height: 2.5em;
  width: 18em;
}
.queen-link {
  background: rgba(93, 63, 211);
  box-shadow: 0 0 0 0 rgba(3, 152, 158, 1);
  height: 1.2em;
  width: 1.2em;
  border-radius: 50%;
  border: none;
  transition: var(--transition);
  cursor: pointer;
}

.queen-link:hover {
  animation: pulseq 1.5s forwards;
}

.queen-s-link {
  height: 1.4em;
  width: 1.4em;
  box-shadow: 0 0 0 0 rgba(3, 152, 158, 1);
  background: var(--glory-blue-300);
  color: var(--glory-blue-300);
}

.queen-ns-link {
  box-shadow: 0 0 0 0 rgba(93, 63, 211, 1);
  background: var(--purple);
  color: var(--purple);
}

@keyframes pulseq {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 1em rgba(3, 152, 158, 0);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(3, 152, 158, 0);
  }
}
.mb-screen-hidden-graces {
  display: none;
}

/* Rosary Inspirations */

.rosary-inspirations {
  height: auto;
  font-size: 16px;
}

.rosary-inspirations div h2 {
  margin-bottom: 0;
}

.rosary-flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  padding: 1em;
  /* scroll-snap-type: x mandatory;
  scroll-snap-align: center; */
  scroll-behavior: smooth;
  overflow-x: auto;
  overflow-y: scroll;
  overscroll-behavior-inline: contain;
  height: calc(var(--vh, 1vh) * 72);
  justify-content: center;
  scrollbar-width: thin;
  scrollbar-color: var(--purple) transparent;
  scroll-behavior: smooth;
}

/* .rosary-flex-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 1em;
  padding: 1em 25em 0.5em 10em;
  scroll-behavior: smooth;
  overflow-x: auto;
  overflow-y: hidden;
  overscroll-behavior-inline: contain;
} */

.rosary-inspiration-card {
  border-radius: var(--borderRadius);
  position: relative;
  perspective: 1500px;
  transform-style: preserve-3d;
  max-width: 95vw;
  /* width: 21em; */
  height: calc(var(--vh, 1vh) * 70);
  transition: var(--transition);
  flex-basis: 21em;
  flex-grow: 1;
  flex-shrink: 0;
}
.ht-content-paragraph p {
  text-indent: 1.5rem;
}

.rosary-inspiration-card:hover .ht-card-front {
  transform: rotateY(-180deg);
}

.shaded-title {
  text-shadow: 0 0 10px black;
  /* color: black; */
}

.rosary-inspiration-card:hover .ht-card-back {
  transform: rotateY(0);
}
.rosary-inspiration-card:hover .ht-card-back-content {
  overflow-y: scroll;
}
.rosary-inspiration-card:hover .ht-card-side {
  box-shadow: 0 20px 25px -5px rgba(93, 63, 211, 0.75), 0 8px 10px -6px rgba(93, 63, 211, 0.75);
}

.rt-insp-app-card-back-header {
  text-align: center;
  display: grid;
  /* grid-template-columns: 1.5em 1fr 1.5em; */
  grid-template-columns: auto 1fr auto;
  place-items: center;
  gap: 1em;
  border-bottom: 1px solid black;
}

.inspirational-sources li {
  list-style: none;
  margin-bottom: 0.75em;
  font-size: 1em;
  text-transform: capitalize;
}

/* Buttons */
.queen-story-btns {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  place-items: center;
  height: 2em;
  width: 18em;
}
.marian-story-btns {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  place-items: center;
  height: 2em;
  width: 18em;
}
.queen-story-btn {
  background: rgba(255, 223, 0, 1);
  height: 1.2em;
  width: 1.2em;
  border-radius: 50%;
  border: none;
  transition: var(--transition);
  cursor: pointer;
}

.queen-story-btn:hover {
  animation: pulseq 1.5s forwards;
}

.queen-story-btn-s {
  height: 1.4em;
  width: 1.4em;
  box-shadow: 0 0 0 0 rgba(3, 152, 158, 1);
  background: var(--glory-blue-300);
}

.queen-story-btn-ns {
  box-shadow: 0 0 0 0 rgba(3, 152, 158, 1);
  background: rgba(255, 223, 0, 1);
}

/* Rosary Reparations*/

.reparations-section {
  background: black;
  margin: 0;
  min-height: fit-content;
  font-size: 16px;
  padding-bottom: 1.5em;
}

.reparations-container {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-items: center;
  align-items: center;
  row-gap: 2em;
  height: 100%;
  width: 100%;
}

.rep-card {
  width: 90vw;
  border-radius: var(--borderRadius);
  /* overflow-y: scroll; */
  transition: var(--transition);
  height: 60vh;
  position: relative;
}

.first5-saturdays {
  border: 0.2em solid var(--cross-red-300);
}

.flame-of-love {
  border: 0.2em solid var(--cross-gold-300);
}

.rep-front-card {
  width: 100%;
  height: 100%;
  border-radius: var(--borderRadius);
  display: grid;
  grid-template-rows: 10fr 2fr;
  place-items: center;
}

.rep-front-card svg {
  height: 100%;
  width: 100%;
}

.rep-front-card > button {
  padding: 1em 1.8em;
  border-radius: var(--borderRadius);
  color: white;
  letter-spacing: 0.1em;
  border: none;
  transition: var(--transition);
  cursor: pointer;
}

.first5SatBtn {
  background: var(--cross-red-300);
}
.first5SatBtn:hover {
  background: var(--cross-gold-400);
}

.flameOfLoveBtn {
  background: var(--cross-gold-300);
}
.flameOfLoveBtn:hover {
  background: var(--orange);
}

.rep-back-card {
  display: grid;
  grid-template-rows: 2.5em calc(60vh - 3.3em);
  /* grid-template-rows: 2.5em 1fr; */
  /* grid-template-rows: 2.5em auto; */
  /* position: absolute; */
}

.rep-back-header {
  display: grid;
  grid-template-columns: 2em 1fr 2em;
}
.rep-back-title {
  font-size: 0.9em;
  color: white;
  text-align: center;
  margin: 0;
  letter-spacing: 0.1em;
  font-family: var(--headingFont);
}
.rep-info-text-body {
  overflow-y: scroll;
  overflow-x: hidden;
  /* transition: var(--transition); */
  /* height: 100%;
  width: 100%; */
  padding: 0 0.75rem 0.5rem 0.5rem;
  font-family: var(--bodyFont);
  scrollbar-width: thin;
  scrollbar-color: var(--cross-gold-300) transparent;
  scroll-behavior: smooth;
}
.rep-txt {
  font-size: 0.8em;
  letter-spacing: 0.1em;
  color: white;
  font-family: var(--bodyFont);
}

.bold-Jesus {
  font-weight: bold;
  color: var(--cross-gold-300);
}
.bold-Mary {
  font-weight: bold;
  color: var(--glory-blue-300);
}

.italic {
  font-style: italic;
}

/* Scroll Bar */
.rep-info-text-body::-webkit-scrollbar {
  width: 0.2em;
}

.rep-info-text-body::-webkit-scrollbar-track {
  background: none;
  border-radius: 100vw;
  margin-block: 0.5rem;
}

.rep-info-text-body::-webkit-scrollbar-thumb {
  border-radius: 100vw;
  cursor: pointer;
  /* background: rgba(255, 255, 255, 0.5); */
  background: var(--grey-500);
  z-index: 200;
}

.rep-info-text-body::-webkit-scrollbar-thumb:hover {
  background: var(--cross-red-300);
}

/* Scrollbar */
.rosary-flex-container::-webkit-scrollbar {
  width: 0.5rem;
  /* for horizontal scroll bars */
  height: 0rem;
}

.rosary-flex-container::-webkit-scrollbar-track {
  background: none;
  border-radius: 100vw;
  margin-block: 1rem;
}

.rosary-flex-container::-webkit-scrollbar-thumb {
  /* background: linear-gradient(var(--cross-gold-300), var(--cross-red-300)); */
  background: var(--glory-gold-300);
  border-radius: 100vw;
  cursor: pointer;
}

.rosary-flex-container::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(var(--white), var(--cross-red-300));
}

/*  */

@keyframes pulseq {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 1em rgba(3, 152, 158, 0);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(3, 152, 158, 0);
  }
}

/* Rosary Arrows */

@media screen and (min-width: 600px) {
  /* Rosary Origins */
  .prayer ul li {
    margin-left: 4em;
    font-size: 1em;
  }
  /* Rosary Graces */
  .rosary-graces {
    font-size: 17px;
  }
}

@media screen and (min-width: 400px) {
  /* Context Section */
  .rosary-context-info-full {
    font-size: 0.95em;
  }

  .rosary-context-info {
    font-size: 0.95em;
  }
}

@media screen and (min-width: 650px) {
  /* Rosary Inspirations */
  .queen-story-btns,
  .marian-story-btns {
    height: 0em;
  }

  .rosary-card:hover .ht-card-front {
    transform: rotateY(-180deg);
  }

  .rosary-card:hover .ht-card-back {
    transform: rotateY(0);
  }

  .rosary-card:hover .rt-origin-card-back {
    transform: rotateY(0);
  }

  .rosary-flex-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 1em;
    padding: 1em 5em;
    overflow-x: scroll;
    width: 100vw;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: unset;
  }

  .rosary-flex-container::-webkit-scrollbar {
    width: 0.5em;
    /* for horizontal scroll bars */
    height: 0.7rem;
  }

  .rosary-flex-container::-webkit-scrollbar-track {
    background: #555;
    border-radius: 100vw;
    margin-block: 0.3rem;
    margin-right: 0;
  }

  .rosary-flex-container::-webkit-scrollbar-thumb {
    border-radius: 100vw;
    cursor: pointer;
    background: var(--glory-blue-300);
  }

  .rosary-flex-container::-webkit-scrollbar-thumb:hover {
    border-radius: 100vw;
    cursor: pointer;
    background: var(--glory-blue-400);
  }
}

@media screen and (min-height: 800px) {
  .q-quote-content {
    max-height: 25em;
  }
  .q-quote {
    height: 30em;
  }
  /* Context Section */
  .rosary-context-info-pane {
    height: 30em;
  }

  .rosary-context-info-full {
    font-size: 1em;
    height: 29.5em;
  }

  .rosary-context-info {
    font-size: 1em;
    height: 24em;
  }

  .rosary-context-btn-about {
    font-size: 1.1em;
  }

  .context-btn-quote-dec-ns {
    width: 1.25em;
    height: 1.25em;
  }

  .context-btn-quote-dec-s {
    transform: scale(1.2);
    width: 1.45em;
    height: 1.45em;
  }

  /* Rosary Graces */
  .section-title {
    font-size: 1.7em;
  }
}

@media screen and (min-width: 700px) {
  /* Rosary Graces */
  .rosary-graces {
    font-size: 18px;
  }
  .rosary-inspiration-card {
    height: 32em;
  }
  .rosary-flex-container {
    height: 36em;
  }

  /* Context Section */

  .rosary-context h1 {
    font-size: 2em;
  }
  .rosary-context-info-pane {
    height: 36em;
  }
  .rosary-context-info-full {
    font-size: 1.2em;
    height: 28em;
    /* text-shadow: 0 0 0.05em black;
  padding: 1em 0.5em;
  margin: 0;
  height: 28em; */
  }

  .rosary-context-info {
    font-size: 1.2em;
    height: 25em;
    /* text-shadow: 0 0 0.05em black;
  padding: 1em 0.5em;
  margin: 0;
  height: 23em; */
  }
  .rosary-context-visionary {
    font-size: 1.5em;
  }
  .rosary-context-visionary-loc {
    /* text-shadow: 0 0 0.05em black;
  letter-spacing: 0.1em; */
    font-size: 1em;
  }

  .rosary-context-btn-about {
    font-size: 1.4em;
  }

  .context-btn-quote-dec-ns {
    width: 1.5em;
    height: 1.5em;
  }

  .context-btn-quote-dec-s {
    transform: scale(1.2);
    width: 1.7em;
    height: 1.7em;
  }
}

@media screen and (min-width: 800px) {
  /* Rosary Graces */
  .rosary-graces {
    font-size: 19px;
  }
  /* Rosary Reparations */
  .reparations-container {
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: nowrap;
  }

  .rep-card {
    height: 24em;
  }
  .rep-back-card {
    grid-template-rows: 2.5em calc(24em - 3.3em);
  }
}

@media screen and (min-width: 900px) {
  /* Rosary Graces */
  .rosary-graces {
    font-size: 20px;
  }
  /* Rosary Reparations */
  .reparations-container {
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: nowrap;
  }
  .rep-card {
    height: 33em;
    width: 40vw;
  }
  .rep-back-card {
    grid-template-rows: 2.5em calc(33em - 3.3em);
  }
}
@media screen and (min-height: 910px) {
  .q-quote-content {
    max-height: 30em;
  }
  .q-quote {
    height: 32em;
  }
  .rosary-graces {
    font-size: 16px;
  }
}
@media screen and (min-height: 1000px) and (max-aspect-ratio: 8/10) {
  .rosary-graces {
    font-size: 19px;
  }

  /* Rosary Inspirations */

  .rosary-inspirations,
  .reparations-section,
  .rosary-context {
    font-size: 17px;
  }
}

@media screen and (min-width: 950px) {
  .rosary-context-subheader {
    font-size: 1em;
  }
  /* .section-title {
    font-size: 2.5rem;
  } */
}

@media screen and (min-width: 1000px) {
  /* Rosary Origins */

  /* .learn-btn {
    width: 45vw;
  } */

  .rosary-context {
    min-height: 50vh;
    padding-bottom: 4em;
  }

  .mb-screen-hidden {
    display: block;
  }
  .rosary-card {
    width: 40vw;
    height: 35vh;
    margin: 0 2em;
  }

  .prayer ul li {
    margin-left: 2em;
    font-size: 0.8em;
  }

  /* Rosary Graces */
  .rosary-graces {
    font-size: 21px;
  }
  /* Rosary Reparations */
  .reparations-section {
    font-size: 17px;
  }
  .section-title {
    font-size: 2em;
  }
}

@media screen and (min-width: 1000px) and (max-aspect-ratio: 12/14) {
  .rosary-page {
    margin-top: 6rem;
  }
}

@media screen and (min-width: 1000px) and (min-aspect-ratio: 1/1) {
  .q-quote {
    height: 28em;
  }
  .q-quote-verse,
  .q-quote-content {
    max-width: 34em;
    max-height: 24em;
  }

  .rosary-graces {
    font-size: 18px;
  }
  .queen-center {
    gap: 7em;
  }
}

@media screen and (min-width: 1200px) {
  /* Rosary Graces */
  /* .rosary-graces {
    font-size: 23px;
  } */
  .queen-quotes {
    width: 26em;
    display: grid;
    place-items: center;
  }
  .mb-screen-hidden-graces {
    display: block;
  }
  /* Rosary Inpsirations */
  .rosary-flex-container {
    padding-top: 2em;
    padding-bottom: 2em;
  }
  .rosary-flex-container {
    gap: 3em;
  }
  /* Rosary Reparations */
  .reparations-section {
    font-size: 18px;
  }
  /* Context Section */
  .rosary-context {
    min-height: 50vh;
    padding-bottom: 4em;
  }
  .context-bgscrn-pane1 {
    padding: 0 0.5em;
    scrollbar-width: thin;
    scrollbar-color: #141c5f transparent;
    scroll-behavior: smooth;
  }
  .context-bgscrn-pane2 {
    position: relative;
    padding: 0 0.5em;
  }
  .rosary-context-info-pane {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 34em;
    background: transparent;
  }
  .rosary-context-info-full {
    height: 25em;
  }
  .rosary-context-info {
    height: 22em;
  }
  .rosary-context-about-title {
    font-size: 1.5em;
    margin: 0.4em auto 0 auto;
  }
  .rosary-context-visionary {
    margin: 0.4em auto 0 auto;
  }

  .rosary-context-btn-container {
    position: absolute;
    bottom: -2.5em;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    width: 40vw;
    height: 2.5em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    place-items: center;
    transition: var(--transition);
    z-index: 500;
  }
}

@media screen and (min-width: 1250px) {
  /* Rosary Origins */
  .rosary-card {
    height: 27em;
    width: 43vw;
  }
  .rosary-card {
    width: 43vw;
  }

  .prayer ul li {
    margin-left: 4em;
    font-size: 1em;
  }
  .rosary-context h1 {
    margin-bottom: 0.5em;
  }
}

@media screen and (min-width: 1300px) {
  /* Rosary Graces */
  /* .queen-center {
    gap: 4em;
  } */
  /* Rosary Inspirations */
  .rosary-inspiration-card {
    max-width: 21em;
    flex-basis: 21em;
  }
  /* Rosary Inspirations */
  .rep-card {
    height: 32em;
    width: 38vw;
  }
  .rep-back-card {
    grid-template-rows: 2.5em calc(32em - 3em);
  }
  /* Rosary Graces */
  .q-quote-verse,
  .q-quote-content {
    max-width: 34em;
    /* max-height: 24em; */
  }
}
@media screen and (min-width: 1300px) and (min-aspect-ratio: 9/17) {
  .rosary-graces {
    font-size: 20px;
  }
  .queen-center {
    gap: 8em;
  }
}

@media screen and (min-width: 1400px) {
  /* Rosary Graces */
  /* .queen-center {
    gap: 8em;
  } */
  .rosary-context-subheader {
    font-size: 1.1em;
  }
  /* Rosary Inspirations */
  .rosary-inspirations {
    font-size: 18px;
  }
  /* Rosary Reparations */
  .rep-card {
    height: 30em;
    width: 35vw;
  }
  .rep-back-card {
    grid-template-rows: 2.5em calc(30em - 3em);
  }
  .reparations-container {
    justify-content: center;
    gap: 7vw;
  }
}

@media screen and (min-width: 1500px) {
  /* Rosary Inspirations */
  .rosary-flex-container {
    gap: 1em;
  }
  /* Originally in below Media scrn */
  .rosary-context {
    font-size: 20px;
  }

  .queen-center {
    gap: 12em;
  }
  .rosary-graces {
    font-size: 22px;
  }
  /* Rosary Context */
  .context-bgscrn-pane1,
  .context-bgscrn-pane2 {
    padding: 0 0.75em;
  }
}

@media screen and (min-width: 1900px) and (min-aspect-ratio: 17/10) {
  .rosary-graces {
    font-size: 25px;
  }

  .r-grace {
    border-radius: 0.8em;
  }
  /* Rosary Inspirations */
  .rosary-inspirations,
  .reparations-section,
  .rosary-context {
    font-size: 23px;
  }
  .rosary-context-subheader {
    font-size: 1.2em;
  }
  .queen-center {
    gap: 8em;
  }
  .q-quote-verse,
  .q-quote-content {
    max-width: 38em;
    /* max-height: 24em; */
  }
}

@media screen and (min-width: 2100px) {
  /* Rosary Graces */
  .rosary-graces {
    font-size: 29px;
  }

  .queen-center {
    gap: 8em;
  }
  .q-quote-verse,
  .q-quote-content {
    max-width: 38em;
    max-height: 28em;
  }
  .rosary-inspirations,
  .reparations-section,
  .rosary-context {
    font-size: 26px;
  }
  .rosary-context-subheader {
    font-size: 1.3em;
  }
  .rosary-page {
    font-size: 20px;
  }

  .rep-front-card > button {
    font-size: 0.7em;
  }
}
@media screen and (min-width: 2200px) and (min-aspect-ratio: 10/15) {
  .r-grace {
    border-radius: 0.8em;
  }
}

@media screen and (min-width: 2500px) {
  .rosary-page {
    margin-top: 7rem;
  }
  .rosary-card {
    width: 38vw;
  }
  .rosary-graces {
    font-size: 30px;
  }
  .queen-center {
    gap: 8em;
  }

  .rosary-inspirations,
  .reparations-section,
  .rosary-context {
    font-size: 27px;
  }
  .rosary-context-subheader {
    font-size: 1.4em;
  }
}

@media screen and (min-width: 2700px) {
  .queen-center {
    gap: 12em;
  }
}

@media screen and (min-width: 3000px) {
  .rosary-page {
    font-size: 27px;
    margin-top: 7rem;
  }

  .rosary-graces {
    font-size: 37px;
  }

  .rosary-inspirations,
  .reparations-section,
  .rosary-context {
    font-size: 33px;
  }
  .rosary-context-subheader {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 3800px) {
  .rosary-page {
    margin-top: 10rem;
    font-size: 33px;
  }
  .rosary-graces {
    font-size: 39px;
  }
  .rosary-inspirations,
  .reparations-section,
  .rosary-context {
    font-size: 35px;
  }
  .rosary-context-subheader {
    font-size: 1.6em;
  }
  .queen-center {
    gap: 15em;
  }
  .q-quote-verse,
  .q-quote-content {
    max-width: 40em;
  }
  /* .learn-btn {
    max-width: 1200px;
    max-height: 26em;
  } */
}
