.dm-mercy-page {
  padding-top: 4em;
}

.dm-channels-section {
  /* min-height: 95vh; */
  position: relative;
  height: auto;
  font-size: 16px;
  text-align: center;
  margin: 0 auto;
  background: radial-gradient(#d74444, #9a1313);
  padding: 2em 2em 1.5em 2em;
}
/* .dm-channels-section {
  width: var(--body-width);
  height: auto;
  
  
  font-size: 16px;
} */

.dm-quotes-section {
  /* min-height: 95vh; */
  height: auto;
  width: 100vw;
  margin: 0 auto;
  /* display: grid;
  place-items: center; */
  background: black;
  font-size: 16px;
}

.dm-memorial-section {
  /* min-height: 95vh; */
  height: auto;
  width: 100vw;
  font-size: 16px;
}

.dm-last-quote-section {
  /* min-height: 14rem; */
  height: auto;
  max-width: 100vw;
  width: 100%;
  background: black;
  font-size: 16px;
}

.section-title {
  font-size: 1.5em;
  text-align: center;
  letter-spacing: 0.05em;
}

.section-subtitle {
  margin: 0 auto;
  font-size: 0.9em;
  letter-spacing: var(--letterSpacing);
}
.information-icons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.information-icons svg {
  width: 1.2em;
  height: 1.2em;
}

/* Divine Mercy Channels*/

#blink-line-dm {
  color: black;
  opacity: 0;
  font-size: 1.2em;
  animation: blink 0.7s linear 0.1s infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.invisible-btn {
  background: none;
  border: none;
}

.dm-header-btn {
  display: inline-block;
  /* margin-bottom: 0.5em; */
  height: 1.2em;
}
.dm-channels-header {
  margin: 0 auto;
}

@keyframes glow {
  0% {
    color: white;
  }
  50% {
    color: var(--cross-gold-300);
  }
  100% {
    color: white;
  }
}

.dm-channels-subheader {
  margin: 0.5em auto;
  font-size: 0.9em;
  transition: var(--transition);
  animation: glow 7s ease-in-out infinite;
  cursor: pointer;
}

.dm-channels-grid-container {
  /* display: grid;
  justify-content: center;
  gap: 1em; */
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
}

/* card */
.dm-channel-card {
  height: 9em;
  width: 90vw;
  /* background: rgba(170, 22, 22, 0.9); */
  border-radius: var(--borderRadius);
  position: relative;
  /* cursor: pointer; */
  transition: var(--transition);
  background: rgba(171, 23, 23, 0.95);
  /* box-shadow: 0 0 10px 8px rgb(245, 229, 1); */
  /* animation: descendChannelGlow 5s ease-in-out 0s infinite; */
}
.dm-channels-grid-container article:nth-child(1) {
  animation: descendChannelGlow 5s linear 0s infinite;
}
.dm-channels-grid-container article:nth-child(2) {
  animation: descendChannelGlow 5s linear 0.8s infinite;
}
.dm-channels-grid-container article:nth-child(3) {
  animation: descendChannelGlow 5s linear 1.6s infinite;
}
.dm-channels-grid-container article:nth-child(4) {
  animation: descendChannelGlow 5s linear 2.2s infinite;
}
.dm-channels-grid-container article:nth-child(5) {
  animation: descendChannelGlow 5s linear 3s infinite;
}

@keyframes descendChannelGlow {
  0% {
    box-shadow: 0 0 10px 8px rgba(245, 229, 1, 0);
  }
  18% {
    box-shadow: 0 0 10px 8px rgba(245, 229, 1, 0.5);
  }
  26% {
    box-shadow: 0 0 10px 8px rgba(245, 229, 1, 0.9);
  }
  31.5% {
    box-shadow: 0 0 10px 8px rgba(245, 229, 1, 1);
  }
  37% {
    box-shadow: 0 0 10px 8px rgba(245, 229, 1, 0.9);
  }
  45% {
    box-shadow: 0 0 10px 8px rgba(245, 229, 1, 0.7);
  }
  65% {
    box-shadow: 0 0 10px 8px rgba(245, 229, 1, 0);
  }
}
/* 0 20 30 37.5 45 75 */

.channel-info {
  width: 86%;
  display: grid;
  place-items: center;
  margin: 0;
  color: var(--white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: var(--transition);
  /* change this back */
  opacity: 0;
  z-index: -1;
  animation: channelFadeIn 1.5s ease-in 0s forwards;
}

.link {
  color: var(--white);
  /* z-index: 100; */
  transition: var(--transition);
  animation: channelFadeIn 1.5s ease-in 0s forwards;
}
.link:hover {
  color: var(--glory-gold-100);
}
.dm-channel-arrows {
  position: absolute;
  top: 90%;
  left: 45%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  animation: channelFadeIn 1.5s ease-in 0s forwards;
}

.channel-overlay-title {
  font-size: 0.75em;
  margin: 0.1em 0;
  letter-spacing: 0.1em;
  animation: channelFadeIn 1.5s ease-in 0s forwards;
}

.channel-overlay-info {
  font-size: 0.57em;
  color: var(--white);
  font-family: var(--bodyFont);
  letter-spacing: 0.1em;
  animation: channelFadeIn 1.5s ease-in 0s forwards;
}

.channel-img {
  height: 100%;
  width: 100%;
  transition: var(--transition);
  border-radius: var(--borderRadius);
  object-fit: cover;
  opacity: 0.1;
}

.Jesus-img {
  object-position: top;
  opacity: 0.4;
}

.sunday-img {
  opacity: 0.4;
}

/* .dm-spec-card::before {
  outline: 4px solid var(--glory-gold-400);
} */

.hour-img {
  object-position: bottom;
}

/* .dm-channel-card::before {
  content: "";
  position: absolute;
  top: -0.5px;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--borderRadius);
  transition: var(--transition);
  background: rgba(171, 23, 23, 0.8);
  animation: channelFadeIn 1.5s ease-in 0s forwards;
} */

@keyframes channelFadeIn {
  0% {
    opacity: 0;
    z-index: 1;
  }
  100% {
    opacity: 1;
    z-index: 100;
  }
}

/* hover*/

/* .dm-channel-card:hover .channel-info,
.dm-channel-card:hover .pulse-circle {
  opacity: 1;
  z-index: 100;
} */

.dm-channel-card:hover .channel-img {
  opacity: 0;
}

.dm-channel-card:hover {
  background: rgb(116, 30, 30);
}

/* .dm-channel-card:hover::after {
  opacity: 1;
  
} */

.pulse-circle {
  animation: pulsesFadeIn 2s ease-in 0s forwards;
}

.pulse-circle span {
  content: "";
  position: absolute;
  height: 0.4em;
  width: 0.4em;
  border-radius: 50%;
  /* border: 0.5px solid var(--black); */
}

@keyframes pulsesFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.leftgroup {
  /* opacity: 0; */
  bottom: 1.85em;
  left: 1.9em;
  background: red;
  z-index: 100;
}
.rightgroup {
  /* opacity: 0; */
  bottom: 1.85em;
  right: 1.9em;
  background: white;
  z-index: 100;
}
.c1 {
  animation: first-lane-l 3s linear 0.2s infinite;
}
.c2 {
  animation: second-lane-l 3s linear 0.8s infinite;
}
.c3 {
  animation: third-lane-l 3s linear 1.8s infinite;
}
.c4 {
  animation: fourth-lane-l 3s linear 1.2s infinite;
}
.c5 {
  animation: fifth-lane-l 3s linear 0.4s infinite;
}
.c6 {
  animation: first-lane-l 3s linear 1s infinite;
}
.c7 {
  animation: second-lane-l 3s linear 1.4s infinite;
}
.c8 {
  animation: third-lane-l 3s linear 2.5s infinite;
}
.c9 {
  animation: fourth-lane-l 3s linear 2s infinite;
}
.c10 {
  animation: fifth-lane-l 3s linear 1.2s infinite;
}

.c11 {
  animation: first-lane-r 3s linear 0.2s infinite;
}
.c12 {
  animation: second-lane-r 3s linear 0.8s infinite;
}
.c13 {
  animation: third-lane-r 3s linear 1.8s infinite;
}
.c14 {
  animation: fourth-lane-r 3s linear 1.2s infinite;
}
.c15 {
  animation: fifth-lane-r 3s linear 0.4s infinite;
}
.c16 {
  animation: first-lane-r 3s linear 1s infinite;
}
.c17 {
  animation: second-lane-r 3s linear 1.4s infinite;
}
.c18 {
  animation: third-lane-r 3s linear 2.5s infinite;
}
.c19 {
  animation: fourth-lane-r 3s linear 2s infinite;
}
.cr0 {
  animation: fifth-lane-r 3s linear 1.2s infinite;
}

@keyframes first-lane-l {
  to {
    bottom: 1.7em;
    left: 0;
  }
}
@keyframes second-lane-l {
  to {
    bottom: 1em;
    left: 0;
  }
}
@keyframes third-lane-l {
  to {
    bottom: 1.7em;
    left: 0.2em;
  }
}
@keyframes fourth-lane-l {
  to {
    bottom: 0;
    left: 1em;
  }
}
@keyframes fifth-lane-l {
  to {
    bottom: 0;
    left: 1.7em;
  }
}
@keyframes first-lane-r {
  to {
    bottom: 1.7em;
    right: 0;
  }
}
@keyframes second-lane-r {
  to {
    bottom: 1em;
    right: 0;
  }
}
@keyframes third-lane-r {
  to {
    bottom: 0.2em;
    right: 0.2em;
  }
}
@keyframes fourth-lane-r {
  to {
    bottom: 0;
    right: 1em;
  }
}
@keyframes fifth-lane-r {
  to {
    bottom: 0;
    right: 1.7em;
  }
}

/* Divine Mercy Quotes */

.dm-heading {
  position: relative;
  margin-bottom: 0;
  display: grid;
  place-items: center;
  padding: 0.5em 0;
}
.dm-heading h2 {
  margin: 0 auto;
}
.dm-heading p {
  color: var(--white);
  text-shadow: 0 0 15px var(--glory-gold-200);
  margin-bottom: 0.3em;
}

/* Question Mark Icon*/
.information-icon {
  background: none;
  border: none;
}
.information-icon svg {
  margin: 0 auto;
  cursor: pointer;
  width: 1.2em;
  height: 1.2em;
}
.icon-bounce svg {
  animation: bounce 2s linear infinite;
}
@keyframes bounce {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.information-msg-dm {
  position: absolute;
  top: 0.8em;
  height: auto;
  width: 16em;
  background: black;
  font-family: var(--bodyFont);
  border: 1px white solid;
  border-radius: var(--borderRadius);
  z-index: 100;
  display: grid;
  place-items: center;
}
.information-msg-dm p {
  font-size: 0.7em;
  font-style: italic;
  color: var(--white);
  padding: 1em 1em 0 1em;
  text-align: left;
  margin: 0;
}
.information-msg-dm i {
  font-size: 0.7em;
}
.information-icons svg {
  width: 0.8em;
  height: 0.8em;
}

.dm-quotes-center {
  height: auto;
  margin: 0 auto;
  /* overflow: hidden; */
  /* width: 90vw; */
  width: 100vw;
  display: grid;
  grid-template-columns: auto;
  place-items: center;
  /* max-width: 1400px; */
}
.dm-quote-circles {
  width: 100%;
  margin: 1.2em auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.55em;
  position: relative;
  overflow-y: visible;
}

/* individual button css */
.circle-type {
  height: 4.85em;
  width: 4.85em;
  border-radius: 50%;
  cursor: pointer;
  word-break: break-all;
}

.circle-type p {
  position: absolute;
  font-size: 0.7em;
}

.qc-channel {
  background: transparent;
  color: var(--bright-red);
  text-shadow: 0 0 10px var(--bright-red);
  /* box-shadow: 0 0 8px 5px currentColor, inset 0 0 8px 5px currentColor; */
  /* border: 3px solid var(--bright-red); */
  border: 1px solid var(--bright-red);
}

.qc-trust {
  background: black;
  color: var(--white);
  text-shadow: 0 0 10px var(--white);
  /* box-shadow: 0 0 8px 5px currentColor, inset 0 0 8px 5px currentColor; */
  /* border: 3px solid var(--white); */
  border: 1px solid var(--white);
}

.qc-selected {
  /* background: transparent; */
  background: black;
  color: var(--cross-gold-300);
  text-shadow: 0 0 10px var(--cross-gold-300);
  /* box-shadow: 0 0 8px 5px currentColor, inset 0 0 8px 5px currentColor; */
  border: 1px solid var(--cross-gold-300);
}

.qc-all-selected {
  animation: breatheAlreadySelected 3s 1.5s infinite;
}

.qc-channel-animation {
  animation: breatheChannel 3s infinite;
}

.qc-trust-animation {
  animation: breatheTrust 3s infinite;
}

.qc-channel:hover {
  color: var(--white);
  background: var(--cross-gold-300);
  border: 1px solid var(--white);
  box-shadow: 0 0 12px 7px currentColor, inset 0 0 12px 7px currentColor;
}

.qc-trust:hover {
  color: var(--white);
  background: var(--glory-blue-200);
  box-shadow: 0 0 12px 7px currentColor, inset 0 0 12px 7px currentColor;
}

.qc-selected:hover {
  color: var(--white);
  background: var(--cross-gold-300);
  box-shadow: 0 0 12px 7px var(--cross-gold-300), inset 0 0 12px 7px var(--cross-gold-300);
}

@keyframes breatheTrust {
  0% {
    background: black;
  }
  50% {
    color: var(--white);
    background: var(--glory-blue-200);
    box-shadow: 0 0 12px 7px currentColor, inset 0 0 12px 7px currentColor;
  }
  100% {
    background: black;
  }
}

@keyframes breatheChannel {
  0% {
    background: black;
  }
  50% {
    color: var(--white);
    background: var(--cross-gold-300);
    border: 1px solid var(--white);
    box-shadow: 0 0 12px 7px currentColor, inset 0 0 12px 7px currentColor;
  }
  100% {
    background: black;
  }
}

@keyframes breatheAlreadySelected {
  0% {
    background: black;
  }
  50% {
    color: var(--white);
    background: var(--cross-gold-300);
    border: 1px solid var(--cross-gold-300);
    box-shadow: 0 0 12px 7px currentColor, inset 0 0 12px 7px currentColor;
  }
  100% {
    background: black;
  }
}

.dm-circle-qte-box {
  width: 100%;
  display: grid;
  place-items: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.quote-circle {
  /* display: grid;
  place-items: center; */
  transition: var(--transition-lights);
  position: relative;
}

/* Card css */
.dm-quote-card {
  min-width: 15em;
  max-width: 33em;
  min-height: 15em;
  max-height: 45em;
  height: auto;
  width: 20em;
  padding: 1em;
  border-radius: var(--borderRadius);
  background: black;
  position: relative;
  /* order | layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 12px 5px var(--cross-gold-300);
  /* positioning */
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* transition */
  transition: var(--transition);
  /* animation: borderRadiateGold 1.8s ease-out infinite; */
}

/* Numbers of the cards CSS */
.card-arrow {
  position: absolute;
  left: 0.5rem;
  top: 0.25rem;
  display: block;
  /* background: var(--cross-gold-300);
  color: black; */
  background: white;
  color: var(--cross-gold-300);
  box-shadow: inset 0 0 1px 5px var(--cross-gold-300);
  border: 2px solid var(--cross-gold-300);
  /* border-top: 4px solid var(--cross-gold-300); */
  border-radius: 50%;
  font-size: 2em;
  cursor: pointer;
}

.holyTrinity-icon {
  position: absolute;
  right: 0.5em;
  top: 0.25em;
  display: block;
  color: var(--white);
  border: none;
  width: 2.2em;
  height: 2.2em;
}

.holyTrinity-icon svg {
  color: var(--white);
}

.quote-title p {
  margin: 0;
  letter-spacing: 0.1em;
  font-size: 1.1em;
  color: white;
}

.quote {
  overflow-y: hidden;
}
.quote p {
  letter-spacing: var(--letterSpacing);
  /* text-shadow: 0 0 0.03em black; */
}

.card-font {
  font-size: 0.75em;
  color: white;
}

.sm-font {
  font-size: 0.68em;
  color: white;
}

.bold-text {
  font-weight: bold;
  color: var(--cross-gold-300);
  text-shadow: 0 0 0.03em var(--cross-gold-300);
}

.quote-description {
  display: grid;
  grid-template-columns: 3fr 2fr 3fr;
  grid-template-rows: 3em;
  place-items: center;
}
.quote-description svg {
  height: 4em;
  width: 4em;
}
.quote-description-element p {
  margin: 0.75em 1em;
  letter-spacing: 0.1em;
}

.dm-date {
  text-align: center;
}

@keyframes borderRadiateGold {
  0% {
    box-shadow: 0 0 1px 1px var(--cross-gold-300);
  }
  25% {
    box-shadow: 0 0 3px 3px var(--cross-gold-200);
  }
  50% {
    box-shadow: 0 0 7px 5px var(--cross-gold-100);
  }
  75% {
    box-shadow: 0 0 3px 3px var(--cross-gold-200);
  }
  100% {
    box-shadow: 0 0 1px 1px var(--cross-gold-300);
  }
}

/* Divine Mercy Memorial */

.dm-mercy-memorial {
  margin: 1em auto;
  text-align: center;
}
.attribute-container {
  position: relative;
  margin: 0 auto;
  padding-top: 1em;
}

.bible_link_box {
  position: absolute;
  top: -0.5em;
  right: 50%;
  transform: translate(50%, -50%);
  color: var(--cross-gold-400);
  font-size: 1em;
  cursor: pointer;
}

.bible_link_box i,
.bible_link_box svg {
  animation: bounce 2s ease-in-out infinite;
}

/* Card that contains St. Faustina */
.card {
  width: 80vw;
  height: 50vh;
  position: relative;
  margin: 0 auto;
  border-radius: var(--borderRadius);
  perspective: 1500px;
}

.card:hover .card-side {
  box-shadow: 0 15px 20px -5px rgb(219, 190, 73), 0 8px 10px -6px rgb(219, 190, 73);
}

.card-front {
  border-radius: var(--borderRadius);
}

.obituary-memorial-img {
  width: 80vw;
  height: 50vh;
  object-fit: cover;
  object-position: top;
  opacity: 1;
  border-radius: var(--borderRadius);
}

/* Obituary text */
.card-back {
  transform: rotateY(180deg);
  background: var(--grey-50);
}

.card-side {
  transition: all 2s linear;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: var(--shadow-4);
  cursor: pointer;
}
.card-front-text {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.card-front-text h5,
h6 {
  color: var(--white);
  margin: 0.3em 0;
}
.card:hover .card-front {
  transform: rotateY(-180deg);
}

.card:hover .card-back {
  transform: rotateY(0);
}

.card-back-text {
  backface-visibility: hidden;
  width: 70vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  place-items: center;
}

.card-back-title {
  backface-visibility: hidden;
  color: var(--cross-gold-300);
  font-family: var(--bodyFont);
  text-transform: var(--letterSpacing);
  font-size: 0.9em;
  margin-bottom: 0.5em;
}

.obituary-body-text {
  color: var(--black);
  font-family: var(--bodyFont);
  font-size: 0.75em;
  margin: 0.5em 0;
}

/* quotes about St. faustina from her diary */

.memorial-quotes-container {
  margin: 0.5em auto;
  /* dynamic */
  /* height: 75vh; */
  height: calc(var(--vh, 1vh) * 75);
  width: 80vw;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0.5em;
}

.memorial-quote {
  padding: 0.5em;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  flex-grow: 1;
  flex-basis: 4em;
  margin: 0.25em auto;
  display: grid;
  grid-template-columns: 75vw;
  grid-template-rows: minmax(2em, auto) auto auto;
  place-items: center;
  background-color: var(--white);
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow-4);
  transition: var(--transition);
}

@keyframes bounce-icons {
  0% {
    font-size: 1em;
  }
  50% {
    font-size: 1.4em;
  }
  100% {
    font-size: 1em;
  }
}
.memorial-quote-caret-icon {
  position: absolute;
  top: 1em;
  left: 2em;
  transform: translate(-50%, -50%);
  font-size: 1em;
  cursor: pointer;
  /* animation: bounce-icons 3s linear infinite; */
}

.memorial-quote h5 {
  margin: 0.15em 0;
  font-size: 0.8em;
  color: var(--cross-gold-300);
}
.memorial-body-text {
  margin: 0.15em auto;
  font-size: 0.72em;
  transition: var(--transition);
  color: var(--black);
  font-family: var(--bodyFont);
  padding: 0.4em;
  text-align: left;
}

.bold {
  font-weight: bold;
  color: var(--cross-gold-300);
}

.memorial-quote:hover {
  background: var(--cross-gold-300);
}

.memorial-quote:hover .bold {
  color: var(--white);
}
.memorial-quote:hover > * {
  color: white;
}
.memorial-quote:hover .memorial-quote-description p {
  color: white;
}
.memorial-quote-description {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

/* Divine Mercy Last Q */
.dm-last-quote {
  font-size: 16px;
  height: 22em;
  width: 90vw;
  margin: 2em auto 0 auto;
  padding: 1em;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  place-items: center;
  transition: var(--transition);
}

.dm-last-quote h3 {
  margin-bottom: 0;
}

@keyframes fade-inout {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.dm-last-quote h3 {
  font-size: 1em;
  transition: var(--transition);
  animation: fade-inout 24s linear infinite;
  letter-spacing: 0.1em;
}
.dm-last-quote h1 {
  text-transform: none;
  font-family: var(--bodyFont);
  transition: var(--transition);
  animation: fade-inout 24s linear infinite;
  letter-spacing: 0.1em;
}
.last-quote-small-paragraph {
  font-size: 1.1em;
  line-height: 1.3em;
}
.last-quote-big-paragraph {
  font-size: 0.9em;
  line-height: 1.25em;
}

/*  Media Queries */
/* .obituary-body-text {
    font-size: 1em;
  }
  .memorial-body-text {
    font-size: 0.85em;
  } */
@media screen and (min-width: 370px) {
  .dm-quote-circles {
    gap: 0.65em;
  }
}

@media screen and (min-width: 400px) {
  /* Divine Mercy Channels */
  .dm-channel-arrows {
    top: 100%;
    left: 45%;
    font-size: 12px;
  }
  /* Divine Mercy Memorial */
  .card-back-title {
    font-size: 1.1em;
  }

  .obituary-body-text {
    font-size: 0.9em;
  }
  .obituary-header {
    margin: 1em auto;
  }
  .memorial-quote h5 {
    font-size: 0.9em;
  }
}

@media screen and (min-width: 500px) {
  /* Divine Mercy Quotes */
  .circle-type {
    width: 5.4em;
    height: 5.4em;
  }
  .circle-type p {
    font-size: 0.9em;
  }
  .dm-quotes-center {
    width: 95vw;
  }
  .dm-quote-circles {
    gap: 0.85em;
  }
}

@media screen and (min-width: 600px) and (max-height: 450px) {
  /* Divine Mercy Channels */
  .dm-channels-section {
    font-size: 17px;
  }
  .channel-overlay-info {
    font-size: 0.7em;
  }
}

@media screen and (min-width: 600px) and (min-height: 600px) {
  /* Divine Mercy Channels */
  .dm-channels-grid-container {
    /* grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr); */
    gap: 1.5em;
    /* margin: 1.5em 7em; */
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .channel-info {
    top: 50%;
  }
  .dm-channel-card {
    height: 14em;
    width: 18em;
  }
  .dm-channels-grid-container article:nth-child(1) {
    animation: descendChannelGlow 5s ease-in-out 0s infinite;
  }
  .dm-channels-grid-container article:nth-child(2) {
    animation: descendChannelGlow 5s ease-in-out 0s infinite;
  }
  .dm-channels-grid-container article:nth-child(3) {
    animation: descendChannelGlow 5s ease-in-out 0s infinite;
  }
  .dm-channels-grid-container article:nth-child(4) {
    animation: descendChannelGlow 5s ease-in-out 0s infinite;
  }
  .dm-channels-grid-container article:nth-child(5) {
    animation: descendChannelGlow 5s ease-in-out 0s infinite;
  }

  @keyframes descendChannelGlow {
    0% {
      box-shadow: 0 0 10px 8px rgba(245, 229, 1, 0);
    }
    50% {
      box-shadow: 0 0 10px 8px rgba(245, 229, 1, 1);
    }
    100% {
      box-shadow: 0 0 10px 8px rgba(245, 229, 1, 0);
    }
  }
}

@media screen and (min-width: 600px) {
  /* Divine Mercy Quotes*/
  /* .dm-heading {
    margin: 1.5em 0 0 0;
  } */

  .dm-quote-card {
    width: 22em;
  }

  .dm-quotes-section {
    font-size: 17px;
  }
  .dm-quotes-center {
    width: 90vw;
  }
  .circle-type {
    width: 5.7em;
    height: 5.7em;
  }
  .circle-type p {
    font-size: 1em;
  }
  /* Divine Mercy Memorial */
  .card-back-title {
    font-size: 1.25em;
  }

  .memorial-quote h5 {
    font-size: 1em;
  }

  /* DM Last Quote */
  .dm-last-quote {
    font-size: 18px;
  }
  .dm-last-quote h1 {
    line-height: 1.4em;
  }
}

@media screen and (min-width: 700px) {
  /* Divine Mercy Quotes*/
  .dm-quotes-section {
    font-size: 18px;
  }
  .circle-type {
    height: 6em;
    width: 6em;
  }
  .dm-quote-circles {
    gap: 1em;
  }
  .letter-circle-spacing {
    letter-spacing: 0.1em;
  }
  .memorial-quotes-container {
    height: calc(var(--vh, 1vh) * 50);
  }
  .obituary-body-text {
    font-size: 1em;
  }
  .memorial-body-text {
    font-size: 0.85em;
  }
}

@media screen and (min-width: 750px) {
  .qc-channel {
    border: 1px solid var(--bright-red);
  }

  .qc-trust {
    border: 1px solid var(--white);
  }
}

@media screen and (min-width: 800px) {
  /* Divine Mercy Channels */
  .img-overlay-title {
    font-size: 0.85em;
  }

  .img-overlay-info {
    font-size: 0.6em;
  }
  .dm-channels-section {
    font-size: 18px;
  }
  .dm-channels-grid-container {
    gap: 2em;
  }

  /* Divine Mercy Quotes*/
  .dm-quotes-section {
    font-size: 19px;
  }
  .circle-type {
    height: 6em;
    width: 6em;
  }
  /* Divine Mercy Memorial */
  .card-back-title {
    font-size: 1.4em;
  }

  .memorial-quote h5 {
    font-size: 1.2em;
  }

  /* .memorial-quote-caret-icon {
    font-size: 1.4em;
    top: 1.3em;
  } */
  .card-front-text h5,
  h6 {
    font-size: 2em;
  }
  /* DM Last Quote */
  .dm-last-quote {
    font-size: 20px;
    height: 20em;
  }
  .last-quote-big-paragraph {
    font-size: 1em;
    line-height: 1.35em;
  }
}

@media screen and (min-width: 900px) {
  /* DM Last Quote */
  .dm-last-quote {
    font-size: 21px;
    width: 85vw;
  }
}

@media screen and (min-width: 950px) {
  .section-title {
    font-size: 2.5em;
    text-align: center;
  }

  .section-subtitle {
    font-size: 1.2em;
  }
  /* Divine Mercy Channels */
  .dm-channels-header {
    font-size: 2.5em;
  }
  .dm-channels-subheader {
    font-size: 1em;
  }
  .dm-channels-grid-container {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  /* Divine Mercy Quotes*/
  .circle-type {
    height: 7em;
    width: 7em;
  }
  .dm-quotes-section {
    font-size: 20px;
  }
  .circle-type {
    letter-spacing: 0.1em;
  }
}

@media screen and (min-width: 1000px) and (max-aspect-ratio: 12/14) {
  .dm-channels-section {
    padding-top: 3em;
  }
}
@media screen and (min-width: 1000px) {
  /* Divine Mercy Channels */
  .dm-channels-section {
    font-size: 19px;
  }
  /* DM Last Quote */
  .dm-last-quote {
    font-size: 22px;
    height: 23em;
  }
  .dm-last-quote h3 {
    font-size: 1.2em;
  }
  .last-quote-small-paragraph {
    font-size: 1.4em;
    line-height: 1.5em;
  }
  .last-quote-big-paragraph {
    font-size: 1.1em;
    line-height: 1.3em;
  }
  .obituary-body-text {
    font-size: 1.3em;
  }
  .memorial-body-text {
    font-size: 1.2em;
  }
}
@media screen and (min-width: 1100px) {
  /* Divine Mercy Quotes*/
  .dm-quotes-center {
    width: 85vw;
  }
  /* .circle-type {
    height: 8em;
    width: 8em;
  } */

  .dm-quote-circles {
    gap: 1.5em;
  }

  /* Divine Mercy Memorial */
  .attribute-container {
    max-width: 90vw;
    display: grid;
    grid-template-columns: 45vw 45vw;
    grid-template-rows: 70vh;
    /* overflow-x: hidden; */
  }
  .memorial-quotes-container {
    height: 100%;
    width: 90%;
    justify-content: center;
  }
  .card-back-text {
    width: 40vw;
  }
  .card {
    height: 100%;
    width: 100%;
  }
  .obituary-memorial-img {
    height: 100%;
    width: 100%;
  }
  .memorial-quote {
    grid-template-columns: 100%;
  }
  .memorial-quote h5 {
    font-size: 1.3em;
  }
  .memorial-body-text {
    font-size: 0.7em;
  }
  /* .memorial-quote-caret-icon {
    font-size: 1.5em;
    top: 1.3em;
  } */

  /* DM Last Quote */
  .dm-last-quote {
    font-size: 23px;
    width: 80vw;
  }
}
@media screen and (min-width: 1200px) {
  .qc-channel:hover,
  .qc-trust:hover {
    animation: none;
  }
  .leftgroup {
    bottom: 2em;
    left: 2.1em;
    background: red;
  }
  .rightgroup {
    bottom: 2em;
    right: 2.1em;
    background: white;
  }

  .c1 {
    animation: first-lane-l 3s linear 0.2s infinite;
  }
  .c2 {
    animation: second-lane-l 3s linear 0.8s infinite;
  }
  .c3 {
    animation: third-lane-l 3s linear 1.8s infinite;
  }
  .c4 {
    animation: fourth-lane-l 3s linear 1.2s infinite;
  }
  .c5 {
    animation: fifth-lane-l 3s linear 0.4s infinite;
  }
  .c6 {
    animation: first-lane-l 3s linear 1s infinite;
  }
  .c7 {
    animation: second-lane-l 3s linear 1.4s infinite;
  }
  .c8 {
    animation: third-lane-l 3s linear 2.5s infinite;
  }
  .c9 {
    animation: fourth-lane-l 3s linear 2s infinite;
  }
  .c10 {
    animation: fifth-lane-l 3s linear 1.2s infinite;
  }

  .c11 {
    animation: first-lane-r 3s linear 0.2s infinite;
  }
  .c12 {
    animation: second-lane-r 3s linear 0.8s infinite;
  }
  .c13 {
    animation: third-lane-r 3s linear 1.8s infinite;
  }
  .c14 {
    animation: fourth-lane-r 3s linear 1.2s infinite;
  }
  .c15 {
    animation: fifth-lane-r 3s linear 0.4s infinite;
  }
  .c16 {
    animation: first-lane-r 3s linear 1s infinite;
  }
  .c17 {
    animation: second-lane-r 3s linear 1.4s infinite;
  }
  .c18 {
    animation: third-lane-r 3s linear 2.5s infinite;
  }
  .c19 {
    animation: fourth-lane-r 3s linear 2s infinite;
  }
  .cr0 {
    animation: fifth-lane-r 3s linear 1.2s infinite;
  }

  @keyframes first-lane-l {
    to {
      bottom: 2em;
      left: 0;
    }
  }
  @keyframes second-lane-l {
    to {
      bottom: 1em;
      left: 0;
    }
  }
  @keyframes third-lane-l {
    to {
      bottom: 0.2em;
      left: 0.2em;
    }
  }
  @keyframes fourth-lane-l {
    to {
      bottom: 0;
      left: 1em;
    }
  }
  @keyframes fifth-lane-l {
    to {
      bottom: 0;
      left: 2em;
    }
  }
  @keyframes first-lane-r {
    to {
      bottom: 2em;
      right: 0;
    }
  }
  @keyframes second-lane-r {
    to {
      bottom: 1em;
      right: 0;
    }
  }
  @keyframes third-lane-r {
    to {
      bottom: 0.2em;
      right: 0.2em;
    }
  }
  @keyframes fourth-lane-r {
    to {
      bottom: 0;
      right: 1em;
    }
  }
  @keyframes fifth-lane-r {
    to {
      bottom: 0;
      right: 2em;
    }
  }
}

@media screen and (min-width: 1260px) {
  /* Divine Mercy Channels */
  .img-overlay-title {
    font-size: 1em;
  }

  .img-overlay-info {
    font-size: 0.8em;
  }
  .dm-channels-grid-container {
    gap: 2.5em;
  }
  .dm-channels-section {
    font-size: 18px;
  }
  .dm-channel-card {
    width: 17em;
  }
  .dm-quotes-section {
    font-size: 16px;
  }
}
@media screen and (min-width: 1300px) {
  /* DM Last Quote */
  .dm-last-quote {
    font-size: 24px;
  }
}

@media screen and (min-width: 1350px) {
  /* Divine Mercy Quotes*/
  .dm-quotes-center {
    width: 85vw;
  }
  /* .circle-type {
    height: 9em;
    width: 9em;
  } */

  .dm-quote-card {
    max-width: 40em;
    max-height: 50em;
  }

  .dm-quotes-section {
    font-size: 22px;
  }
  .circle-type {
    letter-spacing: 0.2em;
  }
  /* Divine Mercy Memorial */
  .card-back-title {
    font-size: 1.7em;
  }

  .memorial-quote h5 {
    font-size: 1.5em;
  }
  .obituary-body-text {
    font-size: 1em;
  }
  .memorial-body-text {
    font-size: 0.85em;
  }
  /* .memorial-quote-caret-icon {
    font-size: 1.6em;
    top: 1.3em;
  } */
}

@media screen and (min-width: 1400px) {
  /* Divine Mercy Channels */
  .dm-channels-section {
    font-size: 21px;
  }
  .dm-channels-subheader {
    font-size: 1.1em;
  }

  /* DM Last Quote */
  .last-quote-small-paragraph {
    font-size: 1.5em;
    line-height: 1.6em;
  }
  .last-quote-big-paragraph {
    font-size: 1.2em;
    line-height: 1.35em;
  }
  .dm-last-quote h3 {
    font-size: 1.2em;
  }
}

@media screen and (min-width: 1500px) {
  /* Divine Mercy Channels */
  .dm-channels-section {
    font-size: 22px;
  }
  .dm-channel-card {
    height: 15em;
    width: 18em;
  }
  .dm-memorial-section {
    font-size: 21px;
  }
  /* .leftgroup {
    bottom: 3em;
  }
  .rightgroup {
    bottom: 3em;
  } */

  .channel-overlay-info {
    font-size: 0.65em;
    letter-spacing: 0.1em;
  }
  /* DM Last Quote */
  .dm-last-quote h3 {
    font-size: 1.3em;
    line-height: 1.3em;
  }

  .circle-type {
    width: 8em;
    height: 8em;
  }

  .bible_link_box {
    font-size: 1em;
  }
}

@media screen and (min-width: 1900px) {
  .dm-channels-section {
    font-size: 24px;
  }
  .attribute-container {
    padding-top: 1.2em;
  }

  .circle-type {
    width: 9em;
    height: 9em;
  }
  .obituary-body-text {
    font-size: 1.3em;
  }
  .memorial-body-text {
    font-size: 1.1em;
  }

  /* .last-quote-big-paragraph{
    font-size: 1.2em;
  } */
  .bible_link_box {
    top: -0.4em;
  }
}

@media screen and (min-width: 2200px) {
  .dm-channels-section {
    font-size: 30px;
  }
  .dm-channels-subheader {
    font-size: 1.4em;
  }
  .dm-quotes-section {
    font-size: 27px;
  }
  .dm-memorial-section {
    font-size: 16px;
  }
  .dm-memorial-section .section-title {
    font-size: 3.7em;
    text-align: center;
  }
  .qc-channel {
    border: 2px solid var(--bright-red);
  }

  .qc-trust {
    border: 2px solid var(--white);
  }
  .dm-last-quote {
    font-size: 28px;
  }
  .circle-type {
    width: 12em;
    height: 12em;
  }
  .circle-type p {
    font-size: 1.1em;
  }
  /* .last-quote-big-paragraph{
    font-size: 1.2em;
  } */

  .obituary-body-text {
    font-size: 1.8em;
  }
  .memorial-body-text {
    font-size: 1.6em;
  }
  .information-icon svg {
    height: 1.7em;
    width: 1.7em;
  }
  .information-icons svg {
    width: 0.9em;
    height: 0.9em;
  }

  .card-front-text h6 {
    font-size: 1.5em;
  }

  .attribute-container {
    padding-top: 1.5em;
  }

  .bible_link_box {
    font-size: 1.3em;
  }
}

@media screen and (min-width: 2500px) {
  .dm-channels-section {
    font-size: 31px;
  }
  .dm-channels-subheader {
    font-size: 1.5em;
  }
  .dm-quotes-section {
    font-size: 29px;
  }
  .dm-quotes-center {
    width: 80vw;
  }
  .card-front-text h5 {
    font-size: 2.4em;
  }
  .card-front-text h6 {
    font-size: 2em;
  }
  .dm-last-quote {
    font-size: 30px;
  }
  .bible_link_box {
    font-size: 1.4em;
  }
}

@media screen and (min-width: 2700px) {
  .dm-channels-section {
    font-size: 33px;
  }
  .dm-channels-subheader {
    font-size: 1.7em;
  }
  .dm-quotes-section {
    font-size: 29px;
  }
  .dm-quotes-center {
    width: 75vw;
  }
  .circle-type {
    width: 14em;
    height: 14em;
  }
  .circle-type p {
    font-size: 1.4em;
  }
  .card-font {
    font-size: 0.85em;
  }
  .dm-quote-card {
    width: 23em;
  }
  .quote-description svg {
    width: 5em;
    height: 5em;
  }
  .information-icon svg {
    height: 1.8em;
    width: 1.8em;
  }
  .information-icons svg {
    width: 1em;
    height: 1em;
  }

  .dm-memorial-section {
    font-size: 21px;
  }
  .bible_link_box {
    top: -0.3em;
  }
  .dm-last-quote {
    font-size: 38px;
  }
}

@media screen and (min-width: 3200px) {
  .dm-channels-section {
    font-size: 39px;
  }

  .dm-quotes-section {
    font-size: 29px;
  }
  .dm-quotes-center {
    width: 75vw;
  }
  .circle-type {
    width: 16em;
    height: 16em;
  }
  .circle-type p {
    font-size: 1.4em;
  }
  .dm-quote-card {
    width: 25em;
  }
  .information-msg-dm {
    width: 18em;
  }
  .information-msg-dm p {
    font-size: 0.9em;
  }
  .dm-last-quote {
    font-size: 40px;
  }
}

@media screen and (min-width: 3800px) {
  .dm-channels-section {
    font-size: 46px;
  }
  .dm-channels-subheader {
    font-size: 1.8em;
  }
  .dm-quotes-section {
    font-size: 32px;
  }
  .circle-type {
    width: 19em;
    height: 19em;
  }
  .circle-type p {
    font-size: 1.7em;
  }
  .card-font {
    font-size: 1em;
  }
  .dm-quote-card {
    width: 28em;
  }
  .dm-memorial-section {
    font-size: 25px;
  }
  .information-msg-dm {
    width: 22em;
  }
  .dm-last-quote {
    font-size: 47px;
  }
}
