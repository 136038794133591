/* flame of love */
#flameofloveheart-flower {
  animation: flameofloveheart-flower_f_p 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-flower_f_p {
  0% {
    fill: #790e0e;
  }
  20% {
    fill: #ccc7c7;
  }
  50% {
    fill: #ccc7c7;
  }
  70% {
    fill: #790e0e;
  }
  100% {
    fill: #790e0e;
  }
}
#flameofloveheart-flower2 {
  animation: flameofloveheart-flower2_f_p 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-flower2_f_p {
  0% {
    fill: #790e0e;
  }
  20% {
    fill: #ccc7c7;
  }
  50% {
    fill: #ccc7c7;
  }
  70% {
    fill: #790e0e;
  }
  100% {
    fill: #790e0e;
  }
}
#flameofloveheart-flower3 {
  animation: flameofloveheart-flower3_f_p 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-flower3_f_p {
  0% {
    fill: #790e0e;
  }
  20% {
    fill: #ccc7c7;
  }
  50% {
    fill: #ccc7c7;
  }
  70% {
    fill: #790e0e;
  }
  100% {
    fill: #790e0e;
  }
}
#flameofloveheart-flower4 {
  animation: flameofloveheart-flower4_f_p 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-flower4_f_p {
  0% {
    fill: #790e0e;
  }
  20% {
    fill: #ccc7c7;
  }
  50% {
    fill: #ccc7c7;
  }
  70% {
    fill: #790e0e;
  }
  100% {
    fill: #790e0e;
  }
}
#flameofloveheart-flower5 {
  animation: flameofloveheart-flower5_f_p 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-flower5_f_p {
  0% {
    fill: #790e0e;
  }
  20% {
    fill: #ccc7c7;
  }
  50% {
    fill: #ccc7c7;
  }
  70% {
    fill: #790e0e;
  }
  100% {
    fill: #790e0e;
  }
}
#flameofloveheart-flower6 {
  animation: flameofloveheart-flower6_f_p 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-flower6_f_p {
  0% {
    fill: #790e0e;
  }
  20% {
    fill: #ccc7c7;
  }
  50% {
    fill: #ccc7c7;
  }
  70% {
    fill: #790e0e;
  }
  100% {
    fill: #790e0e;
  }
}
#flameofloveheart-flower7 {
  animation: flameofloveheart-flower7_f_p 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-flower7_f_p {
  0% {
    fill: #790e0e;
  }
  20% {
    fill: #ccc7c7;
  }
  50% {
    fill: #ccc7c7;
  }
  70% {
    fill: #790e0e;
  }
  100% {
    fill: #790e0e;
  }
}
#flameofloveheart-line1_to {
  animation: flameofloveheart-line1_to__to 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-line1_to__to {
  0% {
    transform: translate(45px, 114px);
  }
  5% {
    transform: translate(40px, 109px);
  }
  10% {
    transform: translate(45px, 114px);
  }
  15% {
    transform: translate(40px, 109px);
  }
  20% {
    transform: translate(45px, 114px);
  }
  25% {
    transform: translate(40px, 109px);
  }
  30% {
    transform: translate(45px, 114px);
  }
  35% {
    transform: translate(40px, 109px);
  }
  40% {
    transform: translate(45px, 114px);
  }
  45% {
    transform: translate(40px, 109px);
  }
  50% {
    transform: translate(45px, 114px);
  }
  55% {
    transform: translate(40px, 109px);
  }
  60% {
    transform: translate(45px, 114px);
  }
  65% {
    transform: translate(40px, 109px);
  }
  70% {
    transform: translate(45px, 114px);
  }
  75% {
    transform: translate(40px, 109px);
  }
  80% {
    transform: translate(45px, 114px);
  }
  85% {
    transform: translate(40px, 109px);
  }
  90% {
    transform: translate(45px, 114px);
  }
  95% {
    transform: translate(40px, 109px);
  }
  100% {
    transform: translate(45px, 114px);
  }
}
#flameofloveheart-line3_to {
  animation: flameofloveheart-line3_to__to 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-line3_to__to {
  0% {
    transform: translate(20px, 201px);
  }
  5% {
    transform: translate(16px, 200px);
  }
  10% {
    transform: translate(20px, 200px);
  }
  15% {
    transform: translate(16px, 200px);
  }
  20% {
    transform: translate(20px, 200px);
  }
  25% {
    transform: translate(16px, 200px);
  }
  30% {
    transform: translate(20px, 200px);
  }
  35% {
    transform: translate(16px, 200px);
  }
  40% {
    transform: translate(20px, 200px);
  }
  45% {
    transform: translate(16px, 200px);
  }
  50% {
    transform: translate(20px, 200px);
  }
  55% {
    transform: translate(16px, 200px);
  }
  60% {
    transform: translate(20px, 200px);
  }
  65% {
    transform: translate(16px, 200px);
  }
  70% {
    transform: translate(20px, 200px);
  }
  75% {
    transform: translate(16px, 200px);
  }
  80% {
    transform: translate(20px, 200px);
  }
  85% {
    transform: translate(16px, 200px);
  }
  90% {
    transform: translate(20px, 200px);
  }
  95% {
    transform: translate(16px, 200px);
  }
  100% {
    transform: translate(20px, 200px);
  }
}
#flameofloveheart-line5_to {
  animation: flameofloveheart-line5_to__to 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-line5_to__to {
  0% {
    transform: translate(40px, 285px);
  }
  5% {
    transform: translate(36px, 287px);
  }
  10% {
    transform: translate(40px, 285px);
  }
  15% {
    transform: translate(36px, 287px);
  }
  20% {
    transform: translate(40px, 285px);
  }
  25% {
    transform: translate(36px, 287px);
  }
  30% {
    transform: translate(40px, 285px);
  }
  35% {
    transform: translate(36px, 287px);
  }
  40% {
    transform: translate(40px, 285px);
  }
  45% {
    transform: translate(36px, 287px);
  }
  50% {
    transform: translate(40px, 285px);
  }
  55% {
    transform: translate(36px, 287px);
  }
  60% {
    transform: translate(40px, 285px);
  }
  65% {
    transform: translate(36px, 287px);
  }
  70% {
    transform: translate(40px, 285px);
  }
  75% {
    transform: translate(36px, 287px);
  }
  80% {
    transform: translate(40px, 285px);
  }
  85% {
    transform: translate(36px, 287px);
  }
  90% {
    transform: translate(40px, 285px);
  }
  95% {
    transform: translate(36px, 287px);
  }
  100% {
    transform: translate(40px, 285px);
  }
}
#flameofloveheart-line6_to {
  animation: flameofloveheart-line6_to__to 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-line6_to__to {
  0% {
    transform: translate(60px, 321px);
  }
  5% {
    transform: translate(56px, 324px);
  }
  10% {
    transform: translate(60px, 321px);
  }
  15% {
    transform: translate(56px, 324px);
  }
  20% {
    transform: translate(60px, 321px);
  }
  25% {
    transform: translate(56px, 324px);
  }
  30% {
    transform: translate(60px, 321px);
  }
  35% {
    transform: translate(56px, 324px);
  }
  40% {
    transform: translate(60px, 321px);
  }
  45% {
    transform: translate(56px, 324px);
  }
  50% {
    transform: translate(60px, 321px);
  }
  55% {
    transform: translate(56px, 324px);
  }
  60% {
    transform: translate(60px, 321px);
  }
  65% {
    transform: translate(56px, 324px);
  }
  70% {
    transform: translate(60px, 321px);
  }
  75% {
    transform: translate(56px, 324px);
  }
  80% {
    transform: translate(60px, 321px);
  }
  85% {
    transform: translate(56px, 324px);
  }
  90% {
    transform: translate(60px, 321px);
  }
  95% {
    transform: translate(56px, 324px);
  }
  100% {
    transform: translate(60px, 321px);
  }
}
#flameofloveheart-line7_to {
  animation: flameofloveheart-line7_to__to 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-line7_to__to {
  0% {
    transform: translate(86px, 352px);
  }
  5% {
    transform: translate(82px, 356px);
  }
  10% {
    transform: translate(86px, 352px);
  }
  15% {
    transform: translate(82px, 356px);
  }
  20% {
    transform: translate(86px, 352px);
  }
  25% {
    transform: translate(82px, 356px);
  }
  30% {
    transform: translate(86px, 352px);
  }
  35% {
    transform: translate(82px, 356px);
  }
  40% {
    transform: translate(86px, 352px);
  }
  45% {
    transform: translate(82px, 356px);
  }
  50% {
    transform: translate(86px, 352px);
  }
  55% {
    transform: translate(82px, 356px);
  }
  60% {
    transform: translate(86px, 352px);
  }
  65% {
    transform: translate(82px, 356px);
  }
  70% {
    transform: translate(86px, 352px);
  }
  75% {
    transform: translate(82px, 356px);
  }
  80% {
    transform: translate(86px, 352px);
  }
  85% {
    transform: translate(82px, 356px);
  }
  90% {
    transform: translate(86px, 352px);
  }
  95% {
    transform: translate(82px, 356px);
  }
  100% {
    transform: translate(86px, 352px);
  }
}
#flameofloveheart-line8_to {
  animation: flameofloveheart-line8_to__to 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-line8_to__to {
  0% {
    transform: translate(117px, 368px);
  }
  5% {
    transform: translate(113px, 372px);
  }
  10% {
    transform: translate(116px, 368px);
  }
  15% {
    transform: translate(113px, 372px);
  }
  20% {
    transform: translate(116px, 368px);
  }
  25% {
    transform: translate(113px, 372px);
  }
  30% {
    transform: translate(116px, 368px);
  }
  35% {
    transform: translate(113px, 372px);
  }
  40% {
    transform: translate(116px, 368px);
  }
  45% {
    transform: translate(113px, 372px);
  }
  50% {
    transform: translate(116px, 368px);
  }
  55% {
    transform: translate(113px, 372px);
  }
  60% {
    transform: translate(116px, 368px);
  }
  65% {
    transform: translate(113px, 372px);
  }
  70% {
    transform: translate(116px, 368px);
  }
  75% {
    transform: translate(113px, 372px);
  }
  80% {
    transform: translate(116px, 368px);
  }
  85% {
    transform: translate(113px, 372px);
  }
  90% {
    transform: translate(116px, 368px);
  }
  95% {
    transform: translate(113px, 372px);
  }
  100% {
    transform: translate(116px, 368px);
  }
}
#flameofloveheart-line4_to {
  animation: flameofloveheart-line4_to__to 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-line4_to__to {
  0% {
    transform: translate(27px, 243px);
  }
  5% {
    transform: translate(23px, 244px);
  }
  10% {
    transform: translate(27px, 243px);
  }
  15% {
    transform: translate(23px, 244px);
  }
  20% {
    transform: translate(27px, 243px);
  }
  25% {
    transform: translate(23px, 244px);
  }
  30% {
    transform: translate(27px, 243px);
  }
  35% {
    transform: translate(23px, 244px);
  }
  40% {
    transform: translate(27px, 243px);
  }
  45% {
    transform: translate(23px, 244px);
  }
  50% {
    transform: translate(27px, 243px);
  }
  55% {
    transform: translate(23px, 244px);
  }
  60% {
    transform: translate(27px, 243px);
  }
  65% {
    transform: translate(23px, 244px);
  }
  70% {
    transform: translate(27px, 243px);
  }
  75% {
    transform: translate(23px, 244px);
  }
  80% {
    transform: translate(27px, 243px);
  }
  85% {
    transform: translate(23px, 244px);
  }
  90% {
    transform: translate(27px, 243px);
  }
  95% {
    transform: translate(23px, 244px);
  }
  100% {
    transform: translate(27px, 243px);
  }
}
#flameofloveheart-line2_to {
  animation: flameofloveheart-line2_to__to 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-line2_to__to {
  0% {
    transform: translate(27px, 155px);
  }
  5% {
    transform: translate(23px, 151px);
  }
  10% {
    transform: translate(27px, 155px);
  }
  15% {
    transform: translate(23px, 151px);
  }
  20% {
    transform: translate(27px, 155px);
  }
  25% {
    transform: translate(23px, 151px);
  }
  30% {
    transform: translate(27px, 155px);
  }
  35% {
    transform: translate(23px, 151px);
  }
  40% {
    transform: translate(27px, 155px);
  }
  45% {
    transform: translate(23px, 151px);
  }
  50% {
    transform: translate(27px, 155px);
  }
  55% {
    transform: translate(23px, 151px);
  }
  60% {
    transform: translate(27px, 155px);
  }
  65% {
    transform: translate(23px, 151px);
  }
  70% {
    transform: translate(27px, 155px);
  }
  75% {
    transform: translate(23px, 151px);
  }
  80% {
    transform: translate(27px, 155px);
  }
  85% {
    transform: translate(23px, 151px);
  }
  90% {
    transform: translate(27px, 155px);
  }
  95% {
    transform: translate(23px, 151px);
  }
  100% {
    transform: translate(27px, 155px);
  }
}
#flameofloveheart-line12_to {
  animation: flameofloveheart-line12_to__to 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-line12_to__to {
  0% {
    transform: translate(45px, 114px);
  }
  5% {
    transform: translate(40px, 109px);
  }
  10% {
    transform: translate(45px, 114px);
  }
  15% {
    transform: translate(40px, 109px);
  }
  20% {
    transform: translate(45px, 114px);
  }
  25% {
    transform: translate(40px, 109px);
  }
  30% {
    transform: translate(45px, 114px);
  }
  35% {
    transform: translate(40px, 109px);
  }
  40% {
    transform: translate(45px, 114px);
  }
  45% {
    transform: translate(40px, 109px);
  }
  50% {
    transform: translate(45px, 114px);
  }
  55% {
    transform: translate(40px, 109px);
  }
  60% {
    transform: translate(45px, 114px);
  }
  65% {
    transform: translate(40px, 109px);
  }
  70% {
    transform: translate(45px, 114px);
  }
  75% {
    transform: translate(40px, 109px);
  }
  80% {
    transform: translate(45px, 114px);
  }
  85% {
    transform: translate(40px, 109px);
  }
  90% {
    transform: translate(45px, 114px);
  }
  95% {
    transform: translate(40px, 109px);
  }
  100% {
    transform: translate(45px, 114px);
  }
}
#flameofloveheart-line32_to {
  animation: flameofloveheart-line32_to__to 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-line32_to__to {
  0% {
    transform: translate(20px, 201px);
  }
  5% {
    transform: translate(16px, 200px);
  }
  10% {
    transform: translate(20px, 200px);
  }
  15% {
    transform: translate(16px, 200px);
  }
  20% {
    transform: translate(20px, 200px);
  }
  25% {
    transform: translate(16px, 200px);
  }
  30% {
    transform: translate(20px, 200px);
  }
  35% {
    transform: translate(16px, 200px);
  }
  40% {
    transform: translate(20px, 200px);
  }
  45% {
    transform: translate(16px, 200px);
  }
  50% {
    transform: translate(20px, 200px);
  }
  55% {
    transform: translate(16px, 200px);
  }
  60% {
    transform: translate(20px, 200px);
  }
  65% {
    transform: translate(16px, 200px);
  }
  70% {
    transform: translate(20px, 200px);
  }
  75% {
    transform: translate(16px, 200px);
  }
  80% {
    transform: translate(20px, 200px);
  }
  85% {
    transform: translate(16px, 200px);
  }
  90% {
    transform: translate(20px, 200px);
  }
  95% {
    transform: translate(16px, 200px);
  }
  100% {
    transform: translate(20px, 200px);
  }
}
#flameofloveheart-line52_to {
  animation: flameofloveheart-line52_to__to 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-line52_to__to {
  0% {
    transform: translate(40px, 285px);
  }
  5% {
    transform: translate(36px, 287px);
  }
  10% {
    transform: translate(40px, 285px);
  }
  15% {
    transform: translate(36px, 287px);
  }
  20% {
    transform: translate(40px, 285px);
  }
  25% {
    transform: translate(36px, 287px);
  }
  30% {
    transform: translate(40px, 285px);
  }
  35% {
    transform: translate(36px, 287px);
  }
  40% {
    transform: translate(40px, 285px);
  }
  45% {
    transform: translate(36px, 287px);
  }
  50% {
    transform: translate(40px, 285px);
  }
  55% {
    transform: translate(36px, 287px);
  }
  60% {
    transform: translate(40px, 285px);
  }
  65% {
    transform: translate(36px, 287px);
  }
  70% {
    transform: translate(40px, 285px);
  }
  75% {
    transform: translate(36px, 287px);
  }
  80% {
    transform: translate(40px, 285px);
  }
  85% {
    transform: translate(36px, 287px);
  }
  90% {
    transform: translate(40px, 285px);
  }
  95% {
    transform: translate(36px, 287px);
  }
  100% {
    transform: translate(40px, 285px);
  }
}
#flameofloveheart-line62_to {
  animation: flameofloveheart-line62_to__to 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-line62_to__to {
  0% {
    transform: translate(60px, 321px);
  }
  5% {
    transform: translate(56px, 324px);
  }
  10% {
    transform: translate(60px, 321px);
  }
  15% {
    transform: translate(56px, 324px);
  }
  20% {
    transform: translate(60px, 321px);
  }
  25% {
    transform: translate(56px, 324px);
  }
  30% {
    transform: translate(60px, 321px);
  }
  35% {
    transform: translate(56px, 324px);
  }
  40% {
    transform: translate(60px, 321px);
  }
  45% {
    transform: translate(56px, 324px);
  }
  50% {
    transform: translate(60px, 321px);
  }
  55% {
    transform: translate(56px, 324px);
  }
  60% {
    transform: translate(60px, 321px);
  }
  65% {
    transform: translate(56px, 324px);
  }
  70% {
    transform: translate(60px, 321px);
  }
  75% {
    transform: translate(56px, 324px);
  }
  80% {
    transform: translate(60px, 321px);
  }
  85% {
    transform: translate(56px, 324px);
  }
  90% {
    transform: translate(60px, 321px);
  }
  95% {
    transform: translate(56px, 324px);
  }
  100% {
    transform: translate(60px, 321px);
  }
}
#flameofloveheart-line72_to {
  animation: flameofloveheart-line72_to__to 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-line72_to__to {
  0% {
    transform: translate(86px, 352px);
  }
  5% {
    transform: translate(82px, 356px);
  }
  10% {
    transform: translate(86px, 352px);
  }
  15% {
    transform: translate(82px, 356px);
  }
  20% {
    transform: translate(86px, 352px);
  }
  25% {
    transform: translate(82px, 356px);
  }
  30% {
    transform: translate(86px, 352px);
  }
  35% {
    transform: translate(82px, 356px);
  }
  40% {
    transform: translate(86px, 352px);
  }
  45% {
    transform: translate(82px, 356px);
  }
  50% {
    transform: translate(86px, 352px);
  }
  55% {
    transform: translate(82px, 356px);
  }
  60% {
    transform: translate(86px, 352px);
  }
  65% {
    transform: translate(82px, 356px);
  }
  70% {
    transform: translate(86px, 352px);
  }
  75% {
    transform: translate(82px, 356px);
  }
  80% {
    transform: translate(86px, 352px);
  }
  85% {
    transform: translate(82px, 356px);
  }
  90% {
    transform: translate(86px, 352px);
  }
  95% {
    transform: translate(82px, 356px);
  }
  100% {
    transform: translate(86px, 352px);
  }
}
#flameofloveheart-line82_to {
  animation: flameofloveheart-line82_to__to 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-line82_to__to {
  0% {
    transform: translate(117px, 368px);
  }
  5% {
    transform: translate(113px, 372px);
  }
  10% {
    transform: translate(116px, 368px);
  }
  15% {
    transform: translate(113px, 372px);
  }
  20% {
    transform: translate(116px, 368px);
  }
  25% {
    transform: translate(113px, 372px);
  }
  30% {
    transform: translate(116px, 368px);
  }
  35% {
    transform: translate(113px, 372px);
  }
  40% {
    transform: translate(116px, 368px);
  }
  45% {
    transform: translate(113px, 372px);
  }
  50% {
    transform: translate(116px, 368px);
  }
  55% {
    transform: translate(113px, 372px);
  }
  60% {
    transform: translate(116px, 368px);
  }
  65% {
    transform: translate(113px, 372px);
  }
  70% {
    transform: translate(116px, 368px);
  }
  75% {
    transform: translate(113px, 372px);
  }
  80% {
    transform: translate(116px, 368px);
  }
  85% {
    transform: translate(113px, 372px);
  }
  90% {
    transform: translate(116px, 368px);
  }
  95% {
    transform: translate(113px, 372px);
  }
  100% {
    transform: translate(116px, 368px);
  }
}
#flameofloveheart-line42_to {
  animation: flameofloveheart-line42_to__to 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-line42_to__to {
  0% {
    transform: translate(27px, 243px);
  }
  5% {
    transform: translate(23px, 244px);
  }
  10% {
    transform: translate(27px, 243px);
  }
  15% {
    transform: translate(23px, 244px);
  }
  20% {
    transform: translate(27px, 243px);
  }
  25% {
    transform: translate(23px, 244px);
  }
  30% {
    transform: translate(27px, 243px);
  }
  35% {
    transform: translate(23px, 244px);
  }
  40% {
    transform: translate(27px, 243px);
  }
  45% {
    transform: translate(23px, 244px);
  }
  50% {
    transform: translate(27px, 243px);
  }
  55% {
    transform: translate(23px, 244px);
  }
  60% {
    transform: translate(27px, 243px);
  }
  65% {
    transform: translate(23px, 244px);
  }
  70% {
    transform: translate(27px, 243px);
  }
  75% {
    transform: translate(23px, 244px);
  }
  80% {
    transform: translate(27px, 243px);
  }
  85% {
    transform: translate(23px, 244px);
  }
  90% {
    transform: translate(27px, 243px);
  }
  95% {
    transform: translate(23px, 244px);
  }
  100% {
    transform: translate(27px, 243px);
  }
}
#flameofloveheart-line22_to {
  animation: flameofloveheart-line22_to__to 10000ms linear infinite normal forwards;
}
@keyframes flameofloveheart-line22_to__to {
  0% {
    transform: translate(27px, 155px);
  }
  5% {
    transform: translate(23px, 151px);
  }
  10% {
    transform: translate(27px, 155px);
  }
  15% {
    transform: translate(23px, 151px);
  }
  20% {
    transform: translate(27px, 155px);
  }
  25% {
    transform: translate(23px, 151px);
  }
  30% {
    transform: translate(27px, 155px);
  }
  35% {
    transform: translate(23px, 151px);
  }
  40% {
    transform: translate(27px, 155px);
  }
  45% {
    transform: translate(23px, 151px);
  }
  50% {
    transform: translate(27px, 155px);
  }
  55% {
    transform: translate(23px, 151px);
  }
  60% {
    transform: translate(27px, 155px);
  }
  65% {
    transform: translate(23px, 151px);
  }
  70% {
    transform: translate(27px, 155px);
  }
  75% {
    transform: translate(23px, 151px);
  }
  80% {
    transform: translate(27px, 155px);
  }
  85% {
    transform: translate(23px, 151px);
  }
  90% {
    transform: translate(27px, 155px);
  }
  95% {
    transform: translate(23px, 151px);
  }
  100% {
    transform: translate(27px, 155px);
  }
}
