#sacredheartofjesus-2-u-group-4 {
  animation: sacredheartofjesus-2-u-group-4_c_o 5000ms linear infinite normal forwards;
}
@keyframes sacredheartofjesus-2-u-group-4_c_o {
  0% {
    opacity: 0;
  }
  24% {
    opacity: 1;
  }
  48% {
    opacity: 0;
  }
  74% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#sacredheartofjesus-2-u-group-5 {
  animation: sacredheartofjesus-2-u-group-5_c_o 5000ms linear infinite normal forwards;
}
@keyframes sacredheartofjesus-2-u-group-5_c_o {
  0% {
    opacity: 0;
  }
  14% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  46% {
    opacity: 1;
  }
  62% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#sacredheartofjesus-2-u-group-1 {
  animation: sacredheartofjesus-2-u-group-1_c_o 5000ms linear infinite normal forwards;
}
@keyframes sacredheartofjesus-2-u-group-1_c_o {
  0% {
    opacity: 1;
  }
  22% {
    opacity: 0;
  }
  48% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#sacredheartofjesus-2-u-group-6 {
  animation: sacredheartofjesus-2-u-group-6_c_o 5000ms linear infinite normal forwards;
}
@keyframes sacredheartofjesus-2-u-group-6_c_o {
  0% {
    opacity: 0.4;
  }
  14% {
    opacity: 1;
  }
  32% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  88% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
#sacredheartofjesus-2-u-group-7 {
  animation: sacredheartofjesus-2-u-group-7_c_o 5000ms linear infinite normal forwards;
}
@keyframes sacredheartofjesus-2-u-group-7_c_o {
  0% {
    opacity: 0.7;
  }
  18% {
    opacity: 0;
  }
  36% {
    opacity: 1;
  }
  54% {
    opacity: 0;
  }
  72% {
    opacity: 1;
  }
  88% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}
#sacredheartofjesus-2-u-group-3 {
  animation: sacredheartofjesus-2-u-group-3_c_o 5000ms linear infinite normal forwards;
}
@keyframes sacredheartofjesus-2-u-group-3_c_o {
  0% {
    opacity: 0;
  }
  22% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  76% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#sacredheartofjesus-2-u-group-2 {
  animation: sacredheartofjesus-2-u-group-2_c_o 5000ms linear infinite normal forwards;
}
@keyframes sacredheartofjesus-2-u-group-2_c_o {
  0% {
    opacity: 0.2;
  }
  14% {
    opacity: 1;
  }
  34% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  72% {
    opacity: 0;
  }
  86% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
