#eiD7IC5pNRU4_to {
  animation: eiD7IC5pNRU4_to__to 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU4_to__to {
  0% {
    transform: translate(149.156647px, 126.327378px);
  }
  4.444444% {
    transform: translate(149.156647px, 126.327378px);
  }
  31.111111% {
    transform: translate(149.156647px, 128.327378px);
  }
  57.777778% {
    transform: translate(149.156647px, 126.327378px);
  }
  100% {
    transform: translate(149.156647px, 126.327378px);
  }
}
#eiD7IC5pNRU4_ts {
  animation: eiD7IC5pNRU4_ts__ts 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU4_ts__ts {
  0% {
    transform: scale(1, 1);
  }
  4.444444% {
    transform: scale(1, 1);
  }
  31.111111% {
    transform: scale(1.05, 1.05);
  }
  57.777778% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
#eiD7IC5pNRU7 {
  animation: eiD7IC5pNRU7_c_o 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU7_c_o {
  0% {
    opacity: 0;
  }
  26.666667% {
    opacity: 1;
  }
  53.333333% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
#eiD7IC5pNRU10 {
  animation: eiD7IC5pNRU10_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU10_f_p {
  0% {
    fill: #880303;
  }
  22.222222% {
    fill: #880303;
  }
  35.555556% {
    fill: #ff0202;
  }
  46.666667% {
    fill: #ff0202;
  }
  60% {
    fill: #880303;
  }
  100% {
    fill: #880303;
  }
}
#eiD7IC5pNRU18 {
  animation: eiD7IC5pNRU18_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU18_f_p {
  0% {
    fill: #b33434;
  }
  24.444444% {
    fill: #b33434;
  }
  37.777778% {
    fill: #ff0202;
  }
  48.888889% {
    fill: #ff0202;
  }
  62.222222% {
    fill: #b33434;
  }
  100% {
    fill: #b33434;
  }
}
#eiD7IC5pNRU24 {
  animation: eiD7IC5pNRU24_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU24_f_p {
  0% {
    fill: #880303;
  }
  11.111111% {
    fill: #880303;
  }
  24.444444% {
    fill: #ff0202;
  }
  37.777778% {
    fill: #ff0202;
  }
  51.111111% {
    fill: #880303;
  }
  100% {
    fill: #880303;
  }
}
#eiD7IC5pNRU30 {
  animation: eiD7IC5pNRU30_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU30_f_p {
  0% {
    fill: #b33434;
  }
  2.222222% {
    fill: #b33434;
  }
  15.555556% {
    fill: #ff0202;
  }
  26.666667% {
    fill: #ff0202;
  }
  40% {
    fill: #b33434;
  }
  100% {
    fill: #b33434;
  }
}
#eiD7IC5pNRU36 {
  animation: eiD7IC5pNRU36_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU36_f_p {
  0% {
    fill: #18546d;
  }
  8.888889% {
    fill: #18546d;
  }
  22.222222% {
    fill: #027a7e;
  }
  35.555556% {
    fill: #027a7e;
  }
  48.888889% {
    fill: #18546d;
  }
  100% {
    fill: #18546d;
  }
}
#eiD7IC5pNRU44 {
  animation: eiD7IC5pNRU44_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU44_f_p {
  0% {
    fill: #941c1c;
  }
  20% {
    fill: #941c1c;
  }
  33.333333% {
    fill: #ff0202;
  }
  46.666667% {
    fill: #ff0202;
  }
  60% {
    fill: #941c1c;
  }
  100% {
    fill: #941c1c;
  }
}
#eiD7IC5pNRU48 {
  animation: eiD7IC5pNRU48_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU48_f_p {
  0% {
    fill: #c5ab42;
  }
  22.222222% {
    fill: #c5ab42;
  }
  44.444444% {
    fill: #ccb200;
  }
  55.555556% {
    fill: #ccb200;
  }
  77.777778% {
    fill: #c5ab42;
  }
  100% {
    fill: #c5ab42;
  }
}
#eiD7IC5pNRU50 {
  animation: eiD7IC5pNRU50_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU50_f_p {
  0% {
    fill: #18546d;
  }
  22.222222% {
    fill: #18546d;
  }
  44.444444% {
    fill: #027a7e;
  }
  55.555556% {
    fill: #027a7e;
  }
  77.777778% {
    fill: #18546d;
  }
  100% {
    fill: #18546d;
  }
}
#eiD7IC5pNRU52 {
  animation: eiD7IC5pNRU52_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU52_f_p {
  0% {
    fill: #005953;
  }
  11.111111% {
    fill: #005953;
  }
  24.444444% {
    fill: #00776e;
  }
  37.777778% {
    fill: #00776e;
  }
  51.111111% {
    fill: #005953;
  }
  100% {
    fill: #005953;
  }
}
#eiD7IC5pNRU54 {
  animation: eiD7IC5pNRU54_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU54_f_p {
  0% {
    fill: #b33434;
  }
  20% {
    fill: #b33434;
  }
  33.333333% {
    fill: #ff0202;
  }
  46.666667% {
    fill: #ff0202;
  }
  60% {
    fill: #b33434;
  }
  100% {
    fill: #b33434;
  }
}
#eiD7IC5pNRU56 {
  animation: eiD7IC5pNRU56_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU56_f_p {
  0% {
    fill: #b33434;
  }
  20% {
    fill: #b33434;
  }
  33.333333% {
    fill: #ff0202;
  }
  48.888889% {
    fill: #ff0202;
  }
  62.222222% {
    fill: #b33434;
  }
  100% {
    fill: #b33434;
  }
}
#eiD7IC5pNRU64 {
  animation: eiD7IC5pNRU64_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU64_f_p {
  0% {
    fill: #941c1c;
  }
  13.333333% {
    fill: #ff0202;
  }
  26.666667% {
    fill: #ff0202;
  }
  40% {
    fill: #941c1c;
  }
  100% {
    fill: #941c1c;
  }
}
#eiD7IC5pNRU66 {
  animation: eiD7IC5pNRU66_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU66_f_p {
  0% {
    fill: #880303;
  }
  13.333333% {
    fill: #ff0202;
  }
  26.666667% {
    fill: #ff0202;
  }
  40% {
    fill: #880303;
  }
  100% {
    fill: #880303;
  }
}
#eiD7IC5pNRU68 {
  animation: eiD7IC5pNRU68_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU68_f_p {
  0% {
    fill: #880303;
  }
  13.333333% {
    fill: #ff0202;
  }
  26.666667% {
    fill: #ff0202;
  }
  40% {
    fill: #880303;
  }
  100% {
    fill: #880303;
  }
}
#eiD7IC5pNRU69_to {
  animation: eiD7IC5pNRU69_to__to 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU69_to__to {
  0% {
    transform: translate(149.156647px, 197.327378px);
  }
  24.444444% {
    transform: translate(149.156647px, 197.327378px);
  }
  53.333333% {
    transform: translate(149.156647px, 199.327378px);
  }
  82.222222% {
    transform: translate(149.156647px, 197.327378px);
  }
  100% {
    transform: translate(149.156647px, 197.327378px);
  }
}
#eiD7IC5pNRU69_ts {
  animation: eiD7IC5pNRU69_ts__ts 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU69_ts__ts {
  0% {
    transform: scale(1, 1);
  }
  24.444444% {
    transform: scale(1, 1);
  }
  53.333333% {
    transform: scale(1.05, 1.05);
  }
  82.222222% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
#eiD7IC5pNRU73 {
  animation: eiD7IC5pNRU73_c_o 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU73_c_o {
  0% {
    opacity: 0;
  }
  22.222222% {
    opacity: 0;
  }
  48.888889% {
    opacity: 1;
  }
  75.555556% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
#eiD7IC5pNRU77 {
  animation: eiD7IC5pNRU77_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU77_f_p {
  0% {
    fill: #880303;
  }
  44.444444% {
    fill: #880303;
  }
  57.777778% {
    fill: #ff0202;
  }
  71.111111% {
    fill: #ff0202;
  }
  84.444444% {
    fill: #880303;
  }
  100% {
    fill: #880303;
  }
}
#eiD7IC5pNRU81 {
  animation: eiD7IC5pNRU81_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU81_f_p {
  0% {
    fill: #880303;
  }
  51.111111% {
    fill: #880303;
  }
  62.222222% {
    fill: #ff0202;
  }
  75.555556% {
    fill: #ff0202;
  }
  86.666667% {
    fill: #880303;
  }
  100% {
    fill: #880303;
  }
}
#eiD7IC5pNRU93 {
  animation: eiD7IC5pNRU93_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU93_f_p {
  0% {
    fill: #b33434;
  }
  33.333333% {
    fill: #b33434;
  }
  46.666667% {
    fill: #ff0202;
  }
  60% {
    fill: #ff0202;
  }
  73.333333% {
    fill: #b33434;
  }
  100% {
    fill: #b33434;
  }
}
#eiD7IC5pNRU101 {
  animation: eiD7IC5pNRU101_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU101_f_p {
  0% {
    fill: #c5ab42;
  }
  35.555556% {
    fill: #c5ab42;
  }
  48.888889% {
    fill: #ccb200;
  }
  60% {
    fill: #ccb200;
  }
  73.333333% {
    fill: #c5ab42;
  }
  100% {
    fill: #c5ab42;
  }
}
#eiD7IC5pNRU111 {
  animation: eiD7IC5pNRU111_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU111_f_p {
  0% {
    fill: #941c1c;
  }
  55.555556% {
    fill: #941c1c;
  }
  66.666667% {
    fill: #ff0202;
  }
  77.777778% {
    fill: #ff0202;
  }
  88.888889% {
    fill: #941c1c;
  }
  100% {
    fill: #941c1c;
  }
}
#eiD7IC5pNRU113 {
  animation: eiD7IC5pNRU113_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU113_f_p {
  0% {
    fill: #18546d;
  }
  44.444444% {
    fill: #18546d;
  }
  57.777778% {
    fill: #027a7e;
  }
  68.888889% {
    fill: #027a7e;
  }
  82.222222% {
    fill: #18546d;
  }
  100% {
    fill: #18546d;
  }
}
#eiD7IC5pNRU115 {
  animation: eiD7IC5pNRU115_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU115_f_p {
  0% {
    fill: #005953;
  }
  46.666667% {
    fill: #005953;
  }
  60% {
    fill: #00776e;
  }
  73.333333% {
    fill: #00776e;
  }
  86.666667% {
    fill: #005953;
  }
  100% {
    fill: #005953;
  }
}
#eiD7IC5pNRU117 {
  animation: eiD7IC5pNRU117_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU117_f_p {
  0% {
    fill: #005953;
  }
  37.777778% {
    fill: #005953;
  }
  51.111111% {
    fill: #00776e;
  }
  62.222222% {
    fill: #00776e;
  }
  75.555556% {
    fill: #005953;
  }
  100% {
    fill: #005953;
  }
}
#eiD7IC5pNRU125 {
  animation-name: eiD7IC5pNRU125_f_p, eiD7IC5pNRU125_s_p;
  animation-duration: 3750ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}
@keyframes eiD7IC5pNRU125_f_p {
  0% {
    fill: #941c1c;
  }
  40% {
    fill: #941c1c;
  }
  53.333333% {
    fill: #ff0202;
  }
  66.666667% {
    fill: #ff0202;
  }
  80% {
    fill: #941c1c;
  }
  100% {
    fill: #941c1c;
  }
}
@keyframes eiD7IC5pNRU125_s_p {
  0% {
    stroke: #941c1c;
  }
  40% {
    stroke: #941c1c;
  }
  53.333333% {
    stroke: #ff0202;
  }
  66.666667% {
    stroke: #ff0202;
  }
  80% {
    stroke: #941c1c;
  }
  100% {
    stroke: #941c1c;
  }
}
#eiD7IC5pNRU127 {
  animation: eiD7IC5pNRU127_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU127_f_p {
  0% {
    fill: #941c1c;
  }
  40% {
    fill: #941c1c;
  }
  51.111111% {
    fill: #ff0202;
  }
  62.222222% {
    fill: #ff0202;
  }
  73.333333% {
    fill: #941c1c;
  }
  100% {
    fill: #941c1c;
  }
}
#eiD7IC5pNRU131 {
  animation: eiD7IC5pNRU131_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU131_f_p {
  0% {
    fill: #880303;
  }
  33.333333% {
    fill: #880303;
  }
  46.666667% {
    fill: #ff0202;
  }
  60% {
    fill: #ff0202;
  }
  73.333333% {
    fill: #880303;
  }
  100% {
    fill: #880303;
  }
}
#eiD7IC5pNRU133 {
  animation: eiD7IC5pNRU133_f_p 3750ms linear infinite normal forwards;
}
@keyframes eiD7IC5pNRU133_f_p {
  0% {
    fill: #880303;
  }
  33.333333% {
    fill: #880303;
  }
  46.666667% {
    fill: #ff0202;
  }
  60% {
    fill: #ff0202;
  }
  73.333333% {
    fill: #880303;
  }
  100% {
    fill: #880303;
  }
}
