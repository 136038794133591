#ef3ojvrPnKI82 {
  animation: ef3ojvrPnKI82_c_o 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI82_c_o {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  62.5% {
    opacity: 1;
  }
  87.5% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#ef3ojvrPnKI109 {
  animation: ef3ojvrPnKI109_c_o 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI109_c_o {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  37.5% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
#ef3ojvrPnKI120 {
  animation: ef3ojvrPnKI120_c_o 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI120_c_o {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  37.5% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
#ef3ojvrPnKI226 {
  animation: ef3ojvrPnKI226_s_p 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI226_s_p {
  0% {
    stroke: #fff;
  }
  25% {
    stroke: #fed800;
  }
  32.5% {
    stroke: #fed800;
  }
  37.5% {
    stroke: #05b8cc;
  }
  50% {
    stroke: #fff;
  }
  62.5% {
    stroke: #dc0404;
  }
  87.5% {
    stroke: #dc0404;
  }
  100% {
    stroke: #fff;
  }
}
#ef3ojvrPnKI227 {
  animation: ef3ojvrPnKI227_c_o 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI227_c_o {
  0% {
    opacity: 0;
  }
  12.5% {
    opacity: 1;
  }
  37.5% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  62.5% {
    opacity: 1;
  }
  87.5% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#ef3ojvrPnKI228-fill-0 {
  animation: ef3ojvrPnKI228-fill-0__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI228-fill-0__c {
  0% {
    stop-color: #fed800;
  }
  25% {
    stop-color: #fed800;
  }
  37.5% {
    stop-color: #fed800;
  }
  62.5% {
    stop-color: #880808;
  }
  87.5% {
    stop-color: #880808;
  }
  100% {
    stop-color: #880808;
  }
}
#ef3ojvrPnKI228-fill-1 {
  animation: ef3ojvrPnKI228-fill-1__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI228-fill-1__c {
  0% {
    stop-color: #000;
  }
  25% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#ef3ojvrPnKI228 {
  animation: ef3ojvrPnKI228_s_p 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI228_s_p {
  0% {
    stroke: #fed800;
  }
  25% {
    stroke: #fed800;
  }
  37.5% {
    stroke: #fed800;
  }
  62.5% {
    stroke: #880808;
  }
  87.5% {
    stroke: #880808;
  }
  100% {
    stroke: #880808;
  }
}
#ef3ojvrPnKI229-fill-0 {
  animation: ef3ojvrPnKI229-fill-0__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI229-fill-0__c {
  0% {
    stop-color: #fed800;
  }
  25% {
    stop-color: #fed800;
  }
  37.5% {
    stop-color: #fed800;
  }
  62.5% {
    stop-color: #880808;
  }
  87.5% {
    stop-color: #880808;
  }
  100% {
    stop-color: #880808;
  }
}
#ef3ojvrPnKI229-fill-1 {
  animation: ef3ojvrPnKI229-fill-1__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI229-fill-1__c {
  0% {
    stop-color: #000;
  }
  25% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#ef3ojvrPnKI229 {
  animation: ef3ojvrPnKI229_s_p 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI229_s_p {
  0% {
    stroke: #fed800;
  }
  25% {
    stroke: #fed800;
  }
  37.5% {
    stroke: #fed800;
  }
  62.5% {
    stroke: #880808;
  }
  87.5% {
    stroke: #880808;
  }
  100% {
    stroke: #880808;
  }
}
#ef3ojvrPnKI231-fill-0 {
  animation: ef3ojvrPnKI231-fill-0__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI231-fill-0__c {
  0% {
    stop-color: #fed800;
  }
  25% {
    stop-color: #fed800;
  }
  37.5% {
    stop-color: #fed800;
  }
  62.5% {
    stop-color: #880808;
  }
  87.5% {
    stop-color: #880808;
  }
  100% {
    stop-color: #880808;
  }
}
#ef3ojvrPnKI231-fill-1 {
  animation: ef3ojvrPnKI231-fill-1__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI231-fill-1__c {
  0% {
    stop-color: #000;
  }
  25% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#ef3ojvrPnKI231 {
  animation: ef3ojvrPnKI231_s_p 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI231_s_p {
  0% {
    stroke: #fed800;
  }
  25% {
    stroke: #fed800;
  }
  37.5% {
    stroke: #fed800;
  }
  62.5% {
    stroke: #880808;
  }
  87.5% {
    stroke: #880808;
  }
  100% {
    stroke: #880808;
  }
}
#ef3ojvrPnKI232-fill-0 {
  animation: ef3ojvrPnKI232-fill-0__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI232-fill-0__c {
  0% {
    stop-color: #fed800;
  }
  25% {
    stop-color: #fed800;
  }
  37.5% {
    stop-color: #fed800;
  }
  62.5% {
    stop-color: #880808;
  }
  87.5% {
    stop-color: #880808;
  }
  100% {
    stop-color: #880808;
  }
}
#ef3ojvrPnKI232-fill-1 {
  animation: ef3ojvrPnKI232-fill-1__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI232-fill-1__c {
  0% {
    stop-color: #000;
  }
  25% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#ef3ojvrPnKI232 {
  animation: ef3ojvrPnKI232_s_p 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI232_s_p {
  0% {
    stroke: #fed800;
  }
  25% {
    stroke: #fed800;
  }
  37.5% {
    stroke: #fed800;
  }
  62.5% {
    stroke: #880808;
  }
  87.5% {
    stroke: #880808;
  }
  100% {
    stroke: #880808;
  }
}
#ef3ojvrPnKI234-fill-0 {
  animation: ef3ojvrPnKI234-fill-0__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI234-fill-0__c {
  0% {
    stop-color: #fed800;
  }
  25% {
    stop-color: #fed800;
  }
  37.5% {
    stop-color: #fed800;
  }
  62.5% {
    stop-color: #880808;
  }
  87.5% {
    stop-color: #880808;
  }
  100% {
    stop-color: #880808;
  }
}
#ef3ojvrPnKI234-fill-1 {
  animation: ef3ojvrPnKI234-fill-1__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI234-fill-1__c {
  0% {
    stop-color: #000;
  }
  25% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#ef3ojvrPnKI234 {
  animation: ef3ojvrPnKI234_s_p 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI234_s_p {
  0% {
    stroke: #fed800;
  }
  25% {
    stroke: #fed800;
  }
  37.5% {
    stroke: #fed800;
  }
  62.5% {
    stroke: #880808;
  }
  87.5% {
    stroke: #880808;
  }
  100% {
    stroke: #880808;
  }
}
#ef3ojvrPnKI235-fill-0 {
  animation: ef3ojvrPnKI235-fill-0__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI235-fill-0__c {
  0% {
    stop-color: #fed800;
  }
  25% {
    stop-color: #fed800;
  }
  37.5% {
    stop-color: #fed800;
  }
  62.5% {
    stop-color: #880808;
  }
  87.5% {
    stop-color: #880808;
  }
  100% {
    stop-color: #880808;
  }
}
#ef3ojvrPnKI235-fill-1 {
  animation: ef3ojvrPnKI235-fill-1__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI235-fill-1__c {
  0% {
    stop-color: #000;
  }
  25% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#ef3ojvrPnKI235 {
  animation: ef3ojvrPnKI235_s_p 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI235_s_p {
  0% {
    stroke: #fed800;
  }
  25% {
    stroke: #fed800;
  }
  37.5% {
    stroke: #fed800;
  }
  62.5% {
    stroke: #880808;
  }
  87.5% {
    stroke: #880808;
  }
  100% {
    stroke: #880808;
  }
}
#ef3ojvrPnKI237-fill-0 {
  animation: ef3ojvrPnKI237-fill-0__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI237-fill-0__c {
  0% {
    stop-color: #fed800;
  }
  25% {
    stop-color: #fed800;
  }
  37.5% {
    stop-color: #fed800;
  }
  62.5% {
    stop-color: #880808;
  }
  87.5% {
    stop-color: #880808;
  }
  100% {
    stop-color: #880808;
  }
}
#ef3ojvrPnKI237-fill-1 {
  animation: ef3ojvrPnKI237-fill-1__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI237-fill-1__c {
  0% {
    stop-color: #000;
  }
  25% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#ef3ojvrPnKI237 {
  animation: ef3ojvrPnKI237_s_p 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI237_s_p {
  0% {
    stroke: #fed800;
  }
  25% {
    stroke: #fed800;
  }
  37.5% {
    stroke: #fed800;
  }
  62.5% {
    stroke: #880808;
  }
  87.5% {
    stroke: #880808;
  }
  100% {
    stroke: #880808;
  }
}
#ef3ojvrPnKI238-fill-0 {
  animation: ef3ojvrPnKI238-fill-0__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI238-fill-0__c {
  0% {
    stop-color: #fed800;
  }
  25% {
    stop-color: #fed800;
  }
  37.5% {
    stop-color: #fed800;
  }
  62.5% {
    stop-color: #880808;
  }
  87.5% {
    stop-color: #880808;
  }
  100% {
    stop-color: #880808;
  }
}
#ef3ojvrPnKI238-fill-1 {
  animation: ef3ojvrPnKI238-fill-1__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI238-fill-1__c {
  0% {
    stop-color: #000;
  }
  25% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#ef3ojvrPnKI238 {
  animation: ef3ojvrPnKI238_s_p 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI238_s_p {
  0% {
    stroke: #fed800;
  }
  25% {
    stroke: #fed800;
  }
  37.5% {
    stroke: #fed800;
  }
  62.5% {
    stroke: #880808;
  }
  87.5% {
    stroke: #880808;
  }
  100% {
    stroke: #880808;
  }
}
#ef3ojvrPnKI240-fill-0 {
  animation: ef3ojvrPnKI240-fill-0__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI240-fill-0__c {
  0% {
    stop-color: #fed800;
  }
  25% {
    stop-color: #fed800;
  }
  37.5% {
    stop-color: #fed800;
  }
  62.5% {
    stop-color: #880808;
  }
  87.5% {
    stop-color: #880808;
  }
  100% {
    stop-color: #880808;
  }
}
#ef3ojvrPnKI240-fill-1 {
  animation: ef3ojvrPnKI240-fill-1__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI240-fill-1__c {
  0% {
    stop-color: #000;
  }
  25% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#ef3ojvrPnKI240 {
  animation: ef3ojvrPnKI240_s_p 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI240_s_p {
  0% {
    stroke: #fed800;
  }
  25% {
    stroke: #fed800;
  }
  37.5% {
    stroke: #fed800;
  }
  62.5% {
    stroke: #880808;
  }
  87.5% {
    stroke: #880808;
  }
  100% {
    stroke: #880808;
  }
}
#ef3ojvrPnKI241-fill-0 {
  animation: ef3ojvrPnKI241-fill-0__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI241-fill-0__c {
  0% {
    stop-color: #fed800;
  }
  25% {
    stop-color: #fed800;
  }
  37.5% {
    stop-color: #fed800;
  }
  62.5% {
    stop-color: #880808;
  }
  87.5% {
    stop-color: #880808;
  }
  100% {
    stop-color: #880808;
  }
}
#ef3ojvrPnKI241-fill-1 {
  animation: ef3ojvrPnKI241-fill-1__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI241-fill-1__c {
  0% {
    stop-color: #000;
  }
  25% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#ef3ojvrPnKI241 {
  animation: ef3ojvrPnKI241_s_p 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI241_s_p {
  0% {
    stroke: #fed800;
  }
  25% {
    stroke: #fed800;
  }
  37.5% {
    stroke: #fed800;
  }
  62.5% {
    stroke: #880808;
  }
  87.5% {
    stroke: #880808;
  }
  100% {
    stroke: #880808;
  }
}
#ef3ojvrPnKI242-fill-0 {
  animation: ef3ojvrPnKI242-fill-0__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI242-fill-0__c {
  0% {
    stop-color: #fed800;
  }
  25% {
    stop-color: #fed800;
  }
  37.5% {
    stop-color: #fed800;
  }
  62.5% {
    stop-color: #880808;
  }
  87.5% {
    stop-color: #880808;
  }
  100% {
    stop-color: #880808;
  }
}
#ef3ojvrPnKI242-fill-1 {
  animation: ef3ojvrPnKI242-fill-1__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI242-fill-1__c {
  0% {
    stop-color: #000;
  }
  25% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#ef3ojvrPnKI242 {
  animation: ef3ojvrPnKI242_s_p 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI242_s_p {
  0% {
    stroke: #fed800;
  }
  25% {
    stroke: #fed800;
  }
  37.5% {
    stroke: #fed800;
  }
  62.5% {
    stroke: #880808;
  }
  87.5% {
    stroke: #880808;
  }
  100% {
    stroke: #880808;
  }
}
#ef3ojvrPnKI243-fill-0 {
  animation: ef3ojvrPnKI243-fill-0__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI243-fill-0__c {
  0% {
    stop-color: #fed800;
  }
  25% {
    stop-color: #fed800;
  }
  37.5% {
    stop-color: #fed800;
  }
  62.5% {
    stop-color: #880808;
  }
  87.5% {
    stop-color: #880808;
  }
  100% {
    stop-color: #880808;
  }
}
#ef3ojvrPnKI243-fill-1 {
  animation: ef3ojvrPnKI243-fill-1__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI243-fill-1__c {
  0% {
    stop-color: #000;
  }
  25% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#ef3ojvrPnKI243 {
  animation: ef3ojvrPnKI243_s_p 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI243_s_p {
  0% {
    stroke: #fed800;
  }
  25% {
    stroke: #fed800;
  }
  37.5% {
    stroke: #fed800;
  }
  62.5% {
    stroke: #880808;
  }
  87.5% {
    stroke: #880808;
  }
  100% {
    stroke: #880808;
  }
}
#ef3ojvrPnKI244-fill-0 {
  animation: ef3ojvrPnKI244-fill-0__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI244-fill-0__c {
  0% {
    stop-color: #fed800;
  }
  25% {
    stop-color: #fed800;
  }
  37.5% {
    stop-color: #fed800;
  }
  62.5% {
    stop-color: #880808;
  }
  87.5% {
    stop-color: #880808;
  }
  100% {
    stop-color: #880808;
  }
}
#ef3ojvrPnKI244-fill-1 {
  animation: ef3ojvrPnKI244-fill-1__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI244-fill-1__c {
  0% {
    stop-color: #000;
  }
  25% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#ef3ojvrPnKI244 {
  animation: ef3ojvrPnKI244_s_p 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI244_s_p {
  0% {
    stroke: #fed800;
  }
  25% {
    stroke: #fed800;
  }
  37.5% {
    stroke: #fed800;
  }
  62.5% {
    stroke: #880808;
  }
  87.5% {
    stroke: #880808;
  }
  100% {
    stroke: #880808;
  }
}
#ef3ojvrPnKI245-fill-0 {
  animation: ef3ojvrPnKI245-fill-0__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI245-fill-0__c {
  0% {
    stop-color: #fed800;
  }
  25% {
    stop-color: #fed800;
  }
  37.5% {
    stop-color: #fed800;
  }
  62.5% {
    stop-color: #880808;
  }
  87.5% {
    stop-color: #880808;
  }
  100% {
    stop-color: #880808;
  }
}
#ef3ojvrPnKI245-fill-1 {
  animation: ef3ojvrPnKI245-fill-1__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI245-fill-1__c {
  0% {
    stop-color: #000;
  }
  25% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#ef3ojvrPnKI245 {
  animation: ef3ojvrPnKI245_s_p 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI245_s_p {
  0% {
    stroke: #fed800;
  }
  25% {
    stroke: #fed800;
  }
  37.5% {
    stroke: #fed800;
  }
  62.5% {
    stroke: #880808;
  }
  87.5% {
    stroke: #880808;
  }
  100% {
    stroke: #880808;
  }
}
#ef3ojvrPnKI247-fill-0 {
  animation: ef3ojvrPnKI247-fill-0__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI247-fill-0__c {
  0% {
    stop-color: #fed800;
  }
  25% {
    stop-color: #fed800;
  }
  37.5% {
    stop-color: #fed800;
  }
  62.5% {
    stop-color: #880808;
  }
  87.5% {
    stop-color: #880808;
  }
  100% {
    stop-color: #880808;
  }
}
#ef3ojvrPnKI247-fill-1 {
  animation: ef3ojvrPnKI247-fill-1__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI247-fill-1__c {
  0% {
    stop-color: #000;
  }
  25% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#ef3ojvrPnKI247 {
  animation: ef3ojvrPnKI247_s_p 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI247_s_p {
  0% {
    stroke: #fed800;
  }
  25% {
    stroke: #fed800;
  }
  37.5% {
    stroke: #fed800;
  }
  62.5% {
    stroke: #880808;
  }
  87.5% {
    stroke: #880808;
  }
  100% {
    stroke: #880808;
  }
}
#ef3ojvrPnKI248-fill-0 {
  animation: ef3ojvrPnKI248-fill-0__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI248-fill-0__c {
  0% {
    stop-color: #fed800;
  }
  25% {
    stop-color: #fed800;
  }
  37.5% {
    stop-color: #fed800;
  }
  62.5% {
    stop-color: #880808;
  }
  87.5% {
    stop-color: #880808;
  }
  100% {
    stop-color: #880808;
  }
}
#ef3ojvrPnKI248-fill-1 {
  animation: ef3ojvrPnKI248-fill-1__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI248-fill-1__c {
  0% {
    stop-color: #000;
  }
  25% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#ef3ojvrPnKI248 {
  animation: ef3ojvrPnKI248_s_p 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI248_s_p {
  0% {
    stroke: #fed800;
  }
  25% {
    stroke: #fed800;
  }
  37.5% {
    stroke: #fed800;
  }
  62.5% {
    stroke: #880808;
  }
  87.5% {
    stroke: #880808;
  }
  100% {
    stroke: #880808;
  }
}
#ef3ojvrPnKI249-fill-0 {
  animation: ef3ojvrPnKI249-fill-0__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI249-fill-0__c {
  0% {
    stop-color: #fed800;
  }
  25% {
    stop-color: #fed800;
  }
  37.5% {
    stop-color: #fed800;
  }
  62.5% {
    stop-color: #880808;
  }
  87.5% {
    stop-color: #880808;
  }
  100% {
    stop-color: #880808;
  }
}
#ef3ojvrPnKI249-fill-1 {
  animation: ef3ojvrPnKI249-fill-1__c 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI249-fill-1__c {
  0% {
    stop-color: #000;
  }
  25% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#ef3ojvrPnKI249 {
  animation: ef3ojvrPnKI249_s_p 11428.57142857143ms linear infinite normal forwards;
}
@keyframes ef3ojvrPnKI249_s_p {
  0% {
    stroke: #fed800;
  }
  25% {
    stroke: #fed800;
  }
  37.5% {
    stroke: #fed800;
  }
  62.5% {
    stroke: #880808;
  }
  87.5% {
    stroke: #880808;
  }
  100% {
    stroke: #880808;
  }
}
