.heavenly-power-page {
  min-width: 100vw;
  min-height: 100vh;
  height: fit-content;
  position: relative;
  opacity: 0;
  animation: fadeIn 1.5s linear 0.5s forwards;
}

/* 
When Scaling for Mobile:
  Context:
  - Change Height for .heavenly-power-context, .heavenly-power-context-overview 
  - Change grid-template-rows for .heavenly-power-context, .heavenly-power-context-overview 
  - Change the Height for the .heavenly-power-context-body to correspond to designated grid row height (same thing for .heavenly-power-context-btns)
  Prayer:
  - 

*/

/* Because the image may take sometime to load */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100%;
  }
}

.heavenly-power-content {
  height: fit-content;
  width: 100vw;
  font-size: 16px;
}

.heavenly-power-prayers {
  height: 90vh;
  width: 100vw;
}

.heavenly-power-prayer-content {
  position: relative;
  height: calc(100vh - 5.5em);
  width: 100vw;
  font-size: 16px;
}

.heavenly-power-bg {
  position: absolute;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  z-index: -1;
  transform: rotateX("90deg");
}

.heavenly-power-bg-btm {
  position: absolute;
  height: 95vh;
  width: 100vw;
  object-fit: cover;
  z-index: -1;
  transform: rotateY("90deg");
}

/* Whenever the height (or grid-template-rows) of either of `.heavenly-power-context` 
or `.heavenly-power-context-overview` changes, then... 
  1. the height of `heavenly-power-context-body` needs to also change.
  2. the margin of h1 title within `.heavenly-power-context` needs to change.
*/

.heavenly-power-context,
.heavenly-power-context-overview {
  position: relative;
  height: 98vh;
}
.heavenly-power-context {
  display: grid;
  grid-template-rows: 4.5em 26em 2em;
  padding: 5.5em 0.5em 0 0.5em;
}

/* `-overview` to contain the question mark icon */
.heavenly-power-context-overview {
  display: grid;
  grid-template-rows: 4.5em 1.5em 24.5em 2em;
  padding: 5.5em 0.5em 0 0.5em;
}

.heavenly-power-prayers {
  padding: 0.5em 0.5em 0 0.5em;
}

.heavenly-power-context-overview .section-title {
  font-size: 1.7em;
  text-align: center;
  letter-spacing: 0.05em;
  color: black;
  margin-bottom: 0;
}

.heavenly-power-context .section-title {
  font-size: 1.7em;
  text-align: center;
  letter-spacing: 0.05em;
  color: black;
}

.heavenly-power-prayer-section .section-title {
  font-size: 1.7em;
  text-align: center;
  letter-spacing: 0.05em;
  color: black;
  margin-bottom: 0.3em;
}

.heavenly-power-context-body {
  height: 24em;
  overflow-y: scroll;
  position: relative;
  background: rgba(0, 0, 0, 0.05);
  scrollbar-width: thin;
  scrollbar-color: #a41313 transparent;
  scroll-behavior: smooth;
}

.daily-devotion-list {
  padding-left: 1.5em;
  padding-right: 1em;
}
.daily-devotion-list li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: black; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -0.5em; /* Also needed for space (tweak if needed) */
  margin-right: 0em;
}

.daily-devotion-list-elements p {
  display: inline;
  font-style: italic;
  letter-spacing: 0.03em;
  margin: 0;
}

.heavenly-power-context-quote-paragraph {
  margin-top: 0;
  font-style: italic;
  /* font-weight: bold ; */
}

.heavenly-power-context-quote-date {
  text-align: center;
  letter-spacing: 0.08em;
  margin: 0;
}

.heavenly-power-context-btns {
  position: relative;
  margin: 0.5em auto 1em auto;
  /* height of the row in grid-template-rows for parent comp */
  height: 2em;
  width: 95vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0.7em;
  font-size: 16px;
  place-self: center;
}

.hp-context-quotes-btn,
.hp-context-devotion-btn,
.hp-context-overview-btn {
  min-height: 2em;
  max-height: fit-content;
  text-align: center;
}

.hp-context-quotes-btn {
  min-width: 8em;
  width: fit-content;
  border: none;
  background: radial-gradient(#cc0404cc, #880303);
  letter-spacing: 0.08em;
  border-radius: 0.3em;
  color: white;
  padding: 0.25em 0.5em;
}

.hp-context-devotion-btn {
  min-width: 8em;
  width: fit-content;
  border: none;
  background: radial-gradient(#021d23cc, #000000);
  letter-spacing: 0.08em;
  border-radius: 0.3em;
  color: white;
  padding: 0.2em 0.5em;
}

.hp-context-overview-btn {
  min-width: 8em;
  width: fit-content;
  border: none;
  background: radial-gradient(#00958a, #044b47);
  letter-spacing: 0.08em;
  border-radius: 0.3em;
  color: white;
  padding: 0.2em 0.5em;
}

.heavenly-power-context-btns svg {
  width: 9em;
  height: 9em;
  /* left: 50%;
  position: absolute;
  transform: translateX(-50%);
  top: -20%; */
}

.precious-blood-arrow-cont {
  /* overflow-y: visible; */
  height: fit-content;
  width: fit-content;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  /* As the parent comp height is small */
  top: -450%;
}

/* Prayers */
.heavenly-power-prayer-section {
  padding-top: 1em;
  position: relative;
}

.prayer-content {
  height: 24em;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.05);
  scrollbar-width: thin;
  scrollbar-color: #a41313 transparent;
  scroll-behavior: smooth;
}

.prayer-name {
  margin: 0.5em auto 0 auto;
  text-align: center;
  font-style: italic;
  letter-spacing: 0.08em;
}

.prayer-name-mystical {
  margin: 0.5em auto 0 auto;
  text-align: center;
  /* font-style: italic; */
  font-weight: bold;
  font-size: 1.2em;
  letter-spacing: 0.08em;
}

.prayer-quote-Jesus {
  margin: 0.5em;
  font-weight: bold;
  font-style: italic;
}

.prayer-recitation {
  margin: 0 0.5em 0 0.5em;
  text-align: center;
}

.prayer-frequency {
  margin: 0em auto 1.5em auto;
  text-align: center;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 0.08em;
}

.grace-header {
  margin: 0.5em;
  font-weight: bold;
  text-align: center;
}

.grace {
  margin: 0 0.5em 0 0.5em;
  text-align: left;

  font-style: italic;
}

/* Prayer Buttons */
.prayer-buttons {
  font-size: 16px;
  margin: 0.5em auto;
  padding-top: 0.5em;
  padding-bottom: 1em;
  width: 95vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.prayer-btn {
  height: 1.75em;
  width: 1.75em;
  background: radial-gradient(#fe0b0bcc, #630f0f);
  border-radius: 50%;
  text-align: center;
  align-items: center;
  box-shadow: 0 0 4px 3px #9b002c, 0 0 2px 2px black;
  cursor: pointer;
}

.prayer-btn-ang-appeal {
  height: 1.75em;
  width: 1.75em;
  background: radial-gradient(#8a1cbecc, #640491cc, #630f0f);
  border-radius: 50%;
  text-align: center;
  align-items: center;
  box-shadow: 0 0 7px 5px #9b002c, 0 0 3px 2px black;
  cursor: pointer;
}

.s-prayer-btn {
  color: var(--glory-gold-400);
}

.ns-prayer-btn {
  color: white;
}

.prayer-btn-bg {
  height: 2em;
  width: fit-content;
  background: radial-gradient(#fe0b0bcc, #630f0f);
  border-radius: 50%;
  border-radius: 0.05em;
  text-align: center;
  align-items: center;
  box-shadow: 0 0 4px 3px #9b002c, 0 0 2px 2px black;
  cursor: pointer;
}

.prayer-btn-bg-ang-appeal {
  height: 2em;
  width: fit-content;
  background: radial-gradient(#8a1cbecc, #640491cc, #630f0f);
  border-radius: 50%;
  border-radius: 0.05em;
  text-align: center;
  align-items: center;
  box-shadow: 0 0 4px 3px #9b002c, 0 0 2px 2px black;
  cursor: pointer;
}

.prayer-btn-bg:hover {
  color: var(--glory-gold-400);
}
/* Big Screen Classes */

.heavenly-power-context-bg {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100vw;
  height: calc(100vh - 4em);
}

.heavenly-power-bg-context-information {
  position: absolute;
  left: 50%;
  top: 25%;
  transform: translateX(-50%);
  z-index: 100;
}

.hp-context-bg-scrn-1,
.hp-context-bg-scrn-2 {
  position: relative;
  padding-top: 7em;
  padding-left: 2em;
  padding-right: 2em;
  display: grid;
  /* grid-template-rows: 1fr 7fr 2fr; */
  justify-items: center;
  height: 85vh;
}

.hp-context-bg-scrn-1 {
  grid-template-rows: 5em 23em 4em;
}

.hp-context-bg-scrn-2 {
  grid-template-rows: 5em 23em;
}

.hp-context-bg-scrn-1 .section-title,
.hp-context-bg-scrn-2 .section-title {
  font-size: 1.8em;
  text-align: center;
  letter-spacing: 0.05em;
  color: black;
  margin-bottom: 0em;
}

/* Question Mark Icon*/
/* Centers from position on MB, Centers from Grid place: self in BGScrn */
.information-icon {
  background: none;
  border: none;
  /* position: absolute; */
  place-self: center;
  text-align: center;
  /* top: 25%; */
  font-size: 16px;
}
.information-icon svg {
  margin: 0 auto;
  cursor: pointer;
  width: 1.2em;
  height: 1.2em;
}
.icon-bounce svg {
  animation: bounce 2s linear infinite;
}

.prayer-section-info-btn-cont {
  width: 100%;
  display: grid;
  place-items: center;
}
.information-msg-hp {
  position: absolute;
  top: 4.5em;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  width: 16em;
  background: radial-gradient(#fe0b0b, #630f0f);
  font-family: var(--bodyFont);
  border: 2px var(--glory-gold-300) solid;
  border-radius: var(--borderRadius);
  z-index: 100;
  display: grid;
  place-items: center;
  font-size: 16px;
}

.heavenly-power-context-overview .information-msg-hp {
  top: 9em;
}

.information-msg-hp p {
  font-size: 0.7em;
  font-style: italic;
  color: var(--glory-gold-300);
  padding: 1em 1em 0 1em;
  text-align: left;
  margin: 0;
}
.information-icons i,
.information-icons a i,
.information-icons svg,
.information-icons a svg {
  font-size: 0.7em;
  color: white;
  cursor: pointer;
}
.information-icons {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0.7em;
}

@keyframes bounce {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/* End of icon */

@media screen and (max-height: 500px) {
  .heavenly-power-context {
    grid-template-rows: 5fr 27fr 2fr;
  }
  .heavenly-power-context-body {
    height: 100%;
  }

  /* `-overview` to contain the question mark icon */
  .heavenly-power-context-overview {
    grid-template-rows: 5fr 1fr 25fr 2fr;
  }

  .prayer-content {
    height: 50vh;
  }
}

@media screen and (min-width: 390px) and (min-height: 660px) {
  .prayer-content {
    height: 28em;
  }
}

@media screen and (min-height: 700px) {
  .heavenly-power-context {
    grid-template-rows: 5em 27em 2em;
  }

  /* `-overview` to contain the question mark icon */
  .heavenly-power-context-overview {
    grid-template-rows: 5em 1.5em 25.5em 2em;
  }

  /* Prayer */
  .prayer-content {
    height: 27em;
  }

  .prayer-buttons {
    padding-top: 0.75em;
    /* padding-bottom: 1em;
    gap: 1em; */
  }
}

@media screen and (min-height: 800px) {
  .heavenly-power-context {
    grid-template-rows: 5em 30em 2em;
  }

  /* `-overview` to contain the question mark icon */
  .heavenly-power-context-overview {
    grid-template-rows: 5em 1.5em 28.5em 2em;
  }

  .heavenly-power-context-body {
    height: 28.5em;
  }
  .precious-blood-arrow-cont {
    top: -550%;
  }

  /* Prayer */
  .prayer-content {
    height: 33.5em;
  }

  .prayer-buttons {
    padding-top: 0.75em;
    /* padding-bottom: 1em;
    gap: 1em; */
  }

  /* information msg's */
  .information-msg-hp {
    font-size: 17px;
  }
}

@media screen and (min-height: 840px) {
  .heavenly-power-context {
    grid-template-rows: 5em 32em 2em;
  }

  /* `-overview` to contain the question mark icon */
  .heavenly-power-context-overview {
    grid-template-rows: 5em 1.5em 30.5em 2em;
  }

  .heavenly-power-context-body {
    height: 30.5em;
  }

  /* Prayer */
  .prayer-content {
    height: 35em;
  }
}

@media screen and (min-height: 870px) {
  .heavenly-power-context {
    grid-template-rows: 5em 32em 2em;
  }

  /* `-overview` to contain the question mark icon */
  .heavenly-power-context-overview {
    grid-template-rows: 5em 1.5em 30.5em 2em;
  }

  .heavenly-power-context-body {
    height: 30.5em;
  }

  /* Prayer */
  .prayer-content {
    height: 37.5em;
  }

  .prayer-buttons {
    padding-top: 0.75em;
    /* padding-bottom: 1em;
    gap: 1em; */
  }
}

@media screen and (min-height: 900px) {
  .heavenly-power-context .section-title,
  .heavenly-power-context-overview .section-title {
    font-size: 2em;
  }

  .heavenly-power-prayer-section .section-title {
    font-size: 2em;
    margin-bottom: 0.3em;
  }
  /* context */
  .heavenly-power-context {
    grid-template-rows: 5em 35em 2em;
  }

  /* `-overview` to contain the question mark icon */
  .heavenly-power-context-overview {
    grid-template-rows: 5em 2em 33em 2em;
  }

  .heavenly-power-context-body {
    height: 33em;
  }
  .heavenly-power-context-btns {
    font-size: 18px;
  }

  /* Prayer */
  .prayer-content {
    height: 35em;
  }

  .prayer-buttons {
    padding-top: 0.75em;
    font-size: 17px;
  }

  /* information msg's */
  .information-msg-hp {
    font-size: 18px;
  }
}

@media screen and (min-width: 600px) and (min-height: 500px) {
  .heavenly-power-bg {
    transform: rotateX("0deg");
  }

  /* Prayer */
  .prayer-content {
    height: 37em;
  }

  .prayer-buttons {
    padding-top: 0.75em;
    font-size: 18px;
  }
}

@media screen and (min-width: 700px) and (max-aspect-ratio: 1/1) {
  .heavenly-power-content,
  .heavenly-power-prayer-content {
    font-size: 19px;
  }
  .precious-blood-arrow-cont {
    top: -600%;
  }
  .heavenly-power-context-btns {
    font-size: 19px;
  }
  .prayer-buttons {
    font-size: 19px;
  }
  .information-icon {
    font-size: 17px;
  }

  .information-msg-hp {
    font-size: 21px;
  }

  .heavenly-power-context-paragraph,
  .heavenly-power-context-quote-paragraph,
  .heavenly-power-context-quote-date {
    font-size: 1.3em;
  }
}

@media screen and (min-width: 800px) and (max-aspect-ratio: 1/1) {
  .heavenly-power-content,
  .heavenly-power-prayer-content {
    font-size: 20px;
  }
  .heavenly-power-context {
    grid-template-rows: 6em 37em 6em;
  }
  .heavenly-power-context-overview {
    grid-template-rows: 4em 2em 35em 6em;
  }
  .heavenly-power-context-body {
    height: 35em;
  }

  .heavenly-power-context-btns {
    place-self: center;
    font-size: 22px;
  }
  .precious-blood-arrow-cont {
    top: -700%;
  }
  .prayer-buttons {
    font-size: 25px;
  }
  .information-icon {
    font-size: 18px;
  }

  .information-msg-hp {
    font-size: 22px;
  }
}

@media screen and (min-width: 1000px) and (max-aspect-ratio: 1/1) {
  .heavenly-power-content,
  .heavenly-power-prayer-content {
    font-size: 23px;
  }
  .heavenly-power-context-paragraph,
  .heavenly-power-context-quote-paragraph,
  .heavenly-power-context-quote-date {
    font-size: 1.4em;
  }

  .heavenly-power-context-overview {
    grid-template-rows: 4em 2em 33em 2em;
  }
  .heavenly-power-context-btns {
    font-size: 26px;
    gap: 2em;
  }
  .prayer-buttons {
    font-size: 27px;
  }
  .information-icon {
    font-size: 20px;
  }

  .information-msg-hp {
    font-size: 26px;
  }
}

@media screen and (min-width: 1200px) {
  .precious-blood-arrow-cont {
    display: none;
  }
  .heavenly-power-content {
    font-size: 18px;
  }

  .heavenly-power-bg-context-information .information-msg-hp {
    top: 1.5em;
  }

  .heavenly-power-context-paragraph,
  .heavenly-power-context-quote-paragraph {
    font-weight: bold;
  }

  .heavenly-power-context-body {
    height: 23em;
  }

  .hp-context-bg-scrn-1 .heavenly-power-context-body {
    align-self: start;
  }

  .hp-context-bg-scrn-2 .heavenly-power-context-body {
    align-self: start;
    height: 33em;
    height: 100%;
  }

  .hp-context-quotes-btn,
  .hp-context-devotion-btn,
  .hp-context-overview-btn {
    height: 2em;
    place-self: center;
  }
  .information-icon {
    font-size: 14px;
  }

  .prayer-content {
    height: 33em;
    padding: 0em 5em;
  }
  .prayer-btn-bg {
    padding: 0.1em 0.2em;
  }
  .prayer-buttons {
    font-size: 12px;
  }
}

@media screen and (min-width: 1500px) {
  .hp-context-bg-scrn-1 {
    grid-template-rows: 5em 29em 4em;
  }
  .heavenly-power-context-body {
    height: 29em;
  }

  .hp-context-bg-scrn-2 {
    grid-template-rows: 5em 29em;
  }

  .prayer-content {
    height: 38em;
  }
  .prayer-buttons {
    font-size: 15px;
  }
}

@media screen and (min-width: 1800px) {
  .heavenly-power-content,
  .heavenly-power-prayer-content {
    font-size: 20px;
  }
  .information-icon {
    font-size: 16px;
  }
  .information-msg-hp {
    font-size: 26px;
  }
  .hp-context-quotes-btn,
  .hp-context-devotion-btn,
  .hp-context-overview-btn {
    font-size: 26px;
  }

  .prayer-buttons {
    font-size: 18px;
  }
}

@media screen and (min-width: 2100px) {
  .heavenly-power-content,
  .heavenly-power-prayer-content {
    font-size: 28px;
  }
  .hp-context-bg-scrn-1 {
    grid-template-rows: 5em 31em 4em;
  }
  .heavenly-power-context-body {
    height: 31em;
  }

  .hp-context-bg-scrn-2 {
    grid-template-rows: 5em 31em;
  }
  .information-icon {
    font-size: 22px;
  }
  .information-msg-hp {
    font-size: 33px;
  }
  .hp-context-quotes-btn,
  .hp-context-devotion-btn,
  .hp-context-overview-btn {
    font-size: 30px;
  }

  .prayer-buttons {
    font-size: 23px;
    padding-top: 2em;
  }
}

@media screen and (min-width: 2300px) {
  .information-icon {
    font-size: 22px;
  }
  .information-msg-hp {
    font-size: 33px;
  }
  .hp-context-quotes-btn,
  .hp-context-devotion-btn,
  .hp-context-overview-btn {
    font-size: 30px;
  }
  .prayer-content {
    height: 35em;
  }
  .prayer-buttons {
    font-size: 24px;
  }
}

@media screen and (min-width: 2600px) {
  .heavenly-power-content,
  .heavenly-power-prayer-content {
    font-size: 33px;
  }
  .prayer-content {
    height: 40em;
  }
  .prayer-buttons {
    font-size: 27px;
    padding-top: 1.5em;
  }
}

@media screen and (min-width: 3500px) {
  .heavenly-power-content,
  .heavenly-power-prayer-content {
    font-size: 38px;
  }
  .information-icon {
    font-size: 30px;
  }
  .information-msg-hp {
    font-size: 47px;
  }
  .prayer-content {
    height: 40em;
  }
  .prayer-buttons {
    font-size: 35px;
    padding-top: 1.5em;
  }
  .hp-context-quotes-btn,
  .hp-context-devotion-btn,
  .hp-context-overview-btn {
    font-size: 40px;
  }

  .hp-context-bg-scrn-1 {
    grid-template-rows: 5em 34em 4em;
  }
  .heavenly-power-context-body {
    height: 34em;
  }

  .hp-context-bg-scrn-2 {
    grid-template-rows: 5em 34em;
  }
}
