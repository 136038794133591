#e5C1BF0Vem32_ts {
  animation: e5C1BF0Vem32_ts__ts 3000ms linear 1 normal forwards;
}
@keyframes e5C1BF0Vem32_ts__ts {
  0% {
    transform: translate(1700px, 76.386222px) scale(0, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  40% {
    transform: translate(1700px, 76.386222px) scale(0.96, 1);
  }
  100% {
    transform: translate(1700px, 76.386222px) scale(0.96, 1);
  }
}
#e5C1BF0Vem32 {
  animation: e5C1BF0Vem32_c_o 3000ms linear 1 normal forwards;
}
@keyframes e5C1BF0Vem32_c_o {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  73.333333% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#e5C1BF0Vem33 {
  animation: e5C1BF0Vem33_c_o 3000ms linear 1 normal forwards;
}
@keyframes e5C1BF0Vem33_c_o {
  0% {
    opacity: 1;
  }
  66.666667% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#e5C1BF0Vem34 {
  animation: e5C1BF0Vem34_s_do 3000ms linear 1 normal forwards;
}
@keyframes e5C1BF0Vem34_s_do {
  0% {
    stroke-dashoffset: 1764.052813;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  40% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
#e5C1BF0Vem35 {
  animation: e5C1BF0Vem35_s_do 3000ms linear 1 normal forwards;
}
@keyframes e5C1BF0Vem35_s_do {
  0% {
    stroke-dashoffset: 1764.052813;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  40% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
#e5C1BF0Vem36 {
  animation: e5C1BF0Vem36_s_do 3000ms linear 1 normal forwards;
}
@keyframes e5C1BF0Vem36_s_do {
  0% {
    stroke-dashoffset: 1769.554141;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  40% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
#e5C1BF0Vem37 {
  animation: e5C1BF0Vem37_s_do 3000ms linear 1 normal forwards;
}
@keyframes e5C1BF0Vem37_s_do {
  0% {
    stroke-dashoffset: 1769.304229;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  40% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
#e5C1BF0Vem38_to {
  animation: e5C1BF0Vem38_to__to 3000ms linear 1 normal forwards;
}
@keyframes e5C1BF0Vem38_to__to {
  0% {
    transform: translate(1700px, 75px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  40% {
    transform: translate(75px, 75px);
  }
  100% {
    transform: translate(75px, 75px);
  }
}
#e5C1BF0Vem38_tr {
  animation: e5C1BF0Vem38_tr__tr 3000ms linear 1 normal forwards;
}
@keyframes e5C1BF0Vem38_tr__tr {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
#e5C1BF0Vem310 {
  animation-name: e5C1BF0Vem310_f_p, e5C1BF0Vem310_s_p;
  animation-duration: 3000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: 1;
}
@keyframes e5C1BF0Vem310_f_p {
  0% {
    fill: #f7c312;
  }
  66.666667% {
    fill: #afa078;
  }
  100% {
    fill: #afa078;
  }
}
@keyframes e5C1BF0Vem310_s_p {
  0% {
    stroke: #f7c312;
  }
  66.666667% {
    stroke: #afa078;
  }
  100% {
    stroke: #afa078;
  }
}
#e5C1BF0Vem311 {
  animation-name: e5C1BF0Vem311_f_p, e5C1BF0Vem311_s_p;
  animation-duration: 3000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: 1;
}
@keyframes e5C1BF0Vem311_f_p {
  0% {
    fill: #f7c312;
  }
  66.666667% {
    fill: #afa078;
  }
  100% {
    fill: #afa078;
  }
}
@keyframes e5C1BF0Vem311_s_p {
  0% {
    stroke: #f7c312;
  }
  66.666667% {
    stroke: #afa078;
  }
  100% {
    stroke: #afa078;
  }
}
