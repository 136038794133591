#eV2ksJzphy75 {
  animation: eV2ksJzphy75_c_o 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy75_c_o {
  0% {
    opacity: 0;
  }
  12.5% {
    opacity: 1;
  }
  18.75% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
#eV2ksJzphy719 {
  animation: eV2ksJzphy719_c_o 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy719_c_o {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  37.5% {
    opacity: 1;
  }
  43.75% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
#eV2ksJzphy7103 {
  animation: eV2ksJzphy7103_c_o 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7103_c_o {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  87.5% {
    opacity: 1;
  }
  93.75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#eV2ksJzphy7119 {
  animation: eV2ksJzphy7119_c_o 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7119_c_o {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  62.5% {
    opacity: 1;
  }
  68.75% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
#eV2ksJzphy7159 {
  animation: eV2ksJzphy7159_c_o 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7159_c_o {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.6;
  }
  56.25% {
    opacity: 1;
  }
  68.75% {
    opacity: 1;
  }
  75% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.6;
  }
}
#eV2ksJzphy7165 {
  animation: eV2ksJzphy7165_c_o 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7165_c_o {
  0% {
    opacity: 0.6;
  }
  75% {
    opacity: 0.6;
  }
  81.25% {
    opacity: 1;
  }
  93.75% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
#eV2ksJzphy7171 {
  animation: eV2ksJzphy7171_c_o 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7171_c_o {
  0% {
    opacity: 0.6;
  }
  12.5% {
    opacity: 1;
  }
  18.75% {
    opacity: 1;
  }
  25% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.6;
  }
}
#eV2ksJzphy7177 {
  animation: eV2ksJzphy7177_c_o 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7177_c_o {
  0% {
    opacity: 0.6;
  }
  25% {
    opacity: 0.6;
  }
  31.25% {
    opacity: 1;
  }
  43.75% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.6;
  }
}
#eV2ksJzphy7207 {
  animation: eV2ksJzphy7207_c_o 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7207_c_o {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.7;
  }
  56.25% {
    opacity: 1;
  }
  68.75% {
    opacity: 1;
  }
  75% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.7;
  }
}
#eV2ksJzphy7213 {
  animation: eV2ksJzphy7213_c_o 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7213_c_o {
  0% {
    opacity: 0.7;
  }
  75% {
    opacity: 0.7;
  }
  81.25% {
    opacity: 1;
  }
  93.75% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}
#eV2ksJzphy7219 {
  animation: eV2ksJzphy7219_c_o 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7219_c_o {
  0% {
    opacity: 0.7;
  }
  12.5% {
    opacity: 1;
  }
  18.75% {
    opacity: 1;
  }
  25% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.7;
  }
}
#eV2ksJzphy7225 {
  animation: eV2ksJzphy7225_c_o 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7225_c_o {
  0% {
    opacity: 0.7;
  }
  25% {
    opacity: 0.7;
  }
  31.25% {
    opacity: 1;
  }
  43.75% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.7;
  }
}
#eV2ksJzphy7240 {
  animation: eV2ksJzphy7240_s_p 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7240_s_p {
  0% {
    stroke: #fff;
  }
  12.5% {
    stroke: #05b8cc;
  }
  18.75% {
    stroke: #05b8cc;
  }
  25% {
    stroke: #fff;
  }
  31.25% {
    stroke: #20bf9f;
  }
  43.75% {
    stroke: #20bf9f;
  }
  50% {
    stroke: #fff;
  }
  56.25% {
    stroke: #ff0606;
  }
  68.75% {
    stroke: #ff0606;
  }
  75% {
    stroke: #fff;
  }
  81.25% {
    stroke: #fed800;
  }
  93.75% {
    stroke: #fed800;
  }
  100% {
    stroke: #fff;
  }
}
#eV2ksJzphy7241 {
  animation: eV2ksJzphy7241_c_o 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7241_c_o {
  0% {
    opacity: 0;
  }
  6.25% {
    opacity: 1;
  }
  18.75% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  31.25% {
    opacity: 1;
  }
  43.75% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  56.25% {
    opacity: 1;
  }
  68.75% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  81.25% {
    opacity: 1;
  }
  93.75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#eV2ksJzphy7242-fill-0 {
  animation: eV2ksJzphy7242-fill-0__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7242-fill-0__c {
  0% {
    stop-color: #05b8cc;
  }
  12.5% {
    stop-color: #05b8cc;
  }
  18.75% {
    stop-color: #05b8cc;
  }
  37.5% {
    stop-color: #20bf9f;
  }
  43.75% {
    stop-color: #20bf9f;
  }
  62.5% {
    stop-color: #ff0606;
  }
  68.75% {
    stop-color: #ff0606;
  }
  87.5% {
    stop-color: #fed800;
  }
  93.75% {
    stop-color: #fed800;
  }
  100% {
    stop-color: #fed800;
  }
}
#eV2ksJzphy7242-fill-1 {
  animation: eV2ksJzphy7242-fill-1__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7242-fill-1__c {
  0% {
    stop-color: #000;
  }
  12.5% {
    stop-color: #000;
  }
  18.75% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  43.75% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  68.75% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  93.75% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#eV2ksJzphy7242 {
  animation: eV2ksJzphy7242_s_p 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7242_s_p {
  0% {
    stroke: #05b8cc;
  }
  12.5% {
    stroke: #05b8cc;
  }
  18.75% {
    stroke: #05b8cc;
  }
  37.5% {
    stroke: #20bf9f;
  }
  43.75% {
    stroke: #20bf9f;
  }
  62.5% {
    stroke: #ff0606;
  }
  68.75% {
    stroke: #ff0606;
  }
  87.5% {
    stroke: #fed800;
  }
  93.75% {
    stroke: #fed800;
  }
  100% {
    stroke: #fed800;
  }
}
#eV2ksJzphy7243-fill-0 {
  animation: eV2ksJzphy7243-fill-0__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7243-fill-0__c {
  0% {
    stop-color: #05b8cc;
  }
  12.5% {
    stop-color: #05b8cc;
  }
  18.75% {
    stop-color: #05b8cc;
  }
  37.5% {
    stop-color: #20bf9f;
  }
  43.75% {
    stop-color: #20bf9f;
  }
  62.5% {
    stop-color: #ff0606;
  }
  68.75% {
    stop-color: #ff0606;
  }
  87.5% {
    stop-color: #fed800;
  }
  93.75% {
    stop-color: #fed800;
  }
  100% {
    stop-color: #fed800;
  }
}
#eV2ksJzphy7243-fill-1 {
  animation: eV2ksJzphy7243-fill-1__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7243-fill-1__c {
  0% {
    stop-color: #000;
  }
  12.5% {
    stop-color: #000;
  }
  18.75% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  43.75% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  68.75% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  93.75% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#eV2ksJzphy7243 {
  animation: eV2ksJzphy7243_s_p 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7243_s_p {
  0% {
    stroke: #05b8cc;
  }
  12.5% {
    stroke: #05b8cc;
  }
  18.75% {
    stroke: #05b8cc;
  }
  37.5% {
    stroke: #20bf9f;
  }
  43.75% {
    stroke: #20bf9f;
  }
  62.5% {
    stroke: #ff0606;
  }
  68.75% {
    stroke: #ff0606;
  }
  87.5% {
    stroke: #fed800;
  }
  93.75% {
    stroke: #fed800;
  }
  100% {
    stroke: #fed800;
  }
}
#eV2ksJzphy7245-fill-0 {
  animation: eV2ksJzphy7245-fill-0__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7245-fill-0__c {
  0% {
    stop-color: #05b8cc;
  }
  12.5% {
    stop-color: #05b8cc;
  }
  18.75% {
    stop-color: #05b8cc;
  }
  37.5% {
    stop-color: #20bf9f;
  }
  43.75% {
    stop-color: #20bf9f;
  }
  62.5% {
    stop-color: #ff0606;
  }
  68.75% {
    stop-color: #ff0606;
  }
  87.5% {
    stop-color: #fed800;
  }
  93.75% {
    stop-color: #fed800;
  }
  100% {
    stop-color: #fed800;
  }
}
#eV2ksJzphy7245-fill-1 {
  animation: eV2ksJzphy7245-fill-1__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7245-fill-1__c {
  0% {
    stop-color: #000;
  }
  12.5% {
    stop-color: #000;
  }
  18.75% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  43.75% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  68.75% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  93.75% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#eV2ksJzphy7245 {
  animation: eV2ksJzphy7245_s_p 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7245_s_p {
  0% {
    stroke: #05b8cc;
  }
  12.5% {
    stroke: #05b8cc;
  }
  18.75% {
    stroke: #05b8cc;
  }
  37.5% {
    stroke: #20bf9f;
  }
  43.75% {
    stroke: #20bf9f;
  }
  62.5% {
    stroke: #ff0606;
  }
  68.75% {
    stroke: #ff0606;
  }
  87.5% {
    stroke: #fed800;
  }
  93.75% {
    stroke: #fed800;
  }
  100% {
    stroke: #fed800;
  }
}
#eV2ksJzphy7246-fill-0 {
  animation: eV2ksJzphy7246-fill-0__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7246-fill-0__c {
  0% {
    stop-color: #05b8cc;
  }
  12.5% {
    stop-color: #05b8cc;
  }
  18.75% {
    stop-color: #05b8cc;
  }
  37.5% {
    stop-color: #20bf9f;
  }
  43.75% {
    stop-color: #20bf9f;
  }
  62.5% {
    stop-color: #ff0606;
  }
  68.75% {
    stop-color: #ff0606;
  }
  87.5% {
    stop-color: #fed800;
  }
  93.75% {
    stop-color: #fed800;
  }
  100% {
    stop-color: #fed800;
  }
}
#eV2ksJzphy7246-fill-1 {
  animation: eV2ksJzphy7246-fill-1__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7246-fill-1__c {
  0% {
    stop-color: #000;
  }
  12.5% {
    stop-color: #000;
  }
  18.75% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  43.75% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  68.75% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  93.75% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#eV2ksJzphy7246 {
  animation: eV2ksJzphy7246_s_p 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7246_s_p {
  0% {
    stroke: #05b8cc;
  }
  12.5% {
    stroke: #05b8cc;
  }
  18.75% {
    stroke: #05b8cc;
  }
  37.5% {
    stroke: #20bf9f;
  }
  43.75% {
    stroke: #20bf9f;
  }
  62.5% {
    stroke: #ff0606;
  }
  68.75% {
    stroke: #ff0606;
  }
  87.5% {
    stroke: #fed800;
  }
  93.75% {
    stroke: #fed800;
  }
  100% {
    stroke: #fed800;
  }
}
#eV2ksJzphy7248-fill-0 {
  animation: eV2ksJzphy7248-fill-0__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7248-fill-0__c {
  0% {
    stop-color: #05b8cc;
  }
  12.5% {
    stop-color: #05b8cc;
  }
  18.75% {
    stop-color: #05b8cc;
  }
  37.5% {
    stop-color: #20bf9f;
  }
  43.75% {
    stop-color: #20bf9f;
  }
  62.5% {
    stop-color: #ff0606;
  }
  68.75% {
    stop-color: #ff0606;
  }
  87.5% {
    stop-color: #fed800;
  }
  93.75% {
    stop-color: #fed800;
  }
  100% {
    stop-color: #fed800;
  }
}
#eV2ksJzphy7248-fill-1 {
  animation: eV2ksJzphy7248-fill-1__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7248-fill-1__c {
  0% {
    stop-color: #000;
  }
  12.5% {
    stop-color: #000;
  }
  18.75% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  43.75% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  68.75% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  93.75% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#eV2ksJzphy7248 {
  animation: eV2ksJzphy7248_s_p 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7248_s_p {
  0% {
    stroke: #05b8cc;
  }
  12.5% {
    stroke: #05b8cc;
  }
  18.75% {
    stroke: #05b8cc;
  }
  37.5% {
    stroke: #20bf9f;
  }
  43.75% {
    stroke: #20bf9f;
  }
  62.5% {
    stroke: #ff0606;
  }
  68.75% {
    stroke: #ff0606;
  }
  87.5% {
    stroke: #fed800;
  }
  93.75% {
    stroke: #fed800;
  }
  100% {
    stroke: #fed800;
  }
}
#eV2ksJzphy7249-fill-0 {
  animation: eV2ksJzphy7249-fill-0__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7249-fill-0__c {
  0% {
    stop-color: #05b8cc;
  }
  12.5% {
    stop-color: #05b8cc;
  }
  18.75% {
    stop-color: #05b8cc;
  }
  37.5% {
    stop-color: #20bf9f;
  }
  43.75% {
    stop-color: #20bf9f;
  }
  62.5% {
    stop-color: #ff0606;
  }
  68.75% {
    stop-color: #ff0606;
  }
  87.5% {
    stop-color: #fed800;
  }
  93.75% {
    stop-color: #fed800;
  }
  100% {
    stop-color: #fed800;
  }
}
#eV2ksJzphy7249-fill-1 {
  animation: eV2ksJzphy7249-fill-1__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7249-fill-1__c {
  0% {
    stop-color: #000;
  }
  12.5% {
    stop-color: #000;
  }
  18.75% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  43.75% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  68.75% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  93.75% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#eV2ksJzphy7249 {
  animation: eV2ksJzphy7249_s_p 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7249_s_p {
  0% {
    stroke: #05b8cc;
  }
  12.5% {
    stroke: #05b8cc;
  }
  18.75% {
    stroke: #05b8cc;
  }
  37.5% {
    stroke: #20bf9f;
  }
  43.75% {
    stroke: #20bf9f;
  }
  62.5% {
    stroke: #ff0606;
  }
  68.75% {
    stroke: #ff0606;
  }
  87.5% {
    stroke: #fed800;
  }
  93.75% {
    stroke: #fed800;
  }
  100% {
    stroke: #fed800;
  }
}
#eV2ksJzphy7251-fill-0 {
  animation: eV2ksJzphy7251-fill-0__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7251-fill-0__c {
  0% {
    stop-color: #05b8cc;
  }
  12.5% {
    stop-color: #05b8cc;
  }
  18.75% {
    stop-color: #05b8cc;
  }
  37.5% {
    stop-color: #20bf9f;
  }
  43.75% {
    stop-color: #20bf9f;
  }
  62.5% {
    stop-color: #ff0606;
  }
  68.75% {
    stop-color: #ff0606;
  }
  87.5% {
    stop-color: #fed800;
  }
  93.75% {
    stop-color: #fed800;
  }
  100% {
    stop-color: #fed800;
  }
}
#eV2ksJzphy7251-fill-1 {
  animation: eV2ksJzphy7251-fill-1__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7251-fill-1__c {
  0% {
    stop-color: #000;
  }
  12.5% {
    stop-color: #000;
  }
  18.75% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  43.75% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  68.75% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  93.75% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#eV2ksJzphy7251 {
  animation: eV2ksJzphy7251_s_p 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7251_s_p {
  0% {
    stroke: #05b8cc;
  }
  12.5% {
    stroke: #05b8cc;
  }
  18.75% {
    stroke: #05b8cc;
  }
  37.5% {
    stroke: #20bf9f;
  }
  43.75% {
    stroke: #20bf9f;
  }
  62.5% {
    stroke: #ff0606;
  }
  68.75% {
    stroke: #ff0606;
  }
  87.5% {
    stroke: #fed800;
  }
  93.75% {
    stroke: #fed800;
  }
  100% {
    stroke: #fed800;
  }
}
#eV2ksJzphy7252-fill-0 {
  animation: eV2ksJzphy7252-fill-0__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7252-fill-0__c {
  0% {
    stop-color: #05b8cc;
  }
  12.5% {
    stop-color: #05b8cc;
  }
  18.75% {
    stop-color: #05b8cc;
  }
  37.5% {
    stop-color: #20bf9f;
  }
  43.75% {
    stop-color: #20bf9f;
  }
  62.5% {
    stop-color: #ff0606;
  }
  68.75% {
    stop-color: #ff0606;
  }
  87.5% {
    stop-color: #fed800;
  }
  93.75% {
    stop-color: #fed800;
  }
  100% {
    stop-color: #fed800;
  }
}
#eV2ksJzphy7252-fill-1 {
  animation: eV2ksJzphy7252-fill-1__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7252-fill-1__c {
  0% {
    stop-color: #000;
  }
  12.5% {
    stop-color: #000;
  }
  18.75% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  43.75% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  68.75% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  93.75% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#eV2ksJzphy7252 {
  animation: eV2ksJzphy7252_s_p 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7252_s_p {
  0% {
    stroke: #05b8cc;
  }
  12.5% {
    stroke: #05b8cc;
  }
  18.75% {
    stroke: #05b8cc;
  }
  37.5% {
    stroke: #20bf9f;
  }
  43.75% {
    stroke: #20bf9f;
  }
  62.5% {
    stroke: #ff0606;
  }
  68.75% {
    stroke: #ff0606;
  }
  87.5% {
    stroke: #fed800;
  }
  93.75% {
    stroke: #fed800;
  }
  100% {
    stroke: #fed800;
  }
}
#eV2ksJzphy7254-fill-0 {
  animation: eV2ksJzphy7254-fill-0__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7254-fill-0__c {
  0% {
    stop-color: #05b8cc;
  }
  12.5% {
    stop-color: #05b8cc;
  }
  18.75% {
    stop-color: #05b8cc;
  }
  37.5% {
    stop-color: #20bf9f;
  }
  43.75% {
    stop-color: #20bf9f;
  }
  62.5% {
    stop-color: #ff0606;
  }
  68.75% {
    stop-color: #ff0606;
  }
  87.5% {
    stop-color: #fed800;
  }
  93.75% {
    stop-color: #fed800;
  }
  100% {
    stop-color: #fed800;
  }
}
#eV2ksJzphy7254-fill-1 {
  animation: eV2ksJzphy7254-fill-1__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7254-fill-1__c {
  0% {
    stop-color: #000;
  }
  12.5% {
    stop-color: #000;
  }
  18.75% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  43.75% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  68.75% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  93.75% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#eV2ksJzphy7254 {
  animation: eV2ksJzphy7254_s_p 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7254_s_p {
  0% {
    stroke: #05b8cc;
  }
  12.5% {
    stroke: #05b8cc;
  }
  18.75% {
    stroke: #05b8cc;
  }
  37.5% {
    stroke: #20bf9f;
  }
  43.75% {
    stroke: #20bf9f;
  }
  62.5% {
    stroke: #ff0606;
  }
  68.75% {
    stroke: #ff0606;
  }
  87.5% {
    stroke: #fed800;
  }
  93.75% {
    stroke: #fed800;
  }
  100% {
    stroke: #fed800;
  }
}
#eV2ksJzphy7255-fill-0 {
  animation: eV2ksJzphy7255-fill-0__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7255-fill-0__c {
  0% {
    stop-color: #05b8cc;
  }
  12.5% {
    stop-color: #05b8cc;
  }
  18.75% {
    stop-color: #05b8cc;
  }
  37.5% {
    stop-color: #20bf9f;
  }
  43.75% {
    stop-color: #20bf9f;
  }
  62.5% {
    stop-color: #ff0606;
  }
  68.75% {
    stop-color: #ff0606;
  }
  87.5% {
    stop-color: #fed800;
  }
  93.75% {
    stop-color: #fed800;
  }
  100% {
    stop-color: #fed800;
  }
}
#eV2ksJzphy7255-fill-1 {
  animation: eV2ksJzphy7255-fill-1__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7255-fill-1__c {
  0% {
    stop-color: #000;
  }
  12.5% {
    stop-color: #000;
  }
  18.75% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  43.75% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  68.75% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  93.75% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#eV2ksJzphy7255 {
  animation: eV2ksJzphy7255_s_p 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7255_s_p {
  0% {
    stroke: #05b8cc;
  }
  12.5% {
    stroke: #05b8cc;
  }
  18.75% {
    stroke: #05b8cc;
  }
  37.5% {
    stroke: #20bf9f;
  }
  43.75% {
    stroke: #20bf9f;
  }
  62.5% {
    stroke: #ff0606;
  }
  68.75% {
    stroke: #ff0606;
  }
  87.5% {
    stroke: #fed800;
  }
  93.75% {
    stroke: #fed800;
  }
  100% {
    stroke: #fed800;
  }
}
#eV2ksJzphy7256-fill-0 {
  animation: eV2ksJzphy7256-fill-0__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7256-fill-0__c {
  0% {
    stop-color: #05b8cc;
  }
  12.5% {
    stop-color: #05b8cc;
  }
  18.75% {
    stop-color: #05b8cc;
  }
  37.5% {
    stop-color: #20bf9f;
  }
  43.75% {
    stop-color: #20bf9f;
  }
  62.5% {
    stop-color: #ff0606;
  }
  68.75% {
    stop-color: #ff0606;
  }
  87.5% {
    stop-color: #fed800;
  }
  93.75% {
    stop-color: #fed800;
  }
  100% {
    stop-color: #fed800;
  }
}
#eV2ksJzphy7256-fill-1 {
  animation: eV2ksJzphy7256-fill-1__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7256-fill-1__c {
  0% {
    stop-color: #000;
  }
  12.5% {
    stop-color: #000;
  }
  18.75% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  43.75% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  68.75% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  93.75% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#eV2ksJzphy7256 {
  animation: eV2ksJzphy7256_s_p 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7256_s_p {
  0% {
    stroke: #05b8cc;
  }
  12.5% {
    stroke: #05b8cc;
  }
  18.75% {
    stroke: #05b8cc;
  }
  37.5% {
    stroke: #20bf9f;
  }
  43.75% {
    stroke: #20bf9f;
  }
  62.5% {
    stroke: #ff0606;
  }
  68.75% {
    stroke: #ff0606;
  }
  87.5% {
    stroke: #fed800;
  }
  93.75% {
    stroke: #fed800;
  }
  100% {
    stroke: #fed800;
  }
}
#eV2ksJzphy7257-fill-0 {
  animation: eV2ksJzphy7257-fill-0__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7257-fill-0__c {
  0% {
    stop-color: #05b8cc;
  }
  12.5% {
    stop-color: #05b8cc;
  }
  18.75% {
    stop-color: #05b8cc;
  }
  37.5% {
    stop-color: #20bf9f;
  }
  43.75% {
    stop-color: #20bf9f;
  }
  62.5% {
    stop-color: #ff0606;
  }
  68.75% {
    stop-color: #ff0606;
  }
  87.5% {
    stop-color: #fed800;
  }
  93.75% {
    stop-color: #fed800;
  }
  100% {
    stop-color: #fed800;
  }
}
#eV2ksJzphy7257-fill-1 {
  animation: eV2ksJzphy7257-fill-1__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7257-fill-1__c {
  0% {
    stop-color: #000;
  }
  12.5% {
    stop-color: #000;
  }
  18.75% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  43.75% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  68.75% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  93.75% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#eV2ksJzphy7257 {
  animation: eV2ksJzphy7257_s_p 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7257_s_p {
  0% {
    stroke: #05b8cc;
  }
  12.5% {
    stroke: #05b8cc;
  }
  18.75% {
    stroke: #05b8cc;
  }
  37.5% {
    stroke: #20bf9f;
  }
  43.75% {
    stroke: #20bf9f;
  }
  62.5% {
    stroke: #ff0606;
  }
  68.75% {
    stroke: #ff0606;
  }
  87.5% {
    stroke: #fed800;
  }
  93.75% {
    stroke: #fed800;
  }
  100% {
    stroke: #fed800;
  }
}
#eV2ksJzphy7258-fill-0 {
  animation: eV2ksJzphy7258-fill-0__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7258-fill-0__c {
  0% {
    stop-color: #05b8cc;
  }
  12.5% {
    stop-color: #05b8cc;
  }
  18.75% {
    stop-color: #05b8cc;
  }
  37.5% {
    stop-color: #20bf9f;
  }
  43.75% {
    stop-color: #20bf9f;
  }
  62.5% {
    stop-color: #ff0606;
  }
  68.75% {
    stop-color: #ff0606;
  }
  87.5% {
    stop-color: #fed800;
  }
  93.75% {
    stop-color: #fed800;
  }
  100% {
    stop-color: #fed800;
  }
}
#eV2ksJzphy7258-fill-1 {
  animation: eV2ksJzphy7258-fill-1__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7258-fill-1__c {
  0% {
    stop-color: #000;
  }
  12.5% {
    stop-color: #000;
  }
  18.75% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  43.75% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  68.75% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  93.75% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#eV2ksJzphy7258 {
  animation: eV2ksJzphy7258_s_p 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7258_s_p {
  0% {
    stroke: #05b8cc;
  }
  12.5% {
    stroke: #05b8cc;
  }
  18.75% {
    stroke: #05b8cc;
  }
  37.5% {
    stroke: #20bf9f;
  }
  43.75% {
    stroke: #20bf9f;
  }
  62.5% {
    stroke: #ff0606;
  }
  68.75% {
    stroke: #ff0606;
  }
  87.5% {
    stroke: #fed800;
  }
  93.75% {
    stroke: #fed800;
  }
  100% {
    stroke: #fed800;
  }
}
#eV2ksJzphy7259-fill-0 {
  animation: eV2ksJzphy7259-fill-0__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7259-fill-0__c {
  0% {
    stop-color: #05b8cc;
  }
  12.5% {
    stop-color: #05b8cc;
  }
  18.75% {
    stop-color: #05b8cc;
  }
  37.5% {
    stop-color: #20bf9f;
  }
  43.75% {
    stop-color: #20bf9f;
  }
  62.5% {
    stop-color: #ff0606;
  }
  68.75% {
    stop-color: #ff0606;
  }
  87.5% {
    stop-color: #fed800;
  }
  93.75% {
    stop-color: #fed800;
  }
  100% {
    stop-color: #fed800;
  }
}
#eV2ksJzphy7259-fill-1 {
  animation: eV2ksJzphy7259-fill-1__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7259-fill-1__c {
  0% {
    stop-color: #000;
  }
  12.5% {
    stop-color: #000;
  }
  18.75% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  43.75% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  68.75% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  93.75% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#eV2ksJzphy7259 {
  animation: eV2ksJzphy7259_s_p 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7259_s_p {
  0% {
    stroke: #05b8cc;
  }
  12.5% {
    stroke: #05b8cc;
  }
  18.75% {
    stroke: #05b8cc;
  }
  37.5% {
    stroke: #20bf9f;
  }
  43.75% {
    stroke: #20bf9f;
  }
  62.5% {
    stroke: #ff0606;
  }
  68.75% {
    stroke: #ff0606;
  }
  87.5% {
    stroke: #fed800;
  }
  93.75% {
    stroke: #fed800;
  }
  100% {
    stroke: #fed800;
  }
}
#eV2ksJzphy7261-fill-0 {
  animation: eV2ksJzphy7261-fill-0__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7261-fill-0__c {
  0% {
    stop-color: #05b8cc;
  }
  12.5% {
    stop-color: #05b8cc;
  }
  18.75% {
    stop-color: #05b8cc;
  }
  37.5% {
    stop-color: #20bf9f;
  }
  43.75% {
    stop-color: #20bf9f;
  }
  62.5% {
    stop-color: #ff0606;
  }
  68.75% {
    stop-color: #ff0606;
  }
  87.5% {
    stop-color: #fed800;
  }
  93.75% {
    stop-color: #fed800;
  }
  100% {
    stop-color: #fed800;
  }
}
#eV2ksJzphy7261-fill-1 {
  animation: eV2ksJzphy7261-fill-1__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7261-fill-1__c {
  0% {
    stop-color: #000;
  }
  12.5% {
    stop-color: #000;
  }
  18.75% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  43.75% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  68.75% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  93.75% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#eV2ksJzphy7261 {
  animation: eV2ksJzphy7261_s_p 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7261_s_p {
  0% {
    stroke: #05b8cc;
  }
  12.5% {
    stroke: #05b8cc;
  }
  18.75% {
    stroke: #05b8cc;
  }
  37.5% {
    stroke: #20bf9f;
  }
  43.75% {
    stroke: #20bf9f;
  }
  62.5% {
    stroke: #ff0606;
  }
  68.75% {
    stroke: #ff0606;
  }
  87.5% {
    stroke: #fed800;
  }
  93.75% {
    stroke: #fed800;
  }
  100% {
    stroke: #fed800;
  }
}
#eV2ksJzphy7262-fill-0 {
  animation: eV2ksJzphy7262-fill-0__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7262-fill-0__c {
  0% {
    stop-color: #05b8cc;
  }
  12.5% {
    stop-color: #05b8cc;
  }
  18.75% {
    stop-color: #05b8cc;
  }
  37.5% {
    stop-color: #20bf9f;
  }
  43.75% {
    stop-color: #20bf9f;
  }
  62.5% {
    stop-color: #ff0606;
  }
  68.75% {
    stop-color: #ff0606;
  }
  87.5% {
    stop-color: #fed800;
  }
  93.75% {
    stop-color: #fed800;
  }
  100% {
    stop-color: #fed800;
  }
}
#eV2ksJzphy7262-fill-1 {
  animation: eV2ksJzphy7262-fill-1__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7262-fill-1__c {
  0% {
    stop-color: #000;
  }
  12.5% {
    stop-color: #000;
  }
  18.75% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  43.75% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  68.75% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  93.75% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#eV2ksJzphy7262 {
  animation: eV2ksJzphy7262_s_p 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7262_s_p {
  0% {
    stroke: #05b8cc;
  }
  12.5% {
    stroke: #05b8cc;
  }
  18.75% {
    stroke: #05b8cc;
  }
  37.5% {
    stroke: #20bf9f;
  }
  43.75% {
    stroke: #20bf9f;
  }
  62.5% {
    stroke: #ff0606;
  }
  68.75% {
    stroke: #ff0606;
  }
  87.5% {
    stroke: #fed800;
  }
  93.75% {
    stroke: #fed800;
  }
  100% {
    stroke: #fed800;
  }
}
#eV2ksJzphy7263-fill-0 {
  animation: eV2ksJzphy7263-fill-0__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7263-fill-0__c {
  0% {
    stop-color: #05b8cc;
  }
  12.5% {
    stop-color: #05b8cc;
  }
  18.75% {
    stop-color: #05b8cc;
  }
  37.5% {
    stop-color: #20bf9f;
  }
  43.75% {
    stop-color: #20bf9f;
  }
  62.5% {
    stop-color: #ff0606;
  }
  68.75% {
    stop-color: #ff0606;
  }
  87.5% {
    stop-color: #fed800;
  }
  93.75% {
    stop-color: #fed800;
  }
  100% {
    stop-color: #fed800;
  }
}
#eV2ksJzphy7263-fill-1 {
  animation: eV2ksJzphy7263-fill-1__c 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7263-fill-1__c {
  0% {
    stop-color: #000;
  }
  12.5% {
    stop-color: #000;
  }
  18.75% {
    stop-color: #000;
  }
  37.5% {
    stop-color: #000;
  }
  43.75% {
    stop-color: #000;
  }
  62.5% {
    stop-color: #000;
  }
  68.75% {
    stop-color: #000;
  }
  87.5% {
    stop-color: #000;
  }
  93.75% {
    stop-color: #000;
  }
  100% {
    stop-color: #000;
  }
}
#eV2ksJzphy7263 {
  animation: eV2ksJzphy7263_s_p 22857.14285714286ms linear infinite normal forwards;
}
@keyframes eV2ksJzphy7263_s_p {
  0% {
    stroke: #05b8cc;
  }
  12.5% {
    stroke: #05b8cc;
  }
  18.75% {
    stroke: #05b8cc;
  }
  37.5% {
    stroke: #20bf9f;
  }
  43.75% {
    stroke: #20bf9f;
  }
  62.5% {
    stroke: #ff0606;
  }
  68.75% {
    stroke: #ff0606;
  }
  87.5% {
    stroke: #fed800;
  }
  93.75% {
    stroke: #fed800;
  }
  100% {
    stroke: #fed800;
  }
}
