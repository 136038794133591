#first5satheart-u-flame_ts {
  animation: first5satheart-u-flame_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-flame_ts__ts {
  0% {
    transform: translate(200.23082px, 130.219874px) scale(0.7, 0.7);
  }
  56% {
    transform: translate(200.23082px, 130.219874px) scale(0.7, 0.7);
  }
  74% {
    transform: translate(200.23082px, 130.219874px) scale(1.1, 1.1);
  }
  78% {
    transform: translate(200.23082px, 130.219874px) scale(1, 1);
  }
  100% {
    transform: translate(200.23082px, 130.219874px) scale(1, 1);
  }
}
#first5satheart-u-l2-flame {
  animation: first5satheart-u-l2-flame_f_o 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-l2-flame_f_o {
  0% {
    fill-opacity: 0;
  }
  80% {
    fill-opacity: 1;
  }
  100% {
    fill-opacity: 1;
  }
}
#first5satheart-u-r2-flame {
  animation: first5satheart-u-r2-flame_f_o 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-r2-flame_f_o {
  0% {
    fill-opacity: 0;
  }
  80% {
    fill-opacity: 1;
  }
  100% {
    fill-opacity: 1;
  }
}
#first5satheart-u-r1-flame {
  animation: first5satheart-u-r1-flame_f_o 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-r1-flame_f_o {
  0% {
    fill-opacity: 0;
  }
  80% {
    fill-opacity: 1;
  }
  100% {
    fill-opacity: 1;
  }
}
#first5satheart-u-l1-flame {
  animation: first5satheart-u-l1-flame_f_o 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-l1-flame_f_o {
  0% {
    fill-opacity: 0;
  }
  80% {
    fill-opacity: 1;
  }
  100% {
    fill-opacity: 1;
  }
}
#first5satheart-u-mid-flame {
  animation: first5satheart-u-mid-flame_f_o 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-mid-flame_f_o {
  0% {
    fill-opacity: 0;
  }
  80% {
    fill-opacity: 1;
  }
  100% {
    fill-opacity: 1;
  }
}
#first5satheart-u-heart {
  animation: first5satheart-u-heart_f_o 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-heart_f_o {
  0% {
    fill-opacity: 0;
  }
  0.2% {
    fill-opacity: 0;
  }
  60% {
    fill-opacity: 1;
  }
  100% {
    fill-opacity: 1;
  }
}
#first5satheart-u-thorns {
  animation: first5satheart-u-thorns_c_o 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-thorns_c_o {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
#first5satheart-u-triangle-31_ts {
  animation: first5satheart-u-triangle-31_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-31_ts__ts {
  0% {
    transform: translate(161.145703px, 284.76307px) rotate(-172.333027deg) scale(1.9, 1.9);
  }
  30% {
    transform: translate(161.145703px, 284.76307px) rotate(-172.333027deg) scale(1, 1);
  }
  100% {
    transform: translate(161.145703px, 284.76307px) rotate(-172.333027deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-312_ts {
  animation: first5satheart-u-triangle-312_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-312_ts__ts {
  0% {
    transform: translate(287.060678px, 273.571613px) rotate(-156.76861deg) scale(1.9, 1.9);
  }
  30% {
    transform: translate(287.060678px, 273.571613px) rotate(-156.76861deg) scale(1, 1);
  }
  100% {
    transform: translate(287.060678px, 273.571613px) rotate(-156.76861deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-313_ts {
  animation: first5satheart-u-triangle-313_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-313_ts__ts {
  0% {
    transform: translate(124.667489px, 279.388677px) rotate(-191.083039deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(124.667489px, 279.388677px) rotate(-191.083039deg) scale(1, 1);
  }
  100% {
    transform: translate(124.667489px, 279.388677px) rotate(-191.083039deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-30_ts {
  animation: first5satheart-u-triangle-30_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-30_ts__ts {
  0% {
    transform: translate(258.440138px, 287.638689px) rotate(153.635354deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(258.440138px, 287.638689px) rotate(153.635354deg) scale(1, 1);
  }
  100% {
    transform: translate(258.440138px, 287.638689px) rotate(153.635354deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-29_ts {
  animation: first5satheart-u-triangle-29_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-29_ts__ts {
  0% {
    transform: translate(242.357721px, 260.002049px) rotate(132.360914deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(242.357721px, 260.002049px) rotate(132.360914deg) scale(1, 1);
  }
  100% {
    transform: translate(242.357721px, 260.002049px) rotate(132.360914deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-28_ts {
  animation: first5satheart-u-triangle-28_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-28_ts__ts {
  0% {
    transform: translate(204.857688px, 277.485131px) rotate(216.006338deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(204.857688px, 277.485131px) rotate(216.006338deg) scale(1, 1);
  }
  100% {
    transform: translate(204.857688px, 277.485131px) rotate(216.006338deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-27_ts {
  animation: first5satheart-u-triangle-27_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-27_ts__ts {
  0% {
    transform: translate(234.010638px, 278.031849px) rotate(23.388033deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(234.010638px, 278.031849px) rotate(23.388033deg) scale(1, 1);
  }
  100% {
    transform: translate(234.010638px, 278.031849px) rotate(23.388033deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-26_ts {
  animation: first5satheart-u-triangle-26_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-26_ts__ts {
  0% {
    transform: translate(91.022935px, 230.590702px) rotate(-180.375501deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(91.022935px, 230.590702px) rotate(-180.375501deg) scale(1, 1);
  }
  100% {
    transform: translate(91.022935px, 230.590702px) rotate(-180.375501deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-24_ts {
  animation: first5satheart-u-triangle-24_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-24_ts__ts {
  0% {
    transform: translate(104.385139px, 225.976272px) rotate(16.834237deg) scale(1.95, 1.95);
  }
  30% {
    transform: translate(104.385139px, 225.976272px) rotate(16.834237deg) scale(1, 1);
  }
  100% {
    transform: translate(104.385139px, 225.976272px) rotate(16.834237deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-242_ts {
  animation: first5satheart-u-triangle-242_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-242_ts__ts {
  0% {
    transform: translate(92.33211px, 251.10176px) rotate(175.719542deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(92.33211px, 251.10176px) rotate(175.719542deg) scale(1, 1);
  }
  100% {
    transform: translate(92.33211px, 251.10176px) rotate(175.719542deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-23_ts {
  animation: first5satheart-u-triangle-23_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-23_ts__ts {
  0% {
    transform: translate(112.427821px, 267.181409px) rotate(14.330007deg) scale(2, 2);
  }
  30% {
    transform: translate(112.427821px, 267.181409px) rotate(14.330007deg) scale(1, 1);
  }
  100% {
    transform: translate(112.427821px, 267.181409px) rotate(14.330007deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-21_ts {
  animation: first5satheart-u-triangle-21_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-21_ts__ts {
  0% {
    transform: translate(104.41409px, 239.871974px) rotate(39.315547deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(104.41409px, 239.871974px) rotate(39.315547deg) scale(1, 1);
  }
  100% {
    transform: translate(104.41409px, 239.871974px) rotate(39.315547deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-20_ts {
  animation: first5satheart-u-triangle-20_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-20_ts__ts {
  0% {
    transform: translate(210.55377px, 263.167289px) rotate(-208.819579deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(210.55377px, 263.167289px) rotate(-208.819579deg) scale(1, 1);
  }
  100% {
    transform: translate(210.55377px, 263.167289px) rotate(-208.819579deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-19_ts {
  animation: first5satheart-u-triangle-19_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-19_ts__ts {
  0% {
    transform: translate(201.109403px, 250.340772px) rotate(-147.490025deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(201.109403px, 250.340772px) rotate(-147.490025deg) scale(1, 1);
  }
  100% {
    transform: translate(201.109403px, 250.340772px) rotate(-147.490025deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-18_ts {
  animation: first5satheart-u-triangle-18_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-18_ts__ts {
  0% {
    transform: translate(161.145703px, 247.962505px) rotate(31.057647deg) scale(1.9, 1.9);
  }
  30% {
    transform: translate(161.145703px, 247.962505px) rotate(31.057647deg) scale(1, 1);
  }
  100% {
    transform: translate(161.145703px, 247.962505px) rotate(31.057647deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-182_ts {
  animation: first5satheart-u-triangle-182_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-182_ts__ts {
  0% {
    transform: translate(146.541894px, 257.946507px) rotate(12.68683deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(146.541894px, 257.946507px) rotate(12.68683deg) scale(1, 1);
  }
  100% {
    transform: translate(146.541894px, 257.946507px) rotate(12.68683deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-17_ts {
  animation: first5satheart-u-triangle-17_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-17_ts__ts {
  0% {
    transform: translate(249.164896px, 242.454928px) rotate(-138.774844deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(249.164896px, 242.454928px) rotate(-138.774844deg) scale(1, 1);
  }
  100% {
    transform: translate(249.164896px, 242.454928px) rotate(-138.774844deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-16_ts {
  animation: first5satheart-u-triangle-16_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-16_ts__ts {
  0% {
    transform: translate(261.667929px, 266.738055px) rotate(-37.342622deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(261.667929px, 266.738055px) rotate(-37.342622deg) scale(1, 1);
  }
  100% {
    transform: translate(261.667929px, 266.738055px) rotate(-37.342622deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-15_ts {
  animation: first5satheart-u-triangle-15_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-15_ts__ts {
  0% {
    transform: translate(278.958982px, 244.963041px) rotate(-198.255649deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(278.958982px, 244.963041px) rotate(-198.255649deg) scale(1, 1);
  }
  100% {
    transform: translate(278.958982px, 244.963041px) rotate(-198.255649deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-14_ts {
  animation-name: first5satheart-u-triangle-14_ts__ts, first5satheart-u-triangle-14_ts__ts;
  animation-duration: 5000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}
@keyframes first5satheart-u-triangle-14_ts__ts {
  0% {
    transform: translate(185.637965px, 251.513506px) rotate(136.638119deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(185.637965px, 251.513506px) rotate(136.638119deg) scale(1, 1);
  }
  100% {
    transform: translate(185.637965px, 251.513506px) rotate(136.638119deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-13_ts {
  animation-name: first5satheart-u-triangle-13_ts__ts, first5satheart-u-triangle-13_ts__ts;
  animation-duration: 5000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}
@keyframes first5satheart-u-triangle-13_ts__ts {
  0% {
    transform: translate(226.513706px, 228.891097px) rotate(-0.229687deg) scale(2, 2);
  }
  30% {
    transform: translate(226.513706px, 228.891097px) rotate(-0.229687deg) scale(1, 1);
  }
  100% {
    transform: translate(226.513706px, 228.891097px) rotate(-0.229687deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-132_ts {
  animation: first5satheart-u-triangle-132_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-132_ts__ts {
  0% {
    transform: translate(190.955354px, 232.745261px) rotate(-324.616833deg) scale(2, 2);
  }
  30% {
    transform: translate(190.955354px, 232.745261px) rotate(-324.616833deg) scale(1, 1);
  }
  100% {
    transform: translate(190.955354px, 232.745261px) rotate(-324.616833deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-12_ts {
  animation-name: first5satheart-u-triangle-12_ts__ts, first5satheart-u-triangle-12_ts__ts;
  animation-duration: 5000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}
@keyframes first5satheart-u-triangle-12_ts__ts {
  0% {
    transform: translate(125.798531px, 234.781798px) rotate(33.289269deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(125.798531px, 234.781798px) rotate(33.289269deg) scale(1, 1);
  }
  100% {
    transform: translate(125.798531px, 234.781798px) rotate(33.289269deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-11_ts {
  animation: first5satheart-u-triangle-11_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-11_ts__ts {
  0% {
    transform: translate(177.00787px, 269.727766px) rotate(-169.834521deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(177.00787px, 269.727766px) rotate(-169.834521deg) scale(1, 1);
  }
  100% {
    transform: translate(177.00787px, 269.727766px) rotate(-169.834521deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-10_ts {
  animation: first5satheart-u-triangle-10_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-10_ts__ts {
  0% {
    transform: translate(149.811269px, 232.656151px) rotate(-22.083962deg) scale(2, 2);
  }
  30% {
    transform: translate(149.811269px, 232.656151px) rotate(-22.083962deg) scale(1, 1);
  }
  100% {
    transform: translate(149.811269px, 232.656151px) rotate(-22.083962deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-9_ts {
  animation: first5satheart-u-triangle-9_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-9_ts__ts {
  0% {
    transform: translate(306.695635px, 245.414536px) rotate(-155.747661deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(306.695635px, 245.414536px) rotate(-155.747661deg) scale(1, 1);
  }
  100% {
    transform: translate(306.695635px, 245.414536px) rotate(-155.747661deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-8_ts {
  animation: first5satheart-u-triangle-8_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-8_ts__ts {
  0% {
    transform: translate(286.505028px, 263.297208px) rotate(41.771528deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(286.505028px, 263.297208px) rotate(41.771528deg) scale(1, 1);
  }
  100% {
    transform: translate(286.505028px, 263.297208px) rotate(41.771528deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-7_ts {
  animation: first5satheart-u-triangle-7_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-7_ts__ts {
  0% {
    transform: translate(297.98656px, 251.354986px) rotate(-5.415486deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(297.98656px, 251.354986px) rotate(-5.415486deg) scale(1, 1);
  }
  100% {
    transform: translate(297.98656px, 251.354986px) rotate(-5.415486deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-6_ts {
  animation: first5satheart-u-triangle-6_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-6_ts__ts {
  0% {
    transform: translate(311.140585px, 229.357629px) rotate(-19.693352deg) scale(2, 2);
  }
  30% {
    transform: translate(311.140585px, 229.357629px) rotate(-19.693352deg) scale(1, 1);
  }
  100% {
    transform: translate(311.140585px, 229.357629px) rotate(-19.693352deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-5_ts {
  animation: first5satheart-u-triangle-5_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-5_ts__ts {
  0% {
    transform: translate(287.50412px, 235.586164px) rotate(-0.415772deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(287.50412px, 235.586164px) rotate(-0.415772deg) scale(1, 1);
  }
  100% {
    transform: translate(287.50412px, 235.586164px) rotate(-0.415772deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-3_ts {
  animation: first5satheart-u-triangle-3_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-3_ts__ts {
  0% {
    transform: translate(237.448042px, 239.412616px) rotate(185.23316deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(237.448042px, 239.412616px) rotate(185.23316deg) scale(1, 1);
  }
  100% {
    transform: translate(237.448042px, 239.412616px) rotate(185.23316deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-2_ts {
  animation: first5satheart-u-triangle-2_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-2_ts__ts {
  0% {
    transform: translate(222.599956px, 245.13809px) rotate(-13.828086deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(222.599956px, 245.13809px) rotate(-13.828086deg) scale(1, 1);
  }
  100% {
    transform: translate(222.599956px, 245.13809px) rotate(-13.828086deg) scale(1, 1);
  }
}
#first5satheart-u-triangle-1_ts {
  animation: first5satheart-u-triangle-1_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-triangle-1_ts__ts {
  0% {
    transform: translate(259.665082px, 237.581112px) rotate(45.625365deg) scale(1.8, 1.8);
  }
  30% {
    transform: translate(259.665082px, 237.581112px) rotate(45.625365deg) scale(1, 1);
  }
  100% {
    transform: translate(259.665082px, 237.581112px) rotate(45.625365deg) scale(1, 1);
  }
}
#first5satheart-u-flowers {
  animation: first5satheart-u-flowers_c_o 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-flowers_c_o {
  0% {
    opacity: 0;
  }
  56% {
    opacity: 0;
  }
  78% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#first5satheart-u-rose15_ts {
  animation: first5satheart-u-rose15_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-rose15_ts__ts {
  0% {
    transform: translate(227.260637px, 235.029807px) scale(0.01, 0.01);
  }
  60% {
    transform: translate(227.260637px, 235.029807px) scale(0.01, 0.01);
  }
  70% {
    transform: translate(227.260637px, 235.029807px) scale(0.12, 0.12);
  }
  74% {
    transform: translate(227.260637px, 235.029807px) scale(0.1, 0.1);
  }
  100% {
    transform: translate(227.260637px, 235.029807px) scale(0.1, 0.1);
  }
}
#first5satheart-u-rose14_ts {
  animation: first5satheart-u-rose14_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-rose14_ts__ts {
  0% {
    transform: translate(310.390585px, 231.272871px) scale(0.01, 0.01);
  }
  60% {
    transform: translate(310.390585px, 231.272871px) scale(0.01, 0.01);
  }
  70% {
    transform: translate(310.390585px, 231.272871px) scale(0.12, 0.12);
  }
  74% {
    transform: translate(310.390585px, 231.272871px) scale(0.1, 0.1);
  }
  100% {
    transform: translate(310.390585px, 231.272871px) scale(0.1, 0.1);
  }
}
#first5satheart-u-rose13_ts {
  animation: first5satheart-u-rose13_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-rose13_ts__ts {
  0% {
    transform: translate(192.236491px, 236.776605px) scale(0.01, 0.01);
  }
  60% {
    transform: translate(192.236491px, 236.776605px) scale(0.01, 0.01);
  }
  70% {
    transform: translate(192.236491px, 236.776605px) scale(0.12, 0.12);
  }
  74% {
    transform: translate(192.236491px, 236.776605px) scale(0.1, 0.1);
  }
  100% {
    transform: translate(192.236491px, 236.776605px) scale(0.1, 0.1);
  }
}
#first5satheart-u-rose12_ts {
  animation: first5satheart-u-rose12_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-rose12_ts__ts {
  0% {
    transform: translate(289.968106px, 268.851971px) scale(0.01, 0.01);
  }
  60% {
    transform: translate(289.968106px, 268.851971px) scale(0.01, 0.01);
  }
  70% {
    transform: translate(289.968106px, 268.851971px) scale(0.12, 0.12);
  }
  74% {
    transform: translate(289.968106px, 268.851971px) scale(0.1, 0.1);
  }
  100% {
    transform: translate(289.968106px, 268.851971px) scale(0.1, 0.1);
  }
}
#first5satheart-u-rose11_ts {
  animation: first5satheart-u-rose11_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-rose11_ts__ts {
  0% {
    transform: translate(142.147025px, 258.800307px) scale(0.01, 0.01);
  }
  60% {
    transform: translate(142.147025px, 258.800307px) scale(0.01, 0.01);
  }
  70% {
    transform: translate(142.147025px, 258.800307px) scale(0.12, 0.12);
  }
  74% {
    transform: translate(142.147025px, 258.800307px) scale(0.1, 0.1);
  }
  100% {
    transform: translate(142.147025px, 258.800307px) scale(0.1, 0.1);
  }
}
#first5satheart-u-rose10_ts {
  animation: first5satheart-u-rose10_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-rose10_ts__ts {
  0% {
    transform: translate(241.200565px, 260.083073px) scale(0.01, 0.01);
  }
  60% {
    transform: translate(241.200565px, 260.083073px) scale(0.01, 0.01);
  }
  70% {
    transform: translate(241.200565px, 260.083073px) scale(0.12, 0.12);
  }
  74% {
    transform: translate(241.200565px, 260.083073px) scale(0.1, 0.1);
  }
  100% {
    transform: translate(241.200565px, 260.083073px) scale(0.1, 0.1);
  }
}
#first5satheart-u-rose9_ts {
  animation: first5satheart-u-rose9_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-rose9_ts__ts {
  0% {
    transform: translate(112.291575px, 265.88208px) scale(0.01, 0.01);
  }
  60% {
    transform: translate(112.291575px, 265.88208px) scale(0.01, 0.01);
  }
  70% {
    transform: translate(112.291575px, 265.88208px) scale(0.12, 0.12);
  }
  74% {
    transform: translate(112.291575px, 265.88208px) scale(0.1, 0.1);
  }
  100% {
    transform: translate(112.291575px, 265.88208px) scale(0.1, 0.1);
  }
}
#first5satheart-u-rose8_ts {
  animation: first5satheart-u-rose8_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-rose8_ts__ts {
  0% {
    transform: translate(91.355638px, 224.572581px) scale(0.01, 0.01);
  }
  60% {
    transform: translate(91.355638px, 224.572581px) scale(0.01, 0.01);
  }
  70% {
    transform: translate(91.355638px, 224.572581px) scale(0.12, 0.12);
  }
  74% {
    transform: translate(91.355638px, 224.572581px) scale(0.1, 0.1);
  }
  100% {
    transform: translate(91.355638px, 224.572581px) scale(0.1, 0.1);
  }
}
#first5satheart-u-rose7_ts {
  animation: first5satheart-u-rose7_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-rose7_ts__ts {
  0% {
    transform: translate(257.685116px, 287.403305px) scale(0.01, 0.01);
  }
  60% {
    transform: translate(257.685116px, 287.403305px) scale(0.01, 0.01);
  }
  70% {
    transform: translate(257.685116px, 287.403305px) scale(0.12, 0.12);
  }
  74% {
    transform: translate(257.685116px, 287.403305px) scale(0.1, 0.1);
  }
  100% {
    transform: translate(257.685116px, 287.403305px) scale(0.1, 0.1);
  }
}
#first5satheart-u-rose6_ts {
  animation: first5satheart-u-rose6_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-rose6_ts__ts {
  0% {
    transform: translate(187.679734px, 259.566092px) scale(0.01, 0.01);
  }
  60% {
    transform: translate(187.679734px, 259.566092px) scale(0.01, 0.01);
  }
  70% {
    transform: translate(187.679734px, 259.566092px) scale(0.12, 0.12);
  }
  74% {
    transform: translate(187.679734px, 259.566092px) scale(0.1, 0.1);
  }
  100% {
    transform: translate(187.679734px, 259.566092px) scale(0.1, 0.1);
  }
}
#first5satheart-u-rose5_ts {
  animation: first5satheart-u-rose5_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-rose5_ts__ts {
  0% {
    transform: translate(152.211216px, 232.656151px) scale(0.01, 0.01);
  }
  60% {
    transform: translate(152.211216px, 232.656151px) scale(0.01, 0.01);
  }
  70% {
    transform: translate(152.211216px, 232.656151px) scale(0.12, 0.12);
  }
  74% {
    transform: translate(152.211216px, 232.656151px) scale(0.1, 0.1);
  }
  100% {
    transform: translate(152.211216px, 232.656151px) scale(0.1, 0.1);
  }
}
#first5satheart-u-rose4_ts {
  animation: first5satheart-u-rose4_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-rose4_ts__ts {
  0% {
    transform: translate(264.435117px, 241.273557px) scale(0.01, 0.01);
  }
  60% {
    transform: translate(264.435117px, 241.273557px) scale(0.01, 0.01);
  }
  70% {
    transform: translate(264.435117px, 241.273557px) scale(0.12, 0.12);
  }
  74% {
    transform: translate(264.435117px, 241.273557px) scale(0.1, 0.1);
  }
  100% {
    transform: translate(264.435117px, 241.273557px) scale(0.1, 0.1);
  }
}
#first5satheart-u-rose3_ts {
  animation: first5satheart-u-rose3_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-rose3_ts__ts {
  0% {
    transform: translate(119.041576px, 236.776605px) scale(0.01, 0.01);
  }
  60% {
    transform: translate(119.041576px, 236.776605px) scale(0.01, 0.01);
  }
  70% {
    transform: translate(119.041576px, 236.776605px) scale(0.12, 0.12);
  }
  74% {
    transform: translate(119.041576px, 236.776605px) scale(0.1, 0.1);
  }
  100% {
    transform: translate(119.041576px, 236.776605px) scale(0.1, 0.1);
  }
}
#first5satheart-u-rose2_ts {
  animation: first5satheart-u-rose2_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-rose2_ts__ts {
  0% {
    transform: translate(161.895703px, 282.197394px) scale(0.01, 0.01);
  }
  60% {
    transform: translate(161.895703px, 282.197394px) scale(0.01, 0.01);
  }
  70% {
    transform: translate(161.895703px, 282.197394px) scale(0.12, 0.12);
  }
  74% {
    transform: translate(161.895703px, 282.197394px) scale(0.1, 0.1);
  }
  100% {
    transform: translate(161.895703px, 282.197394px) scale(0.1, 0.1);
  }
}
#first5satheart-u-rose_ts {
  animation: first5satheart-u-rose_ts__ts 5000ms linear infinite alternate forwards;
}
@keyframes first5satheart-u-rose_ts__ts {
  0% {
    transform: translate(210.553769px, 275.095721px) scale(0.01, 0.01);
  }
  60% {
    transform: translate(210.553769px, 275.095721px) scale(0.01, 0.01);
  }
  70% {
    transform: translate(210.553769px, 275.095721px) scale(0.12, 0.12);
  }
  74% {
    transform: translate(210.553769px, 275.095721px) scale(0.1, 0.1);
  }
  100% {
    transform: translate(210.553769px, 275.095721px) scale(0.1, 0.1);
  }
}
