.sum-section {
  /* height: 100vh; */
  height: calc(var(--vh, 1vh) * 100);
  max-width: 100vw;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  /* display: grid; */
  /* grid-template-rows: 10fr 2fr; */
  background-color: black;
  scrollbar-width: none;
  font-size: 16px;
}

.sum-section::-webkit-scrollbar {
  display: none;
}

.sum-loading {
  position: relative;
  height: 100%;
  width: 100%;
}

.sum-loading svg {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sum-home-btn {
  position: absolute;
  z-index: 5;
  top: 12vh;
  left: 0.3em;
  border-radius: 0.3em;
  background: black;
  border: 1px solid #027a7e;
  padding: 0.4em 0.8em;
  opacity: 0;
  animation: homeBtn 0.5s linear 3s forwards;
}

.sum-home-btn i,
.sum-home-btn svg {
  color: #027a7e;
  font-size: 1.1em;
}

.sum-home-btn:hover {
  background: #027a7e;
  border: 1px solid white;
}

.sum-home-btn:hover i,
.sum-home-btn:hover svg {
  color: white;
}

@keyframes homeBtn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.sum-pane-1 {
  height: 5em;
  max-width: 100vw;
  /* font-size: 16px; */
  text-align: center;
}

.sum-lights {
  width: 100vw;
  max-width: 100vw;
  height: 100%;
  background-color: black;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 1px solid #027a7e;
  overflow-x: hidden;
  opacity: 0;
  animation: lightFadeIn 1s linear 0s forwards;
}

@keyframes lightFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.light {
  width: 2.15em;
  height: 2.15em;
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: white;
  font-size: 12px;
  /* transition: var(--transition); */
  transition: 0.3s ease-in-out all;
  cursor: pointer;
}

/* Current lights in video begin at 12 sec and finish off at 16 sec. */
.l1 {
  border: 2px solid rgb(0, 149, 138);
  /* box-shadow: 0 0 10px 2px rgba(0, 149, 138), 0 0 10px 1px #ffdf00; */
  box-shadow: 0 0 10px 8px rgb(3, 170, 159);
}
.l1Sel,
.l1:hover {
  border: 2px solid rgb(6, 196, 183);
  background: radial-gradient(circle at center, rgb(17, 99, 93) 0, rgb(6, 196, 183) 75%);
}
.green-anim-1 {
  animation: glowG 6s linear 13s forwards, glowGPromptUser 4s linear 21s forwards;
}

.l2 {
  border: 2px solid #005b96;
  box-shadow: 0 0 10px 8px #2c9dca;
}
.l2Sel,
.l2:hover {
  border: 2px solid #30bbca;
  background: radial-gradient(circle at center, #005b96 0, #30bbca 75%);
  /* background: radial-gradient(circle at center, #bf1818 0, #ffdf00 75%); */
}

.blue-anim-2 {
  animation: glowB 6s linear 13.1s forwards;
}

.l3 {
  border: 2px solid rgb(0, 149, 138);
  box-shadow: 0 0 10px 2px rgb(0, 149, 138);
}
.l3Sel,
.l3:hover {
  border: 2px solid rgba(0, 149, 138);
  background: radial-gradient(circle at center, rgb(17, 99, 93) 0, rgb(6, 196, 183) 75%);
}

.green-anim-3 {
  animation: glowG 6s linear 13.2s forwards;
}

.l4 {
  border: 2px solid #005b96;
  box-shadow: 0 0 10px 2px #005b96;
}
.l4Sel,
.l4:hover {
  border: 2px solid #30bbca;
  background: radial-gradient(circle at center, #005b96 0, #30bbca 75%);
}

.blue-anim-4 {
  animation: glowB 6s linear 13.3s forwards;
}

.l5 {
  border: 2px solid rgb(0, 149, 138);
  box-shadow: 0 0 10px 2px rgb(0, 149, 138);
}

.l5Sel,
.l5:hover {
  border: 2px solid rgba(0, 149, 138);
  background: radial-gradient(circle at center, rgb(17, 99, 93) 0, rgb(6, 196, 183) 75%);
}

.green-anim-5 {
  animation: glowG 6s linear 13.4s forwards;
}

.l6 {
  border: 2px solid #005b96;
  box-shadow: 0 0 10px 2px #005b96;
}

.l6Sel,
.l6:hover {
  border: 2px solid #30bbca;
  background: radial-gradient(circle at center, #005b96 0, #30bbca 75%);
}

.blue-anim-6 {
  animation: glowB 6s linear 13.5s forwards;
}

.l7 {
  border: 2px solid #ff0000;
  box-shadow: 0 0 10px 8px #ff0000;
}
.l7Sel,
.l7:hover {
  background: radial-gradient(circle at center, #8b1616 0, #ff0000 75%);
}
.red-anim-7 {
  animation: glowR 6s linear 13.6s forwards;
}

.l8 {
  border: 1px solid #ff0000;
  box-shadow: 0 0 7px 2px #ff0000, 0 0 15px 12px #5d3fd3;
}
.l8Sel,
.l8:hover {
  background: radial-gradient(circle at center, #8b1616 0, #ff0000 75%);
}
.purple-anim-8 {
  animation: glowPurple 6s linear 13.7s forwards;
}

.l9 {
  border: 1px solid #ccb200;
  box-shadow: 0 0 7px 2px #ff0000, 0 0 15px 12px #8b1616;
}
.l9Sel,
.l9:hover {
  background: radial-gradient(circle at center, #ccb200 0, #ffdf00 75%);
}
.blood-anim-9 {
  animation: glowBlood 6s linear 13.8s forwards;
}

.green-light {
  background: radial-gradient(circle at center, rgb(17, 99, 93) 0, rgb(6, 196, 183) 75%);
  border: 2px solid rgb(6, 196, 183);
}
.blue-light {
  background: radial-gradient(circle at center, #005b96 0, #30bbca 75%);
  border: 2px solid #30bbca;
}
.red-light {
  background: radial-gradient(circle at center, #d30e0e 0, #ff0000 75%);
  border: 2px solid #ff0000;
  /* box-shadow: 0 0 7px 2px #ffdf00, 0 0 15px 12px #5d3fd3; */
}
/* medjurgorje light */
.purple-light {
  background: radial-gradient(circle at center, #8b1616 0, #ff0000 75%);
  box-shadow: 0 0 7px 2px #ff0000, 0 0 15px 12px #5d3fd3;
}

.blood-light {
  background: radial-gradient(circle at center, #ccb200 0, #ffdf00 75%);
  box-shadow: 0 0 7px 2px #ff0000, 0 0 15px 12px #8b1616;
}

@keyframes glowG {
  0% {
    background: radial-gradient(circle at center, rgb(17, 99, 93) 0, rgb(6, 196, 183) 75%);
    border: 2px solid rgb(6, 196, 183);
  }
  80% {
    background: radial-gradient(circle at center, rgb(17, 99, 93) 0, rgb(6, 196, 183) 75%);
    border: 2px solid rgb(6, 196, 183);
  }
}

@keyframes glowB {
  0% {
    background: radial-gradient(circle at center, #005b96 0, #30bbca 75%);
    border: 2px solid #30bbca;
  }
  80% {
    background: radial-gradient(circle at center, #005b96 0, #30bbca 75%);
    border: 2px solid #30bbca;
  }
}
@keyframes glowGold {
  0% {
    background: radial-gradient(circle at center, #ccb200 0, #ffdf00 75%);
    border: 2px solid #ffdf00;
  }
  80% {
    background: radial-gradient(circle at center, #ccb200 0, #ffdf00 75%);
    border: 2px solid #ffdf00;
  }
}

@keyframes glowR {
  0% {
    background: radial-gradient(circle at center, #8b1616 0, #ff0000 75%);
    box-shadow: 0 0 7px 2px #ff0000, 0 0 15px 12px #d30e0e;
  }
  80% {
    background: radial-gradient(circle at center, #8b1616 0, #ff0000 75%);
  }
}
@keyframes glowPurple {
  0% {
    background: radial-gradient(circle at center, #8b1616 0, #ff0000 75%);
    box-shadow: 0 0 7px 2px #ff0000, 0 0 15px 12px #5d3fd3;
  }
  /* 20% {
    box-shadow: 0 0 7px 2px #ff0000, 0 0 15px 12px #5d3fd3;
  }
  22% {
    box-shadow: 0 0 7px 4px #ff0000, 0 0 15px 15px #5d3fd3;
  }
  24% {
    box-shadow: 0 0 7px 2px #ff0000, 0 0 15px 12px #5d3fd3;
  }
  26% {
    box-shadow: 0 0 7px 4px #ff0000, 0 0 15px 15px #5d3fd3;
  }
  28% {
    box-shadow: 0 0 7px 2px #ff0000, 0 0 15px 12px #5d3fd3;
  }
  40% {
    box-shadow: 0 0 7px 2px #ff0000, 0 0 15px 12px #5d3fd3;
  }
  42% {
    box-shadow: 0 0 7px 4px #ff0000, 0 0 15px 15px #5d3fd3;
  }
  44% {
    box-shadow: 0 0 7px 2px #ff0000, 0 0 15px 12px #5d3fd3;
  }
  46% {
    box-shadow: 0 0 7px 4px #ff0000, 0 0 15px 15px #5d3fd3;
  }
  48% {
    box-shadow: 0 0 7px 2px #ff0000, 0 0 15px 12px #5d3fd3;
  }
  60% {
    box-shadow: 0 0 7px 2px #ff0000, 0 0 15px 12px #5d3fd3;
  }
  62% {
    box-shadow: 0 0 7px 4px #ff0000, 0 0 15px 15px #5d3fd3;
  }
  64% {
    box-shadow: 0 0 7px 2px #ff0000, 0 0 15px 12px #5d3fd3;
  }
  66% {
    box-shadow: 0 0 7px 4px #ff0000, 0 0 15px 15px #5d3fd3;
  }
  68% {
    box-shadow: 0 0 7px 2px #ff0000, 0 0 15px 12px #5d3fd3;
  } */
  80% {
    background: radial-gradient(circle at center, #8b1616 0, #ff0000 75%);
  }
}
@keyframes glowBlood {
  0% {
    background: radial-gradient(circle at center, #ccb200 0, #ffdf00 75%);
    box-shadow: 0 0 7px 2px #ff0000, 0 0 15px 12px #8b1616;
  }
  80% {
    background: radial-gradient(circle at center, #ccb200 0, #ffdf00 75%);
  }
}

@keyframes glowGPromptUser {
  0% {
    box-shadow: 0 0 10px 8px rgb(3, 170, 159);
  }
  4% {
    box-shadow: 0 0 10px 8px rgb(3, 170, 159);
  }
  8% {
    box-shadow: 0 0 10px 10px rgb(3, 170, 159);
  }
  12% {
    box-shadow: 0 0 10px 8px rgb(3, 170, 159);
  }
  16% {
    box-shadow: 0 0 10px 10px rgb(3, 170, 159);
  }
  20% {
    box-shadow: 0 0 10px 8px rgb(3, 170, 159);
  }
  /* 34% {
    box-shadow: 0 0 10px 10px rgb(3, 170, 159);
  }
  38% {
    box-shadow: 0 0 10px 8px rgb(3, 170, 159);
  } */
  40% {
    box-shadow: 0 0 10px 8px rgb(3, 170, 159);
  }
  44% {
    box-shadow: 0 0 10px 10px rgb(3, 170, 159);
  }
  48% {
    box-shadow: 0 0 10px 8px rgb(3, 170, 159);
  }
  52% {
    box-shadow: 0 0 10px 10px rgb(3, 170, 159);
  }
  56% {
    box-shadow: 0 0 10px 8px rgb(3, 170, 159);
  }
  76% {
    box-shadow: 0 0 10px 8px rgb(3, 170, 159);
  }
  80% {
    box-shadow: 0 0 10px 10px rgb(3, 170, 159);
  }
  84% {
    box-shadow: 0 0 10px 8px rgb(3, 170, 159);
  }
  88% {
    box-shadow: 0 0 10px 10px rgb(3, 170, 159);
  }
  92% {
    box-shadow: 0 0 10px 8px rgb(3, 170, 159);
  }
}

.sum-pane-2 {
  position: absolute;
  object-fit: fill;
  height: 90%;
  top: 5.1em;
  left: 0;
  right: 0;
  /* left: 6%;
  right: 6%; */
  background: black;
  z-index: 2;
}

.sum-pane-2-vid {
  position: absolute;
  object-fit: fill;
  height: calc(calc(var(--vh, 1vh) * 100) - 5.1em);
  /* top: 5.1em; */
  left: 0;
  right: 0;
  display: grid;
  place-items: center;
  background: black;
  z-index: 2;
}

.sum-pane-2 svg {
  position: relative;
  object-fit: contain;
  /* height: 100%; */
  bottom: 1em;
  z-index: 1;
}

.sum-pane-2-app {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: grid;
}

.sum-pane-2-app svg {
  justify-self: center;
  align-self: baseline;
  object-fit: contain;
  width: 60%;
  z-index: 1;
}

.sum-pane-2-app > img {
  top: 45%;
  width: 90%;
}

.nic-bg-light {
  position: absolute;
  left: -15%;
  top: 0;
  bottom: -10%;
  right: -15%;
  opacity: 0;
  background: radial-gradient(circle at center, #ffffff 0, #fef3c7 75%);
  /* box-shadow: 0 0 14px 2px #fef3c7; */
  animation: nic-bg 2s linear 1.5s forwards;
  z-index: 1;
}

.nic-rosary {
  position: absolute;
  object-fit: contain;
  opacity: 0;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120%;
  z-index: 3;
  animation: nic-bg 1s linear 4.5s forwards;
}

.nic-gates {
  position: absolute;
  object-fit: contain;
  opacity: 0;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 115%;
  z-index: 2;
  animation: nic-bg 1s linear 6s forwards;
}

.global_arena_img {
  width: 100%;
  height: 100%;
  /* border-radius: var(--borderRadius); */
  object-fit: cover;
  object-position: top;
  transition: var(--transition);
  z-index: 1000;
}

@keyframes nic-bg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sum-pane-3 {
  position: absolute;
  height: auto;
  min-height: 10em;
  background-color: rgb(4, 4, 4);
  border: 3px solid #027a7e;
  /* bottom: 1em; */
  left: 0;
  right: 0;
  top: 92%;
  /* top: 70%; */
  line-height: 1em;
  padding: 0.4em;
  /* border-radius: 1em; */
  overflow-y: auto;
  z-index: 3;
  transition: 0.3s ease-out all;
}

.sum-pane-3 > p {
  color: white;
}

.sum-info-btn {
  position: absolute;
  z-index: 5;
  /* top: 0.8em; */
  top: 90%;
  left: 0.75em;
  border-radius: 0.3em;
  background: black;
  border: 1px solid #027a7e;
  padding: 0.5em 1em;
  transition: 0.3s ease-out all;
}

.sum-info-btn i,
.sum-info-btn svg {
  color: #027a7e;
  font-size: 1.2em;
}

.sum-info-btn:hover {
  background: #027a7e;
  border: 1px solid white;
}

.sum-info-btn:hover i,
.sum-info-btn:hover svg {
  color: white;
}

/* Glows sum-info-btn when it hasn't been selected */
.sum-info-btn-g {
  background: #027a7e;
  border: 1px solid white;
}

.sum-info-btn-g i,
.sum-info-btn-g svg {
  color: white;
}

.vid-hdn {
  visibility: hidden;
}

.bg-sum-panes {
  height: 100%;
  width: 100vw;
  position: relative;
}

.bg-lt-sum-panes {
  position: absolute;
  left: 0%;
  width: 50vw;
  height: 100%;
  background: #18191a;
}

.bg-rt-sum-panes {
  position: absolute;
  left: 50%;
  width: 50vw;
  height: 100%;
  border-left: 1px solid var(--glory-blue-300);
}

.sum-heading {
  position: absolute;
  top: 3%;
  border-radius: 0.2em;
  /* font-size: 16px; */
  /* animation: flicker 1.5s linear 1s forwards, darkBg 2s linear 3s forwards; */
}

/* 
Removed to app.css
@keyframes flicker {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
} */

@keyframes darkBg {
  0% {
    background: none;
  }
  100% {
    background: rgba(8, 8, 8, 0.7);
  }
}

.sum-heading h4,
.sum-heading h5,
.sum-heading p {
  text-align: center;
  font-style: italic;
}

.sum-heading h4 {
  color: var(--glory-gold-300);
  margin-bottom: 0.2em;
  animation: sumhead4 2.5s linear 2.2s forwards;
  letter-spacing: var(--letterSpacing);
  text-shadow: 0 0 3px var(--glory-gold-300), 0 0 4px white;
  font-size: 1.56em;
}

@keyframes sumhead4 {
  0% {
    text-shadow: 0 0 3px var(--glory-gold-300), 0 0 4px white;
  }
  /* 50% {
    text-shadow: 0 0 4px var(--jade), 0 0 4px white;
  } */
  100% {
    text-shadow: 0 0 10px var(--glory-gold-300), 0 0 18px white;
  }
}

.sum-heading h5 {
  color: #4900fc;
  margin-top: 0.1em;
  margin-bottom: 0;
  animation: sumhead5 2.5s linear 2.2s forwards;
  text-shadow: 0 0 2px #4900fc, 0 0 4px var(--glory-blue-50);
  /* font-size: 1em; */
  font-size: 1.56em;
}

@keyframes sumhead5 {
  0% {
    text-shadow: 0 0 2px #4900fc, 0 0 4px var(--glory-blue-50);
  }
  /* 50% {
    text-shadow: 0 0 2px var(--glory-blue-100), 0 0 2px var(--glory-blue-50);
  } */
  100% {
    text-shadow: 0 0 7px #4900fc, 0 0 14px var(--glory-blue-50);
  }
}

.sum-hd-mb-hdn {
  visibility: hidden;
}

.sum-hd-bg {
  padding: 0.5em 0.75em;
}

.sumName {
  padding-top: 1em;
}

.sumName h3,
.sumName h5 {
  text-align: center;
}

.sumName h3 {
  color: white;
  margin-bottom: 0.2em;
  text-transform: none;
}

.sumName h5 {
  font-style: italic;
}

.sub-purple {
  color: var(--cross-red-300);
  text-shadow: 10px 10px 25px #545ee2, -10px 10px 25px #545ee2, -10px -10px 25px #545ee2, 10px -10px 25px #545ee2,
    25px 0px 25px #545ee2, -25px 0px 25px #545ee2, 25px 25px 25px rgb(58, 39, 136), -25px 25px 25px rgb(58, 39, 136),
    -25px -25px 25px rgb(58, 39, 136), 25px -25px 25px rgb(58, 39, 136), 40px 0px 25px rgb(58, 39, 136),
    -40px 0px 25px rgb(58, 39, 136);
}
.sub-green {
  color: rgb(2, 158, 148);
  /* text-shadow: 0 0 7px rgb(6, 196, 183); */
  text-shadow: 10px 10px 25px rgb(1, 156, 146), -10px 10px 25px rgb(1, 156, 146), -10px -10px 25px rgb(1, 156, 146),
    10px -10px 25px rgb(1, 156, 146), 25px 0px 25px rgb(1, 156, 146), -25px 0px 25px rgb(1, 156, 146),
    25px 25px 25px #0f682c, -25px 25px 25px #0f682c, -25px -25px 25px #0f682c, 25px -25px 25px #0f682c,
    40px 0px 25px #0f682c, -40px 0px 25px #0f682c;
}
.sub-blue {
  color: var(--glory-blue-200);
  text-shadow: 0 0 7px #30bbca;
  text-shadow: 10px 10px 25px #30bbca, -10px 10px 25px #30bbca, -10px -10px 25px #30bbca, 10px -10px 25px #30bbca,
    25px 0px 25px #30bbca, -25px 0px 25px #30bbca, 25px 25px 25px #1d43ac, -25px 25px 25px #1d43ac,
    -25px -25px 25px #1d43ac, 25px -25px 25px #1d43ac, 40px 0px 25px #1d43ac, -40px 0px 25px #1d43ac;
}
.sub-white {
  color: var(--white);
  text-shadow: 0 0 7px #d30e0e;
  text-shadow: 10px 10px 25px #d30e0e, -10px 10px 25px #d30e0e, -10px -10px 25px #d30e0e, 10px -10px 25px #d30e0e,
    25px 0px 25px #d30e0e, -25px 0px 25px #d30e0e, 25px 25px 25px #d30e0e, -25px 25px 25px #d30e0e,
    -25px -25px 25px #d30e0e, 25px -25px 25px #d30e0e, 40px 0px 25px #d30e0e, -40px 0px 25px #d30e0e;
}
.sub-blood {
  color: var(--glory-gold-300);
  text-shadow: 0 0 7px #d30e0e;
  text-shadow: 10px 10px 25px #d30e0e, -10px 10px 25px #d30e0e, -10px -10px 25px #d30e0e, 10px -10px 25px #d30e0e,
    25px 0px 25px #8b1616, -25px 0px 25px #8b1616, 25px 25px 25px #8b1616, -25px 25px 25px #8b1616,
    -25px -25px 25px #8b1616, 25px -25px 25px #8b1616, 40px 0px 25px #8b1616, -40px 0px 25px #8b1616;
}

.sumDesc p {
  color: white;
  font-size: 1em;
  letter-spacing: var(--letterSpacing);
  line-height: 1.05em;
}

.sumDesc ol {
  color: var(--glory-gold-300);
}

.sumDesc ol li {
  margin-bottom: 0.5em;
  text-shadow: 0 0 7px white;
}

.sumDesc a {
  text-transform: capitalize;
  font-style: italic;
}

.sumDesc h4 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  letter-spacing: var(--letterSpacing);
  text-align: center;
}

.sumLinks p,
.sumLinks a {
  font-size: 0.85em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  letter-spacing: var(--letterSpacing);
}

.sumLinks ul {
  margin-top: 0.7em;
  margin-bottom: 0.7em;
}

.sumLinks ul li {
  font-size: 0.85em;
  letter-spacing: var(--letterSpacing);
  color: white;
  list-style-type: disc;
  margin-left: 2em;
}

/* SVG */

@media screen and (min-width: 370px) {
  .sum-home-btn {
    top: 13vh;
    left: 0.5em;
  }
}

@media screen and (min-width: 380px) {
  .sum-home-btn {
    top: 11vh;
    left: 0.75em;
    padding: 0.5em 0.9em;
  }

  .sum-home-btn i {
    font-size: 1.2em;
  }
}

@media screen and (min-height: 700px) {
  .nic-bg-light {
    left: -10%;
    right: -10%;
  }
  .sum-section {
    font-size: 17px;
  }
}

@media screen and (min-height: 720px) {
  .sum-pane-2 {
    left: 0;
    right: 0;
  }
}

@media screen and (min-width: 700px) {
  .light {
    width: 2.9em;
    height: 2.9em;
  }
  .sum-heading h4 {
    font-size: 1.8em;
  }
  .sum-heading p {
    font-size: 1.2em;
  }
  .sumName h3 {
    font-size: 1.9em;
  }
  .sumName h5 {
    font-size: 1.3em;
  }

  .nic-rosary {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .nic-gates {
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 115%;
  }
  .nic-bg-light {
    left: 0%;
    top: 0;
    bottom: 0%;
    right: 0%;
  }
  .sum-home-btn i,
  .sum-info-btn i {
    font-size: 1.5em;
    padding: 0.55em 1.05em;
  }
  .sum-pane-2 {
    /* height: 80%; */
    /* left: 10%;
    right: 10%; */
  }
  .sum-pane-2-vid {
    height: calc(calc(var(--vh, 1vh) * 100) - 5.1em);
    /* left: 5%;
    right: 5%; */
  }
}

@media screen and (min-width: 1000px) and (max-aspect-ratio: 1/1) {
  .sum-section {
    font-size: 23px;
  }
  .light {
    width: 3.2em;
    height: 3.2em;
  }
}

@media screen and (min-width: 1000px) {
  .sumDesc h4 {
    font-size: 2em;
    text-align: center;
    font-style: italic;
    letter-spacing: 2px;
  }
  .sumDesc p {
    font-size: 1.2em;
    letter-spacing: var(--letterSpacing);
    line-height: 1.2em;
  }
  .sumLinks p,
  .sumLinks a {
    font-size: 1em;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
  }
}
@media screen and (min-width: 1200px) {
  .sum-section {
    font-size: 16px;
  }
  .sumName h3 {
    font-size: 2em;
  }
  .sumName h5 {
    font-size: 1.2em;
  }
}

@media screen and (min-width: 1900px) {
  .sum-section {
    font-size: 18px;
  }
}

@media screen and (min-width: 2200px) and (min-aspect-ratio: 14/10) {
  .sum-section {
    font-size: 30px;
  }
  .sum-pane-1 {
    height: 4.2em;
  }
  .light {
    width: 4em;
    height: 4em;
  }
  .light span {
    font-size: 1.5em;
  }
  .l1 {
    border: 2px solid rgb(0, 149, 138);
    /* box-shadow: 0 0 10px 2px rgba(0, 149, 138), 0 0 10px 1px #ffdf00; */
    box-shadow: 0 0 14px 11px rgb(3, 170, 159);
  }

  @keyframes glowGPromptUser {
    0% {
      box-shadow: 0 0 14px 11px rgb(3, 170, 159);
    }
    4% {
      box-shadow: 0 0 14px 11px rgb(3, 170, 159);
    }
    8% {
      box-shadow: 0 0 14px 15px rgb(3, 170, 159);
    }
    12% {
      box-shadow: 0 0 14px 11px rgb(3, 170, 159);
    }
    16% {
      box-shadow: 0 0 14px 15px rgb(3, 170, 159);
    }
    20% {
      box-shadow: 0 0 14px 11px rgb(3, 170, 159);
    }
    /* 34% {
    box-shadow: 0 0 10px 10px rgb(3, 170, 159);
  }
  38% {
    box-shadow: 0 0 10px 8px rgb(3, 170, 159);
  } */
    40% {
      box-shadow: 0 0 14px 11px rgb(3, 170, 159);
    }
    44% {
      box-shadow: 0 0 14px 15px rgb(3, 170, 159);
    }
    48% {
      box-shadow: 0 0 14px 11px rgb(3, 170, 159);
    }
    52% {
      box-shadow: 0 0 14px 15px rgb(3, 170, 159);
    }
    56% {
      box-shadow: 0 0 14px 11px rgb(3, 170, 159);
    }
    76% {
      box-shadow: 0 0 14px 11px rgb(3, 170, 159);
    }
    80% {
      box-shadow: 0 0 14px 15px rgb(3, 170, 159);
    }
    84% {
      box-shadow: 0 0 14px 11px rgb(3, 170, 159);
    }
    88% {
      box-shadow: 0 0 14px 15px rgb(3, 170, 159);
    }
    92% {
      box-shadow: 0 0 14px 11px rgb(3, 170, 159);
    }
  }

  .l2 {
    border: 2px solid #005b96;
    box-shadow: 0 0 14px 11px #2c9dca;
  }

  .l3 {
    border: 2px solid rgb(0, 149, 138);
    box-shadow: 0 0 12px 5px rgb(0, 149, 138);
  }

  .l4 {
    border: 2px solid #005b96;
    box-shadow: 0 0 12px 5px #005b96;
  }

  .l5 {
    border: 2px solid rgb(0, 149, 138);
    box-shadow: 0 0 12px 5px rgb(0, 149, 138);
  }

  .l6 {
    border: 2px solid #005b96;
    box-shadow: 0 0 12px 5px #005b96;
  }
  .l7 {
    border: 1px solid #ff0000;
    box-shadow: 0 0 14px 11px #ff0000;
  }
  .l8 {
    border: 1px solid #ff0000;
    box-shadow: 0 0 10px 5px #ff0000, 0 0 18px 15px #5d3fd3;
  }
  .l9 {
    border: 1px solid #ccb200;
    box-shadow: 0 0 10px 5px #ff0000, 0 0 18px 15px #b70b0b;
  }
}

@media screen and (min-width: 2700px) {
  .sum-section {
    font-size: 32px;
  }

  .l1 {
    border: 2px solid rgb(0, 149, 138);
    /* box-shadow: 0 0 10px 2px rgba(0, 149, 138), 0 0 10px 1px #ffdf00; */
    box-shadow: 0 0 16px 13px rgb(3, 170, 159);
  }

  @keyframes glowGPromptUser {
    0% {
      box-shadow: 0 0 16px 13px rgb(3, 170, 159);
    }
    4% {
      box-shadow: 0 0 16px 13px rgb(3, 170, 159);
    }
    8% {
      box-shadow: 0 0 16px 17px rgb(3, 170, 159);
    }
    12% {
      box-shadow: 0 0 16px 13px rgb(3, 170, 159);
    }
    16% {
      box-shadow: 0 0 16px 17px rgb(3, 170, 159);
    }
    20% {
      box-shadow: 0 0 16px 13px rgb(3, 170, 159);
    }
    /* 34% {
    box-shadow: 0 0 10px 10px rgb(3, 170, 159);
  }
  38% {
    box-shadow: 0 0 10px 8px rgb(3, 170, 159);
  } */
    40% {
      box-shadow: 0 0 16px 13px rgb(3, 170, 159);
    }
    44% {
      box-shadow: 0 0 16px 17px rgb(3, 170, 159);
    }
    48% {
      box-shadow: 0 0 16px 13px rgb(3, 170, 159);
    }
    52% {
      box-shadow: 0 0 16px 17px rgb(3, 170, 159);
    }
    56% {
      box-shadow: 0 0 16px 13px rgb(3, 170, 159);
    }
    76% {
      box-shadow: 0 0 16px 13px rgb(3, 170, 159);
    }
    80% {
      box-shadow: 0 0 16px 17px rgb(3, 170, 159);
    }
    84% {
      box-shadow: 0 0 16px 13px rgb(3, 170, 159);
    }
    88% {
      box-shadow: 0 0 16px 17px rgb(3, 170, 159);
    }
    92% {
      box-shadow: 0 0 16px 13px rgb(3, 170, 159);
    }
  }

  .l2 {
    border: 2px solid #005b96;
    box-shadow: 0 0 16px 13px #2c9dca;
  }

  .l3 {
    border: 2px solid rgb(0, 149, 138);
    box-shadow: 0 0 13px 6px rgb(0, 149, 138);
  }

  .l4 {
    border: 2px solid #005b96;
    box-shadow: 0 0 13px 6px #005b96;
  }

  .l5 {
    border: 2px solid rgb(0, 149, 138);
    box-shadow: 0 0 13px 6px rgb(0, 149, 138);
  }

  .l6 {
    border: 2px solid #005b96;
    box-shadow: 0 0 13px 6px #005b96;
  }
  .l7 {
    border: 1px solid #ff0000;
    box-shadow: 0 0 16px 13px #ff0000;
  }
  .l8 {
    border: 1px solid #ff0000;
    box-shadow: 0 0 11px 6px #ff0000, 0 0 19px 16px #5d3fd3;
  }
  .l9 {
    border: 1px solid #ccb200;
    box-shadow: 0 0 11px 6px #ff0000, 0 0 19px 16px #b70b0b;
  }
}

@media screen and (min-width: 3800px) {
  .sum-section {
    font-size: 40px;
  }
  .light {
    width: 5em;
    height: 5em;
  }
  .light span {
    font-size: 2em;
  }
  .l1 {
    border: 2px solid rgb(0, 149, 138);
    /* box-shadow: 0 0 10px 2px rgba(0, 149, 138), 0 0 10px 1px #ffdf00; */
    box-shadow: 0 0 20px 17px rgb(3, 170, 159);
  }

  @keyframes glowGPromptUser {
    0% {
      box-shadow: 0 0 20px 17px rgb(3, 170, 159);
    }
    4% {
      box-shadow: 0 0 20px 17px rgb(3, 170, 159);
    }
    8% {
      box-shadow: 0 0 20px 24px rgb(3, 170, 159);
    }
    12% {
      box-shadow: 0 0 20px 17px rgb(3, 170, 159);
    }
    16% {
      box-shadow: 0 0 20px 24px rgb(3, 170, 159);
    }
    20% {
      box-shadow: 0 0 20px 17px rgb(3, 170, 159);
    }
    /* 34% {
    box-shadow: 0 0 10px 10px rgb(3, 170, 159);
  }
  38% {
    box-shadow: 0 0 10px 8px rgb(3, 170, 159);
  } */
    40% {
      box-shadow: 0 0 20px 17px rgb(3, 170, 159);
    }
    44% {
      box-shadow: 0 0 20px 24px rgb(3, 170, 159);
    }
    48% {
      box-shadow: 0 0 20px 17px rgb(3, 170, 159);
    }
    52% {
      box-shadow: 0 0 20px 24px rgb(3, 170, 159);
    }
    56% {
      box-shadow: 0 0 20px 17px rgb(3, 170, 159);
    }
    76% {
      box-shadow: 0 0 20px 17px rgb(3, 170, 159);
    }
    80% {
      box-shadow: 0 0 20px 24px rgb(3, 170, 159);
    }
    84% {
      box-shadow: 0 0 20px 17px rgb(3, 170, 159);
    }
    88% {
      box-shadow: 0 0 20px 24px rgb(3, 170, 159);
    }
    92% {
      box-shadow: 0 0 20px 17px rgb(3, 170, 159);
    }
  }

  .l2 {
    border: 2px solid #005b96;
    box-shadow: 0 0 20px 17px #2c9dca;
  }

  .l3 {
    border: 2px solid rgb(0, 149, 138);
    box-shadow: 0 0 15px 8px rgb(0, 149, 138);
  }

  .l4 {
    border: 2px solid #005b96;
    box-shadow: 0 0 15px 8px #005b96;
  }

  .l5 {
    border: 2px solid rgb(0, 149, 138);
    box-shadow: 0 0 15px 8px rgb(0, 149, 138);
  }

  .l6 {
    border: 2px solid #005b96;
    box-shadow: 0 0 15px 8px #005b96;
  }
  .l7 {
    border: 1px solid #ff0000;
    box-shadow: 0 0 20px 17px #ff0000;
  }
  .l8 {
    border: 1px solid #ff0000;
    box-shadow: 0 0 16px 12px #ff0000, 0 0 30px 24px #5d3fd3;
  }
  .l9 {
    border: 1px solid #ccb200;
    box-shadow: 0 0 16px 12px #ff0000, 0 0 30px 24px #b70b0b;
  }
}
